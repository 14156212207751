import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Radio, Input, Button, Select } from 'antd';
import clsx from 'clsx';
import { MARK_TYPES, FONT_SIZES } from 'src/constants';
import i18n from 'src/i18n';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomCollapse from './components/CustomCollapse';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class OtherDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  renderMarkIcons = () => {
    return [
      {
        key: MARK_TYPES.common,
        children: (
          <div className={styles.items}>
            <Radio.Group
              className={styles.commonMarkGroup}
              value={this.vm.commonMark}
              onChange={this.vm.onCommonMarkChange}
              disabled={this.vm.disabled.common}
            >
              {this.vm.options.marks.map((item) => {
                return (
                  <Radio className="itemRadio" value={item.id} key={item.id}>
                    <div className={styles.text}>{item.zhName}</div>
                  </Radio>
                );
              })}
            </Radio.Group>
          </div>
        )
      }
    ];
  };

  render() {
    return (
      <CustomDrawer
        vm={this.vm.drawerVM}
        title={i18n.t('draw_floor_plan_page_other_drawer_title')}
        placement="left"
        width={230}
      >
        <div
          className={clsx(['drawFloorPlanPageOtherDrawer', styles.container])}
        >
          <Radio.Group value={this.vm.type} onChange={this.vm.onTypeChange}>
            <div className={styles.section}>
              <div className={styles.sectionLabel}>
                <Radio value={MARK_TYPES.common}>
                  <div className={styles.text}>
                    {i18n.t(
                      'draw_floor_plan_page_other_drawer_common_mark_label'
                    )}
                  </div>
                </Radio>
              </div>

              <CustomCollapse
                items={this.renderMarkIcons()}
                activeKey={this.vm.commonMarkActiveKey}
              />
            </div>

            <div className={styles.section}>
              <div className={styles.sectionLabel}>
                <Radio value={MARK_TYPES.custom}>
                  <div className={styles.text}>
                    {i18n.t(
                      'draw_floor_plan_page_other_drawer_custom_mark_label'
                    )}
                  </div>
                </Radio>
              </div>

              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>
                  {i18n.t(
                    'draw_floor_plan_page_other_drawer_custom_mark_zh_name_label'
                  )}
                </div>

                <div className={styles.fontSizeSelect}>
                  <Select
                    className={styles.select}
                    value={this.vm.zhNameFontSize}
                    onChange={this.vm.onZhNameFontSizeChange}
                    options={this.vm.options.fontSizes}
                    disabled={this.vm.disabled.custom}
                    onFocus={this.vm.pauseSelect}
                    onBlur={this.vm.resumeSelect}
                  />
                </div>

                <Input.TextArea
                  value={this.vm.zhName}
                  onChange={this.vm.onZhNameChange}
                  disabled={this.vm.disabled.custom}
                  autoSize
                  maxLength={50}
                  showCount
                  onFocus={this.vm.pauseSelect}
                  onBlur={this.vm.resumeSelect}
                />
              </div>

              <div className={styles.inputContainer}>
                <div className={styles.inputLabel}>
                  {i18n.t(
                    'draw_floor_plan_page_other_drawer_custom_mark_en_name_label'
                  )}
                </div>

                <div className={styles.fontSizeSelect}>
                  <Select
                    className={styles.select}
                    value={this.vm.enNameFontSize}
                    onChange={this.vm.onEnNameFontSizeChange}
                    options={this.vm.options.fontSizes}
                    disabled={this.vm.disabled.custom}
                    onFocus={this.vm.pauseSelect}
                    onBlur={this.vm.resumeSelect}
                  />
                </div>

                <Input.TextArea
                  value={this.vm.enName}
                  onChange={this.vm.onEnNameChange}
                  disabled={this.vm.disabled.custom}
                  autoSize
                  maxLength={50}
                  showCount
                  onFocus={this.vm.pauseSelect}
                  onBlur={this.vm.resumeSelect}
                />
              </div>
            </div>
          </Radio.Group>

          <div className={styles.otherForm}>
            <div className={styles.label}>
              {i18n.t('draw_floor_plan_page_other_drawer_booth_number_label')}
            </div>

            <div className={styles.boothNumberFontSize}>
              <Select
                className={styles.select}
                options={FONT_SIZES}
                value={this.vm.boothNumberFontSize}
                onChange={this.vm.onBoothNumberFontSizeChange}
                onFocus={this.vm.pauseSelect}
                onBlur={this.vm.resumeSelect}
              />
            </div>

            <Input
              value={this.vm.boothNumber}
              onChange={this.vm.onBoothNumberChange}
              maxLength={20}
              onFocus={this.vm.pauseSelect}
              onBlur={this.vm.resumeSelect}
            />
          </div>

          <div className={styles.confirm}>
            <Button
              type="primary"
              size="large"
              className={styles.button}
              onClick={this.vm.onConfirm}
            >
              {i18n.t('draw_floor_plan_page_other_drawer_confirm_button_label')}
            </Button>
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

OtherDrawer.propTypes = {
  vm: PropTypes.object.isRequired
};

OtherDrawer.defaultProps = {};

export default OtherDrawer;
