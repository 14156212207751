import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Form, Input, Select } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import CustomModal from 'src/components/CustomModal';
import CustomUpload from 'src/components/CustomUpload';
import styles from './styles.module.scss';

@observer
class CreateFloorPlanModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      form: props.form,
      onCreated: props.onCreated
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <CustomModal
        vm={this.vm.modalVM}
        title={this.vm.title}
        footer
        onCancel={this.vm.onCancel}
        cancelDisabled={this.vm.disabled.cancel}
        onOk={this.vm.onOk}
        okDisabled={this.vm.disabled.ok}
        afterClose={this.vm.onAfterClose}
      >
        <div className="createFloorPlanModal">
          <Form form={this.vm.form} disabled={this.vm.disabled.form}>
            <Form.Item
              label={i18n.t('map_page_create_modal_zh_name_label')}
              name="zhName"
              rules={[
                {
                  required: true,
                  message: i18n.t('map_page_create_modal_zh_name_required')
                }
              ]}
            >
              <Input
                placeholder={i18n.t(
                  'map_page_create_modal_zh_name_placeholder'
                )}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('map_page_create_modal_en_name_label')}
              name="enName"
              rules={[
                {
                  required: true,
                  message: i18n.t('map_page_create_modal_en_name_required')
                }
              ]}
            >
              <Input
                placeholder={i18n.t(
                  'map_page_create_modal_en_name_placeholder'
                )}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('map_page_create_modal_exhibition_label')}
              name="exhibitions"
              rules={[
                {
                  required: true,
                  message: i18n.t('map_page_create_modal_exhibition_required')
                }
              ]}
            >
              <Select
                mode="multiple"
                options={this.vm.mapExhibitions}
                filterOption={this.vm.onFilterOption}
                placeholder={i18n.t(
                  'map_page_create_modal_exhibition_placeholder'
                )}
                disabled={this.vm.disabled.exhibitions}
              />
            </Form.Item>

            <Form.Item
              label={i18n.t('map_page_create_modal_template_label')}
              name="template"
              rules={[
                {
                  required: true,
                  message: i18n.t('map_page_create_modal_template_required')
                }
              ]}
            >
              <Select
                options={this.vm.mapTemplates}
                onChange={this.vm.onTemplateChange}
                placeholder={i18n.t(
                  'map_page_create_modal_template_placeholder'
                )}
              />
            </Form.Item>

            {this.vm.check.hasUpload && (
              <Form.Item
                className={styles.clearMargin}
                name="background"
                rules={[
                  {
                    required: true,
                    message: i18n.t(
                      'map_page_create_modal_upload_background_required'
                    )
                  }
                ]}
              >
                <CustomUpload
                  accept="image/jpeg,image/jpg"
                  fileList={this.vm.fileList}
                  onChange={this.vm.onUploadChange}
                  onFileRemove={this.vm.onFileRemove}
                  title={i18n.t(
                    'map_page_create_modal_upload_background_upload_title'
                  )}
                  subtitle={i18n.t(
                    'map_page_create_modal_upload_background_upload_subtitle'
                  )}
                />
              </Form.Item>
            )}
          </Form>
        </div>
      </CustomModal>
    );
  }
}

CreateFloorPlanModal.propTypes = {
  vm: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onCreated: PropTypes.func.isRequired
};

CreateFloorPlanModal.defaultProps = {};

export default withForm(CreateFloorPlanModal);
