import { request, getHost, getHeaders } from './utils';

class FloorPlanAPIs {
  static getMapList({ keyword, limit = 20, anchor }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/map`,
      headers: getHeaders(),
      params: {
        keyword,
        limit,
        anchor
      }
    };

    return request(options);
  }

  static getMapTemplateList() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/map/template`,
      headers: getHeaders()
    };

    return request(options);
  }

  static postMapCreate({ zhName, enName, exhibitions, template, background }) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/backstage/map/create`,
      headers: getHeaders(),
      data: {
        zhName,
        enName,
        exhibitions,
        template,
        background
      }
    };

    return request(options);
  }

  static putMapUpdate({ id, zhName, enName, active, tags, map, background }) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/backstage/map/${id}`,
      headers: getHeaders(),
      data: {
        zhName,
        enName,
        active,
        tags,
        map,
        background
      }
    };

    return request(options);
  }

  static deleteMap({ id }) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/backstage/map/${id}`,
      headers: getHeaders()
    };

    return request(options);
  }

  static getMapDetail({ id }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/map/${id}`,
      headers: getHeaders()
    };

    return request(options);
  }

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 預設標籤

  // 固定標籤的標籤池
  static getMapTagListDefault() {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/tag/default`,
      headers: getHeaders()
    };

    return request(options);
  }

  // 地圖已經選擇的標籤，固定 + 自訂
  static getMapTagList({ mapId }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/tag`,
      headers: getHeaders()
    };

    return request(options);
  }

  static putMapTagDefault({ mapId, tags }) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/tag`,
      headers: getHeaders(),
      data: {
        tags
      }
    };

    return request(options);
  }

  // 新增自訂標籤
  static postMapCustomTag({ mapId, name }) {
    const options = {
      method: 'post',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/tag`,
      headers: getHeaders(),
      data: {
        name
      }
    };

    return request(options);
  }

  // 編輯自訂標籤
  static putMapCustomTag({ mapId, tagId, name }) {
    const options = {
      method: 'put',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/tag/${tagId}`,
      headers: getHeaders(),
      data: {
        name
      }
    };

    return request(options);
  }

  // 刪除自訂標籤
  static deleteMapCustomTag({ mapId, tagId }) {
    const options = {
      method: 'delete',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/tag/${tagId}`,
      headers: getHeaders()
    };

    return request(options);
  }

  // 檢查地圖是否有上架
  static getMapActive({ mapId }) {
    const options = {
      method: 'get',
      url: `${getHost()}/api/v1/backstage/map/${mapId}/active`,
      headers: getHeaders()
    };

    return request(options);
  }
}

export default FloorPlanAPIs;
