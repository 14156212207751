import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import clsx from 'clsx';
import { ROUTES } from 'src/constants';
import withRouter from 'src/components/withRouter';
import i18n from 'src/i18n';
import Header from 'src/components/Header';
import seal from 'src/assets/seal_white.svg';
import tag from 'src/assets/tag.svg';
import OperateMenu from './components/OperateMenu';
import SwitchLanguage from './components/SwitchLanguage';
import CreateBooth from './components/CreateBooth';
import ToolbarButton from './components/ToolbarButton';
import CompanyDrawer from './components/CompanyDrawer';
import BoothTagModal from './components/BoothTagModal';
import BoothDrawer from './components/BoothDrawer';
import OtherDrawer from './components/OtherDrawer';
import ColorPickerModal from './components/ColorPickerModal';
import PillarsSwitch from './components/PillarsSwitch';
import DrawFloorPlanPageViewModel from './viewModel';
import FontSizeModal from './components/FontSizeModal';
import BackButton from './components/BackButton';
import floorPlanViewModel from './viewModels/FloorPlanViewModel';
import styles from './styles.module.scss';

@observer
class DrawFloorPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new DrawFloorPlanPageViewModel({
      router: props.router
    });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    floorPlanViewModel.reset();
  }

  render() {
    return (
      <div className={styles.container}>
        {this.vm.check.show && (
          <Header title={this.vm.title} backTo={ROUTES.Map} />
        )}

        {this.vm.check.show && (
          <div className={styles.toolbar}>
            <div className={styles.toolbarMinWidth}>
              <OperateMenu />

              <BackButton
                onClick={floorPlanViewModel.events.onBack}
                disabled={floorPlanViewModel.disabled.backButton}
              />

              <CreateBooth />

              <ToolbarButton
                onClick={floorPlanViewModel.onCompanyDrawerOpen}
                label={i18n.t('draw_floor_plan_page_company_button_label')}
                iconSrc={seal}
                iconAlt="seal"
                active={floorPlanViewModel.check.isCompanyDrawer}
              />

              <ToolbarButton
                onClick={floorPlanViewModel.boothTagModalVM.open}
                label={i18n.t('draw_floor_plan_page_booth_tag_button_label')}
                iconSrc={tag}
                iconAlt="tag"
              />

              <PillarsSwitch vm={floorPlanViewModel.pillarsSwitchVM} />

              <SwitchLanguage />
            </div>
          </div>
        )}

        <div
          className={clsx([
            styles.mainContent,
            floorPlanViewModel.check.isCreateBooth && styles.isCreateBooth
          ])}
          onMouseEnter={floorPlanViewModel.events.onContainerMouseenter}
          onMouseLeave={floorPlanViewModel.events.onContainerMouseleave}
          onMouseMove={floorPlanViewModel.events.onContainerMousemove}
        >
          <div
            className={styles.floorPlanContainer}
            ref={this.vm.floorPlanContainerRef}
          />
        </div>

        <div
          className={clsx([
            styles.followCompany,
            floorPlanViewModel.showFollowCompanyName && styles.show
          ])}
          style={floorPlanViewModel.followCompanyStyle}
        >
          {floorPlanViewModel.followCompanyName}
        </div>

        {this.vm.check.show && (
          <CompanyDrawer
            vm={floorPlanViewModel.companyDrawerVM}
            exhibitions={this.vm.data.exhibitions}
          />
        )}

        {this.vm.check.show && (
          <BoothTagModal vm={floorPlanViewModel.boothTagModalVM} />
        )}

        {this.vm.check.show && (
          <BoothDrawer vm={floorPlanViewModel.boothDrawerVM} />
        )}

        {this.vm.check.show && (
          <OtherDrawer vm={floorPlanViewModel.otherDrawerVM} />
        )}

        {this.vm.check.show && (
          <ColorPickerModal vm={floorPlanViewModel.colorPickerModalVM} />
        )}

        {this.vm.check.show && (
          <FontSizeModal vm={floorPlanViewModel.fontSizeModalVM} />
        )}
      </div>
    );
  }
}

DrawFloorPlanPage.propTypes = {
  router: PropTypes.object.isRequired
};

DrawFloorPlanPage.defaultProps = {};

export default withRouter(DrawFloorPlanPage);
