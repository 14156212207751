export const MODES = {
  edit: 'edit',
  read: 'read'
};

export const LANGUAGES = {
  zh: 'zh',
  en: 'en'
};

export const COLORS = {
  normal: {
    border: '#808080',
    text: '#212323'
  },
  active: {
    border: '#D94E56',
    text: '#FFFFFF'
  }
};

export const OBJECT_BORDER_WIDTH = {
  normal: 1,
  active: 3
};

export const OBJECT_ZINDEXS = {
  background: 1,
  grid: 2,

  group: {
    normal: 10,
    active: 11
  },

  // 在 groups 內
  rect: 1,
  border: 2,

  pillar: 20,

  selectPath: 50,

  // 在 map 內
  icon: {
    normal: 30,
    active: 31
  },

  // 在 map 內
  text: {
    normal: 40,
    active: 41
  }
};

export const OBJECT_TYPES = {
  group: 'group',
  rect: 'rect',
  company: 'company',
  boothNumber: 'boothNumber',
  markText: 'markText',
  markIcon: 'markIcon',
  areaName: 'areaName'
};

export const OUTSIDE_EVENTS = {
  created: 'created',
  selected: 'selected',
  companiesRemoved: 'companiesRemoved',
  boothRemoved: 'boothRemoved',
  merged: 'merged',
  stamped: 'stamped',
  moved: 'moved',
  pasted: 'pasted',

  setGroupsColor: 'setGroupsColor',
  setFontSize: 'setFontSize',

  // 上一步
  backRemoveCompanies: 'backRemoveCompanies',
  backRemoveBooth: 'backRemoveBooth',
  backStamp: 'backStamp',

  error: 'error'
};

export const EVENT_CODES = {
  leftButton: 1,
  rightButton: 0,
  ctrl: 17,
  command: 91,
  shift: 16,
  space: 32,
  z: 90
};

export const ERRORS = {
  '0x001': {
    code: '0x001',
    message: 'move error: group overlapping'
  },
  '0x002': {
    code: '0x002',
    message: 'create error: group overlapping'
  },
  '0x003': {
    code: '0x003',
    message: 'paste error: group overlapping'
  },
  // 沒有選到任何人
  '0x005': {
    code: '0x005',
    message: 'select error: nothing selected'
  }
};

export const BACKGROUND_TYPES = {
  svg: 'svg',
  jpeg: 'jpeg',
  jpg: 'jpg'
};

export const DEVICE_PIXEL_RATIO = window.devicePixelRatio || 1;

export const FONT_FAMILY = 'Noto Sans TC, Microsoft JhengHei, Roboto, Arial';
export const FONT_FAMILY_BOOTH_NUMBER = 'Crimson Text, Microsoft JhengHei, Roboto, Arial';

export const HISTORIES = {
  createBooth: 'createBooth',
  move: 'move',
  setBoothsColor: 'setBoothsColor',
  setTextsFontSize: 'setTextsFontSize',
  // 貼上攤位
  pasteBooths: 'pasteBooths',
  // 刪除參展商
  removeCompanies: 'removeCompanies',
  // 刪除攤位
  removeBooths: 'removeBooths',
  // 合併攤位
  mergeBooths: 'mergeBooths',
  // 參展商蓋章
  stamp: 'stamp',
  // 更新攤位的參展商和專區名稱
  companies: 'companies',
  // 更新攤位的地標或攤位編號
  other: 'other'
};

// 字型下載時間限制
export const FONT_LOAD_TIMEOUT = 90000;

export const SCALE = {
  max: {
    default: 1.5,
    move: 1.5
  }
};
