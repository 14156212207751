import React from 'react';
import * as PIXI from 'pixi.js';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { Viewport } from 'pixi-viewport';
import { delay } from 'src/utils';

const WORLD_SIZE = 2000;
const STAR_SIZE = 30;
const BORDER = 10;

function overlap(x, y, viewport, starSize) {
  const size = starSize;

  // eslint-disable-next-line no-restricted-syntax
  for (const child of viewport.children) {
    if (
      x < child.x + size
      && x + size > child.x
      && y < child.y + size
      && y + size > child.y
    ) {
      return true;
    }
  }

  return false;
}

function randomInt(n) {
  return Math.floor(Math.random() * n);
}

function randomFloat(n) {
  return Math.random() * n;
}

function range(start, end, useFloat = false) {
  // case where there is no range
  if (end === start) {
    return end;
  }

  if (useFloat) {
    return randomFloat(end - start, true) + start;
  }

  let cRange;
  let cStart = start;

  if (start < 0 && end > 0) {
    cRange = -cStart + end + 1;
  } else if (cStart === 0 && end > 0) {
    cRange = end + 1;
  } else if (cStart < 0 && end === 0) {
    cRange = cStart - 1;
    cStart = 1;
  } else if (cStart < 0 && end < 0) {
    cRange = end - cStart - 1;
  } else {
    cRange = end - cStart + 1;
  }
  return randomInt(cRange) + cStart;
}

class ViewPortPageViewModel {
  isInit = true;

  app = null;
  viewport = null;

  containerRef = React.createRef();


  didMount = () => {
    if (this.isInit) {
      this.init();
      this.initBorder();
      this.initRects();

      this.isInit = false;
    }

    const userAgent = window.navigator.userAgent.toLocaleLowerCase();
    const index = userAgent.lastIndexOf(' ') + 1;
    const system = userAgent.slice(index);

    this.userAgent = userAgent;

    switch (system) {
      case 'android':
        document.addEventListener('message', this.onMessage);
        break;

      case 'ios':
        window.addEventListener('message', this.onMessage);
        break;

      default:
        break;
    }

    const res = JSON.stringify({ type: 'didMount' });

    window?.ReactNativeWebView?.postMessage(res);
  };

  willUnmount = () => {
    switch (this.userAgent) {
      case 'android':
        document.removeEventListener('message', this.onMessage);
        break;

      case 'ios':
        window.removeEventListener('message', this.onMessage);
        break;

      default:
        break;
    }

    const res = JSON.stringify({ type: 'willUnmount' });

    window?.ReactNativeWebView?.postMessage(res);
  };

  init = () => {
    const parent = this.containerRef.current;
    const pixelRatio = window.devicePixelRatio || 1;

    const app = new PIXI.Application({
      resizeTo: parent,
      background: '#cccccc',
      resolution: pixelRatio,
      antialias: true
    });

    const viewport = new Viewport({
      screenWidth: parent.clientWidth,
      screenHeight: parent.clientWidth,
      worldWidth: WORLD_SIZE,
      worldHeight: WORLD_SIZE,
      events: app.renderer.events,
      passiveWheel: false,
      stopPropagation: true,
      disableOnContextMenu: true
    });

    viewport.drag();
    viewport.wheel();
    viewport.pinch();

    app.stage.addChild(viewport);

    this.app = app;
    this.viewport = viewport;

    this.containerRef.current.appendChild(app.view);
  };

  initBorder = () => {
    const line = new PIXI.Graphics();

    line.lineStyle(10, 0xff0000);
    line.drawRect(0, 0, WORLD_SIZE, WORLD_SIZE);

    this.viewport.addChild(line);
  };

  initRects = () => {
    for (let i = 0; i < 1000; i += 1) {
      const star = new PIXI.Sprite(PIXI.Texture.WHITE);

      star.anchor.set(0.5);
      star.tint = randomInt(0xffffff);
      star.width = STAR_SIZE;
      star.height = STAR_SIZE;
      star.alpha = range(0.25, 1, true);
      star.interactive = true;

      let x;
      let y;

      do {
        x = range(
          STAR_SIZE / 2 + BORDER,
          this.viewport.worldWidth - STAR_SIZE - BORDER
        );
        y = range(BORDER, this.viewport.worldHeight - BORDER - STAR_SIZE);
      } while (overlap(x, y, this.viewport, STAR_SIZE));

      star.position.set(x, y);

      this.viewport.addChild(star);
    }
  };

  onMessage = (event) => {
    const data = JSON.parse(event.data);
    const res = { id: data.id, type: data.type };

    window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
  };
}

export default ViewPortPageViewModel;
