class TagDataModel {
  constructor(props) {
    this.id = props.id;

    // 固定標籤會有的 id
    this.tagPoolId = props.tagPoolId === null ? null : String(props.tagPoolId);

    this.name = props.name;
  }
}

export default TagDataModel;
