import { makeObservable, observable, action, computed } from 'mobx';


class StatusStore {
  @observable isLoading = false;

  constructor() {
    makeObservable(this);
  }

  @action setLoading = () => {
    this.isLoading = true;
  };

  @action setCompleted = () => {
    this.isLoading = false;
  };
}

const statusStore = new StatusStore();

export default statusStore;
