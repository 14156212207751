import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import ViewPortPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ViewPortPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ViewPortPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  render() {
    return (
      <div className={styles.container} ref={this.vm.containerRef} />
    );
  }
}

ViewPortPage.propTypes = {};

ViewPortPage.defaultProps = {};

export default ViewPortPage;
