import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import FloorPlanService from 'src/services/floorPlanService';
import { delay } from 'src/utils';

class CustomTagViewModel {
  mapId = null;

  @observable id = null;
  @observable name = null;
  @observable backupName = null;

  @observable isEdit = false;
  @observable isAwait = false;

  inputRef = React.createRef();

  onCustomTagEdited = null;
  onCustomTagRemoved = null;

  @computed
  get show() {
    return {
      input: this.isEdit,
      tag: !this.isEdit
    };
  }

  @computed
  get disabled() {
    return {
      input: this.isAwait,
      tag: this.isAwait
    };
  }

  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.backupName = props.name;
    this.fromServer = props.fromServer;

    makeObservable(this);
  }

  init = (props) => {
    this.mapId = props.router.params.id;
    this.onCustomTagEdited = props.onCustomTagEdited;
    this.onCustomTagRemoved = props.onCustomTagRemoved;
  };

  @action onTagClick = () => {
    if (!this.isAwait) {
      this.isEdit = true;
      setTimeout(this.inputRef.current.focus, 0);
    }
  };

  @action onNameChange = (event) => {
    this.name = event.target.value;
  };

  @action onPressEnter = () => {
    if (!this.isAwait) {
      const checkName = !!this.name.trim();

      if (checkName) {
        this.putMapCustomTagAPI();
      } else {
        this.name = this.backupName;
      }
    }
  };

  @action onBlur = () => {
    this.name = this.backupName;
    this.isEdit = false;
  };

  @action onClose = () => {
    this.deleteMapCustomTagAPI();
  };

  @action putMapCustomTagAPI = async () => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.putMapCustomTag({
        mapId: this.mapId,
        tagId: this.id,
        name: this.name
      });

      runInAction(() => {
        this.backupName = this.name;
        this.onCustomTagEdited();
      });
    } catch (error) {
      console.log('CustomTagViewModel', 'putMapCustomTagAPI', error);
    } finally {
      runInAction(() => {
        this.isEdit = false;
        this.isAwait = false;
      });
    }
  };

  @action deleteMapCustomTagAPI = async () => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.deleteMapCustomTag({
        mapId: this.mapId,
        tagId: this.id
      });

      this.onCustomTagRemoved(this.id);
    } catch (error) {
      console.log('CustomTagViewModel', 'deleteMapCustomTagAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CustomTagViewModel;
