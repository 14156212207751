import { MODES } from '../global/constants';
import Background from '../components/Background';
import Select from '../classes/Select';
import ReadSelect from '../classes/ReadSelect';
import DragMapControl from '../classes/DragMapControl';
import CreateGroup from '../classes/CreateGroup';
import Grid from '../components/Grid';
import Menu from '../components/Menu';
import DragObject from '../classes/DragObject';
import ResizeViewport from '../classes/ResizeViewport';
import Stamp from '../classes/Stamp';
import History from '../classes/History';

class Init {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  init = () => {
    const background = new Background(this.parent.context);
    const grid = new Grid(this.parent.context);
    const menu = new Menu(this.parent.context);

    const select = new Select(this.parent.context);
    const readSelect = new ReadSelect(this.parent.context);
    const stamp = new Stamp(this.parent.context);

    const dragObject = new DragObject(this.parent.context);
    const dragMapControl = new DragMapControl(this.parent.context);

    const resizeViewport = new ResizeViewport(this.parent.context);

    const createGroup = new CreateGroup(this.parent.context);

    const history = new History(this.parent.context);

    // context

    this.parent.context.setKeyValue('floorPlan', this.parent);

    this.parent.context.setKeyValue('background', background);
    this.parent.context.setKeyValue('menu', menu);
    this.parent.context.setKeyValue('grid', grid);

    this.parent.context.setKeyValue('select', select);
    this.parent.context.setKeyValue('readSelect', readSelect);
    this.parent.context.setKeyValue('stamp', stamp);

    this.parent.context.setKeyValue('dragObject', dragObject);
    this.parent.context.setKeyValue('dragMapControl', dragMapControl);

    this.parent.context.setKeyValue('resizeViewport', resizeViewport);

    this.parent.context.setKeyValue('createGroup', createGroup);

    this.parent.context.setKeyValue('history', history);
  };

  modules = (props) => {
    const res = props.mode === MODES.edit ? MODES.edit : MODES.read;

    this.parent.context.store.setMode(res);

    this.parent.context.background.init();

    switch (res) {
      // 編輯模式
      case MODES.edit: {
        this.parent.context.viewport.drag();

        this.parent.context.viewport.wheel({ smooth: false, interrupt: true });

        this.parent.context.select.init();

        this.parent.context.dragMapControl.init();
        this.parent.context.dragObject.init();

        this.parent.context.createGroup.init();

        this.parent.context.stamp.init();

        this.parent.context.grid.init();
        this.parent.context.menu.init();
        break;
      }

      case MODES.read: {
        this.parent.context.viewport.drag({ factor: 2 });
        this.parent.context.viewport.pinch({ noDrag: true });
        this.parent.context.readSelect.init();
        break;
      }

      default:
        break;
    }
  };
}

export default Init;
