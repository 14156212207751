import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import FloorPlanService from 'src/services/floorPlanService';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import CustomTagViewModel from './components/CustomTag/viewModel';

class BoothTagModalViewModel {
  mapId = null;

  // 預設
  @observable fixed = []; // 選項
  @observable selected = []; // 已選的

  // 自訂
  @observable custom = [];

  @observable isAwait = false;

  modalVM = new CustomModalViewModel();

  floorPlanVM = null;

  @computed
  get disabled() {
    return {
      select: this.isAwait,
      confirm: this.isAwait
    };
  }

  @computed
  get show() {
    return {
      createInput: this.isCreate,
      createTag: !this.isCreate
    };
  }

  @computed
  get mapTags() {
    return this.fixed.map((item) => {
      return { value: item.id, label: item.name };
    });
  }

  @computed
  get concatTags() {
    const selected = this.selected.map((item) => {
      return {
        id: item.value,
        name: item.label
      };
    });
    const custom = this.custom.map((item) => {
      return {
        id: item.id,
        name: item.name
      };
    });

    // BoothDrawerViewModel 需要 CustomTagViewModel
    return selected.concat(custom);
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  // 缺已選的固定標籤
  // 缺已輸入的自訂標籤
  init = (props) => {
    this.mapId = props.router.params.id;
  };

  didMount = async () => {
    if (!this.isAwait) {
      await Promise.all([
        this.getDefaultTagsAPI(),
        this.getSelectedTagsAPI(true)
      ]);
    }
  };

  open = () => {
    this.modalVM.open();

    this.floorPlanVM.clearSwitch('create');
  };

  close = () => {
    this.getSelectedTagsAPI(true);
    this.modalVM.close();
  };

  // 上一步關掉彈窗使用
  closeNoAPI = () => {
    this.modalVM.close();
  };

  updateBoothDrawerTags = (tags = []) => {
    const selected = tags.filter((item) => item.tagPoolId).map((item) => {
      return {
        id: item.id,
        name: item.name
      };
    });
    const custom = tags.filter((item) => !item.tagPoolId).map((item) => {
      return {
        id: item.id,
        name: item.name
      };
    });
    const resTags = selected.concat(custom);

    this.floorPlanVM.sets.setTagPool(resTags);

    console.log('標籤池', resTags);
  };

  @action onSelectedChange = (val) => {
    this.selected = val;
    this.putMapTagDefaultAPI();

    console.log('onSelectedChange', val);
  };

  onCustomTagCreated = () => {
    this.getSelectedTagsAPI();
  };

  onCustomTagEdited = () => {
    this.getSelectedTagsAPI();
  };

  @action onCustomTagRemoved = (id) => {
    this.custom = this.custom.filter((item) => item.id !== id);
  };

  // 預設標籤列表選項
  @action getDefaultTagsAPI = async () => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.getMapTagListDefault();

      runInAction(() => {
        this.fixed = res.data;
      });
    } catch (error) {
      console.log('BoothTagModalViewModel', 'getMapTagListDefault', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 地圖已經選擇的標籤, 固定 + 自訂
  @action getSelectedTagsAPI = async (flag = false) => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.getMapTagList({
        mapId: this.mapId
      });

      runInAction(() => {
        const selected = res.data.filter((item) => item.tagPoolId);
        const custom = res.data.filter((item) => !item.tagPoolId);

        this.selected = selected.map((item) => {
          return {
            value: item.tagPoolId,
            label: item.name
          };
        });

        this.custom = custom.map((item) => {
          return new CustomTagViewModel({ id: item.id, name: item.name });
        });

        if (flag) {
          this.updateBoothDrawerTags(res.data);
        }
      });
    } catch (error) {
      console.log('BoothTagModalViewModel', 'getMapTagListDefault', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新選擇的固定標籤
  @action putMapTagDefaultAPI = async () => {
    this.isAwait = true;

    try {
      const tags = this.selected.map((item) => Number(item.value));
      const res = await FloorPlanService.putMapTagDefault({
        mapId: this.mapId,
        tags
      });
    } catch (error) {
      console.log('BoothTagModalViewModel', 'putMapTagDefault', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default BoothTagModalViewModel;
