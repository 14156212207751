import { DEVICE_PIXEL_RATIO } from '../global/constants';

class ReadSelect {
  group = null;

  isMove = false;
  isPause = false;

  clientDown = null;
  clientEnd = null;

  scaledDown = 1;
  scaledEnd = 1;

  context = null;

  constructor(context) {
    this.context = context;
  }

  init = () => {
    this.isActive = true;

    this.context.viewport.on('pointerdown', this.onViewportPointerdown);
    this.context.viewport.on('pointermove', this.onViewportPointermove);

    /**
     * viewport 可以在拖移和縮放時, 可以阻止 pointer 系列的冒泡, 故開啟彈窗改使用 pointerup, 並將事件綁定在最外層的 container
     */
    this.context.container.on('pointerup', this.onContainerPointerup);
    // this.context.container.on('touchend', this.onContainerPointerup);
  };

  destroy = () => {};

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  getDownStatus = () => {
    this.clientDown = {
      x: this.context.viewport.x,
      y: this.context.viewport.y
    };

    this.scaledDown = this.context.viewport.scaled;
  };

  getEndStatus = () => {
    this.clientEnd = {
      x: this.context.viewport.x,
      y: this.context.viewport.y
    };

    this.scaledEnd = this.context.viewport.scaled;
  };

  checkChange = () => {
    const diffX = Math.abs(this.clientDown.x - this.clientEnd.x) > 1;
    const diffY = Math.abs(this.clientDown.y - this.clientEnd.y) > 1;
    const diffScaled = Math.abs(this.scaledDown - this.scaledEnd) > 0.1;

    return diffX || diffY || diffScaled;
  };

  // eslint-disable-next-line consistent-return
  onChildTouchstart = (event, target) => {
    if (this.isPause) {
      return false;
    }

    this.isMove = false;

    this.getDownStatus();
    this.getEndStatus();
  };

  // eslint-disable-next-line consistent-return
  onChildTouchend = (event, target) => {
    if (this.isPause) {
      return false;
    }

    this.group = target.group;
  };

  // eslint-disable-next-line consistent-return
  onViewportPointerdown = () => {
    this.group = null;
    this.isMove = false;

    if (this.isPause) {
      return false;
    }

    this.getDownStatus();
    this.getEndStatus();
  };

  // 有拖移或縮放就取消 onViewportPointerdown onChildTouchend 的選取
  // eslint-disable-next-line consistent-return
  onViewportPointermove = () => {
    if (this.isPause) {
      this.group = null;
      this.isMove = false;

      return false;
    }

    this.getEndStatus();

    if (this.checkChange()) {
      this.group = null;
      this.isMove = true;
    }
  };

  // eslint-disable-next-line consistent-return
  onContainerPointerup = () => {
    // 清除紀錄的手指, 避免卡住
    this.context.viewport.refresh('pinch');

    if (this.isMove) {
      return false;
    }

    if (this.group) {
      this.context.store.updateSelects([this.group]);
    } else {
      this.context.store.updateSelects([]);
    }

    // 外部使用
    this.context.events.onSelected();

    this.group = null;
    this.isMove = false;
  };
}

export default ReadSelect;
