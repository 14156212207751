import React from 'react';
import FloorPlan from 'src/components/FloorPlan';
import { delay } from 'src/utils';

class ForWebViewFloorPlanPageViewModel {
  userAgent = '';
  floorPlan = null;
  containerRef = React.createRef();

  didMount = () => {
    const userAgent = window.navigator.userAgent.toLocaleLowerCase();
    // const index = userAgent.lastIndexOf(' ') + 1;
    // const system = userAgent.slice(index);

    // switch (system) {
    //   case 'android':
    //     document.addEventListener('message', this.onMessage);
    //     break;

    //   case 'ios':
    //     window.addEventListener('message', this.onMessage);
    //     break;

    //   default:
    //     break;
    // }

    this.userAgent = userAgent;

    document.addEventListener('message', this.onMessage);
    window.addEventListener('message', this.onMessage);

    const res = JSON.stringify({ type: 'didMount' });

    window.ReactNativeWebView.postMessage(res);
  };

  willUnmount = () => {
    document.removeEventListener('message', this.onMessage);
    window.removeEventListener('message', this.onMessage);

    // switch (this.userAgent) {
    //   case 'android':
    //     document.removeEventListener('message', this.onMessage);
    //     break;

    //   case 'ios':
    //     window.removeEventListener('message', this.onMessage);
    //     break;

    //   default:
    //     break;
    // }

    const res = JSON.stringify({ type: 'willUnmount' });

    window.ReactNativeWebView.postMessage(res);
  };

  // 註冊到地圖
  onSelected = (data) => {
    const res = JSON.stringify({ type: 'selected', data });

    window.ReactNativeWebView.postMessage(res);
  };

  /**
   * 應該要收到的東西
   * @param {object} event.data
   * @param {string} event.data.id
   * @param {string} event.data.type
   * @param {any[]} event.data.params
   */
  onMessage = async (event) => {
    try {
      const data = JSON.parse(event.data);
      this[data.type](data);
    } catch (error) {
      console.log(error);
    }
  };

  init = async (props) => {
    const res = { id: props.id, type: props.type };

    try {
      const data = props.params[0];
      const map = data.map;
      const background = data.background;
      const language = data.language;

      const floorPlan = await FloorPlan.load({
        parent: this.containerRef.current,
        mode: FloorPlan.MODES.read,
        map,
        background,
        language
      });

      floorPlan.addEventListener('selected', this.onSelected);

      this.floorPlan = floorPlan;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
    } catch (error) {
      const msg = `init error: ${JSON.stringify(error)}`;

      res.error = error;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));

      window.alert(error);
    }
  };

  /**
   * 更新參展商名字
   * @param {object[]} companies
   * @param {string} language
   */
  setCompaniesName = (data) => {
    const res = {
      id: data.id,
      type: data.type
    };

    try {
      const companies = data.params[0];
      const language = data.params[1];

      this.floorPlan.setTextContentById(companies, language);

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
    } catch (error) {
      const msg = `setCompaniesName error: ${JSON.stringify(error)}`;

      res.error = error;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));

      window.alert(error);
    }
  };

  /**
   * 將想要搜尋的目標傳入, keyword or 參展商的 id
   * @param {string[]} ids
   */
  search = (props) => {
    const res = {
      id: props.id,
      type: props.type
    };

    try {
      const ids = props.params[0];
      const data = this.floorPlan.searchGroupByAnything(ids);

      res.data = data;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
    } catch (error) {
      const msg = `search error: ${JSON.stringify(error)}`;

      res.error = error;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));

      window.alert(error);
    }
  };

  highlight = (data) => {
    const res = {
      id: data.id,
      type: data.type
    };

    try {
      const ids = data.params[0];
      const color = data.params[1];

      ids.forEach((id) => this.floorPlan.setBoothColor(id, color));

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
    } catch (error) {
      const msg = `highlight error: ${JSON.stringify(error)}`;

      res.error = error;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));

      window.alert(error);
    }
  };

  moveTo = async (data) => {
    const res = {
      id: data.id,
      type: data.type
    };

    try {
      const ids = data.params[0];
      const checkIds = !!ids.length;

      if (checkIds) {
        await this.floorPlan.moveToGroup(ids[0]);
      } else {
        await this.floorPlan.moveToCenter();
      }

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));
    } catch (error) {
      const msg = `moveTo error: ${JSON.stringify(error)}`;

      res.error = error;

      window.ReactNativeWebView.postMessage(JSON.stringify({ ...res }));

      window.alert(error);
    }
  };
}

export default ForWebViewFloorPlanPageViewModel;
