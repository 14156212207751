import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Input, Form, Button } from 'antd';
import withRouter from 'src/components/withRouter';

import Logo from 'src/assets/logo.svg';

import styles from './styles.module.scss';

import ViewModel from './viewModel';

@observer
class LoginPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ViewModel();
  }

  render() {
    const { router } = this.props;
    return (
      <div className={styles.pageContainer}>
        <img src={Logo} alt="" className={styles.mainIcon} />
        <div className={styles.title}>
          登入 Login
        </div>
        <Form
          name="loginForm"
          className={styles.form}
          onFinish={(value) => this.viewModel.onSubmit(value, router)}
          onFinishFailed={this.viewModel.onSubmitFailed}
          autoComplete="off"
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: '請輸入你的帳號'
              }
            ]}
          >
            <Input
              placeholder="username"
              prefix={<UserOutlined className={styles.icon} />}
              className={styles.input}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: '請輸入你的密碼'
              }
            ]}
          >
            <Input.Password
              placeholder="password"
              prefix={<LockOutlined className={styles.icon} />}
              className={styles.input}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={styles.submitBtn}
            >
              登入
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

LoginPage.propTypes = {
  router: PropTypes.object
};

LoginPage.defaultProps = {
  router: {}
};


export default withRouter(LoginPage);
