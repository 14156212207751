import { makeObservable, observable, computed, action } from 'mobx';

/**
 * CompanyDrawer 使用
 * BoothDrawer 使用
 */
class CompanyViewModel {
  id = '';
  exhibitionId = '';

  @observable name = '';
  @observable zhName = '';
  @observable enName = '';
  @observable boothNumber = '';
  @observable regNo = '';

  // 參展商定位的地圖, 不一定是當前的地圖
  @observable mapId = '';

  // 在這張地圖有定位
  @observable positioned = false;

  floorPlanVM = null;

  @computed
  get check() {
    return {
      isAnywherePositioned: !!this.mapId,
      isActive: this === this.floorPlanVM.company,
      seal: !this.positioned,
      mapId: !!this.mapId.trim()
    };
  }

  @computed
  get label() {
    return `${this.boothNumber} ${this.regNo} ${this.name}`;
  }

  constructor(props) {
    this.id = props.data.id;

    this.name = props.data.name;
    this.zhName = props.data.zhName;
    this.enName = props.data.enName;

    this.boothNumber = props.data.boothNumber;

    this.regNo = props.data.regNo;

    this.mapId = props.data.mapId;

    this.exhibitionId = props.exhibitionId;

    this.floorPlanVM = props.floorPlanVM;

    makeObservable(this);
  }

  @action setBoothNumber = (val) => {
    this.boothNumber = val;
  };

  @action setPositioned = (val) => {
    this.positioned = val;
  };

  @action setZhName = (val) => {
    this.zhName = val;
  };

  @action setEnName = (val) => {
    this.enName = val;
  };

  @action setMapId = (val) => {
    this.mapId = val;
  };

  // 蓋章
  onSealClick = () => {
    this.floorPlanVM.sets.setStampCompany(this);

    console.log('要蓋章的參展商', this);
  };

  // 地圖移動到這個參展商
  onPositionClick = () => this.floorPlanVM.sets.setPosition(this);
}

export default CompanyViewModel;
