import {
  OBJECT_TYPES,
  COLORS,
  FONT_FAMILY,
  FONT_FAMILY_BOOTH_NUMBER
} from '../../global/constants';
import { pickTextColorBasedOnBgColorAdvanced } from '../../global/methods';
import TextDataModel from '../../dataModels/TextDataModel';
import MarkDataModel from '../../dataModels/MarkIconDataModel';
import Mark from '../Mark';
import Text from '../Text';

class Set {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  clone = (data) => {
    const res = {};

    Object.keys(data).forEach((key) => {
      res[key] = data[key];
    });

    return res;
  };

  /**
   * 參展商
   * @param {object} props
   * @param {string} props.id
   *
   * @param {string} props.content
   * @param {string} props.fontSize
   * @param {string} props.language
   */
  setCompany = (props) => {
    const findCompany = this.parent.companies[props.id];
    const findCompanyText = this.parent.companies[props.id]?.[props.language];

    const data = new TextDataModel({
      id: props.id,
      type: OBJECT_TYPES.company,
      language: props.language,
      content: props.content,
      fontFamily: FONT_FAMILY,
      fontSize: props.fontSize,
      translateX: this.parent.get.companyBasic.x,
      translateY: this.parent.get.companyBasic.y,
      fill: COLORS.normal.text,
      visible: props.language === this.parent.context.store.language
    });
    const text = new Text({
      group: this.parent,
      context: this.parent.context,
      data
    });

    if (findCompanyText) {
      findCompanyText.updateContent(props.content);
      findCompanyText.updateFontSize(props.fontSize);
    } else if (findCompany) {
      findCompany[props.language] = text;

      text.addChildToMap();
    } else {
      const newCompanies = this.parent.companies || {};
      const newCompany = {};

      newCompany[props.language] = text;
      newCompanies[props.id] = newCompany;

      text.addChildToMap();

      this.parent.setCompanies(newCompanies);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  // 攤位編號
  setBoothNumber = (props) => {
    if (this.parent.boothNumber) {
      this.parent.boothNumber.updateId(props.content);
      this.parent.boothNumber.updateContent(props.content);
      this.parent.boothNumber.updateFontSize(props.fontSize);
    } else {
      const data = new TextDataModel({
        id: props.content,
        type: OBJECT_TYPES.boothNumber,
        language: null,
        content: props.content,
        fontFamily: FONT_FAMILY_BOOTH_NUMBER,
        fontSize: props.fontSize,
        translateX: this.parent.get.boothNumberBasic.x,
        translateY: this.parent.get.boothNumberBasic.y,
        fill: COLORS.normal.text,
        visible: true
      });
      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data
      });

      text.addChildToMap();

      this.parent.setBoothNumber(text);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  // 只改攤位編號的文字
  // 攤位有可能沒有攤位編號？
  setBoothNumberById = (content) => {
    this.parent.boothNumber?.updateId(content);
    this.parent.boothNumber?.updateContent(content);
  };

  /**
   * 文字地標
   * @param {object} props
   * @param {string} props.id
   * @param {string} props.language
   * @param {string} props.content
   * @param {string} props.fontSize
   */
  setMarkText = (props) => {
    const cloneMarkText = this.clone(this.parent.markText);
    const findMarkText = cloneMarkText[props.language];

    if (findMarkText) {
      console.log('setMarkText', 'update', props);

      findMarkText.updateId(props.id);
      findMarkText.updateContent(props.content);
      findMarkText.updateFontSize(props.fontSize);
    } else {
      console.log('setMarkText', 'create', props);

      const data = new TextDataModel({
        id: props.id,
        type: OBJECT_TYPES.markText,
        language: props.language,
        content: props.content,
        fontFamily: FONT_FAMILY,
        fontSize: props.fontSize,
        translateX: this.parent.get.companyBasic.x,
        translateY: this.parent.get.companyBasic.y,
        fill: COLORS.normal.text,
        visible: props.language === this.parent.context.store.language
      });

      console.log('setMarkText', data);

      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data
      });

      text.addChildToMap();

      cloneMarkText[props.language] = text;

      this.parent.setMarkText(cloneMarkText);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  // 圖片地標
  setMarkIcon = async (props) => {
    if (this.parent.markIcon) {
      this.parent.markIcon.update({
        id: props.id,
        searchId: props.searchId,
        src: props.src
      });
    } else {
      const data = new MarkDataModel({
        id: props.id,
        searchId: props.searchId,
        type: OBJECT_TYPES.markIcon,
        src: props.src,
        translateX: this.parent.get.companyBasic.x,
        translateY: this.parent.get.companyBasic.y,
        visible: true
      });
      const icon = await Mark.forActive({
        context: this.parent.context,
        group: this.parent,
        data
      });

      icon.addChildToMap();

      this.parent.setMarkIcon(icon);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  /**
   * 專區名字
   * @param {object} props
   * @param {string} props.id
   * @param {string} props.language
   * @param {string} props.content
   * @param {string} props.fontSize
   */
  setAreaName = (props) => {
    const cloneAreaName = this.clone(this.parent.areaName);
    const findAreaName = cloneAreaName[props.language];

    if (findAreaName) {
      findAreaName.updateId(props.id);
      findAreaName.updateContent(props.content);
      findAreaName.updateFontSize(props.fontSize);
    } else {
      const data = new TextDataModel({
        id: props.id,
        type: OBJECT_TYPES.areaName,
        language: props.language,
        content: props.content,
        fontFamily: FONT_FAMILY,
        fontSize: props.fontSize,
        translateX: this.parent.get.companyBasic.x,
        translateY: this.parent.get.companyBasic.y,
        fill: COLORS.normal.text,
        visible: props.language === this.parent.context.store.language
      });
      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data
      });

      text.addChildToMap();

      cloneAreaName[props.language] = text;

      this.parent.setAreaName(cloneAreaName);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  setBoothColor = (val) => {
    this.parent.rects.forEach((item) => item.setColor(val));
  };

  setBoothHighlight = (val) => {
    const textColor = val
      ? pickTextColorBasedOnBgColorAdvanced(
        val,
        COLORS.normal.text,
        COLORS.active.text
      )
      : null;

    this.parent.rects.forEach((item) => item.setHighlight(val));
    this.parent.get.flat.texts.forEach((item) => item.setHighlight(textColor));
  };
}

export default Set;
