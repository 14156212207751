import { exhibition } from 'src/api';
import ExhibitorListItemDataModel from 'src/models/response/ExhibitorListItemDataModel';
import ExhibitionDataModel from 'src/models/response/ExhibitionDataModel';
import ExhibitorDataModel from 'src/models/response/ExhibitorDataModel';

export default class ExhibitionService {
  static async getExhibitions(params) {
    const res = await exhibition.getExhibitions(params);

    return res.data.result;
  }

  static async getAnnouncementExhibitionDetail(exhibitionId) {
    const res = await exhibition.getExhibitionDetail(exhibitionId);

    return res.data.result;
  }

  // 展覽列表
  static async getExhibitionList() {
    const res = await exhibition.getExhibitionList();

    return {
      data: res.data.list.map((item) => new ExhibitionDataModel(item))
    };
  }

  // 讀取參展商列表
  static async getExhibitionExhibitor({ id, sortBy, areaNo }) {
    const res = await exhibition.getExhibitionExhibitor({ id, sortBy, areaNo });

    return {
      data: res.data.result.list.map(
        (item) => new ExhibitorListItemDataModel(item)
      )
    };
  }

  // 讀取多個參展商的資料
  static async postExhibitorDetail(ids = []) {
    const exhibitors = ids.map((item) => Number(item));
    const res = await exhibition.postExhibitorDetail({ exhibitors });

    return {
      data: res.data.result.list.map((item) => new ExhibitorDataModel(item))
    };
  }

  // 編輯參展商資料
  static async putExhibitorDetail({ list }) {
    const data = list.map((item) => {
      return {
        id: Number(item.id),
        zhName: item.zhName,
        enName: item.enName,
        standNumber: item.boothNumber,
        mapId: item.mapId,
        tags: item.tags,
        exhibitionId: item.exhibitionId
      };
    });
    const res = await exhibition.putExhibitorDetail({ list: data });

    return {
      data: res.data
    };
  }
}
