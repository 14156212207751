import { makeObservable, observable, action, computed } from 'mobx';
import { ROUTES } from 'src/constants';
import i18n from 'src/i18n';
import { NotificationOutlined, FormOutlined } from '@ant-design/icons';
import UserService from 'src/services/user';

export default class TemplatePageViewModel {
  @observable isCollapse = false;
  @observable selectedKeys = [];

  @observable router = null;
  @observable profile = null;
  @observable currentPath = '';

  @observable isLogoutModalOpen = false;

  @computed get menu() {
    return [
      {
        key: ROUTES.Announcement,
        icon: <NotificationOutlined />,
        children: null,
        label: i18n.t('common_menu_announcement'),
        isShow: this.profile.group === 'admin'
      },
      {
        key: ROUTES.Map,
        icon: <FormOutlined />,
        children: null,
        label: i18n.t('common_menu_map'),
        isShow: true
      }
    ];
  }

  @computed get filterMenu() {
    return this.menu.filter((m) => m.isShow).map((m) => ({
      key: m.key,
      icon: m.icon,
      children: m.children,
      label: m.label
    }));
  }

  constructor(router) {
    makeObservable(this);

    this.init(router);
  }

  @action init = (router) => {
    this.router = router;

    this.profile = router.profile;
  };

  @action didUpdate = () => {
    if (this.router.location.pathname === this.currentPath) {
      return;
    }
    this.currentPath = this.router.location.pathname;
    const parentRoutes = this.router.location.pathname.split('/')[1];

    this.selectedKeys = [`/${parentRoutes}`];
  };

  @action toggleCollapse = () => {
    this.isCollapse = !this.isCollapse;
  };

  @action onMenuClick = (e) => {
    this.selectedKeys = [e.key];

    if (e.key === ROUTES.Announcement) {
      this.router.navigate(`${ROUTES.Announcement}?status=soon`);
      return;
    }
    this.router.navigate(e.key);
  };

  @action onLogoutClick = () => {
    this.isLogoutModalOpen = true;
  };

  @action onLogoutCancel = () => {
    this.isLogoutModalOpen = false;
  };

  @action onLogout = async () => {
    try {
      await UserService.logout();

      this.router.navigate(ROUTES.Login);
    } catch (error) {
      console.log(error);
    }
  };
}
