import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { COMPANIES_SORT_TYPES } from 'src/constants';
import statusStore from 'src/stores/status';
import ExhibitionService from 'src/services/exhibition';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import CompanyViewModel from './components/Company/viewModel';

class CompanyDrawerViewModel {
  @observable exhibitionId = null;

  // 展覽
  @observable exhibitions = [];

  // 展區
  @observable areaId = null;

  // 參展商
  @observable companies = [];

  // 篩選有 map id 的參展商
  @observable filterMapId = false;

  // BoothDrawer 需要使用
  // FloorPlan 清除廠商, 刪除攤位, 蓋章 ...
  allCompanies = {};

  @observable sortBy = null;

  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();

  floorPlanVM = null;

  @computed
  get mapExhibitions() {
    return this.exhibitions.map((item) => {
      return { value: item.id, label: item.name };
    });
  }

  @computed
  get mapSortTypes() {
    return [
      COMPANIES_SORT_TYPES.boothNumber,
      COMPANIES_SORT_TYPES.reg,
      COMPANIES_SORT_TYPES.boothSize
    ];
  }

  // 展區
  @computed
  get areas() {
    const exhibition = this.exhibitions.find(
      (item) => item.id === this.exhibitionId
    );
    const mapAreas = exhibition?.areas.map((area) => {
      return {
        value: area.areaNo,
        label: area.areaName
      };
    });

    return mapAreas || [];

  }

  @computed
  get disabled() {
    return {
      select: this.isAwait
    };
  }

  @computed
  get companiesLengthLabel() {
    return `共${this.filterCompanies.length}筆`;
  }

  // 未勾選時顯示全部的參展商
  @computed
  get filterCompanies() {
    return this.companies.filter((item) => {
      return this.filterMapId ? !item.check.mapId : true;
    });
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  @action init = (props) => {
    this.exhibitions = props.exhibitions;
    this.exhibitionId = props.exhibitions[0].id;

    this.sortBy = COMPANIES_SORT_TYPES.boothNumber.value;
  };

  didMount = async () => {
    this.getExhibitionExhibitorAPI();
  };

  open = () => this.drawerVM.open();

  close = () => this.drawerVM.close();

  // booth drawer 使用
  setZhName = (companyId, val) => {
    this.allCompanies[companyId]?.setZhName(val);
  };

  // booth drawer 使用
  setEnName = (companyId, val) => {
    this.allCompanies[companyId]?.setEnName(val);
  };

  // booth drawer 使用
  setBoothNumber = (companyId, val) => {
    this.allCompanies[companyId]?.setBoothNumber(val);
  };

  // booth drawer 使用
  setPositioned = (companyId, val) => {
    this.allCompanies[companyId]?.setPositioned(val);
  };

  // booth drawer 使用
  setMapId = (companyId, val) => {
    this.allCompanies[companyId]?.setMapId(val);
  };

  @action onExhibitionIdChange = (val) => {
    this.exhibitionId = val;
    this.areaId = null;

    this.reset();
  };

  @action onSortByChange = (val) => {
    this.sortBy = val;
    this.reset();
  };

  @action onAreaIdChange = (val) => {
    this.areaId = val;
    this.reset();
  };

  @action onAreaClear = () => {
    this.areaId = null;
    this.reset();
  };

  @action onFilterMapIdChange = (event) => {
    this.filterMapId = event.target.checked;
  };

  reset = async () => {
    statusStore.setLoading();

    await this.getExhibitionExhibitorAPI();

    statusStore.setCompleted();
  };

  @action getExhibitionExhibitorAPI = async () => {
    this.isAwait = true;

    try {
      const areaNo = this.areaId ? `${this.areaId}` : undefined;
      const res = await ExhibitionService.getExhibitionExhibitor({
        id: this.exhibitionId,
        sortBy: this.sortBy,
        areaNo
      });

      runInAction(() => {
        const data = [];
        const allCompanies = {};
        const companies = this.floorPlanVM.companies;

        res.data.forEach((item) => {
          const vm = new CompanyViewModel({
            data: item,
            exhibitionId: this.exhibitionId,
            floorPlanVM: this.floorPlanVM
          });

          const positioned = !!companies[item.id];

          vm.setPositioned(positioned);

          // 列表使用
          data.push(vm);

          // 地圖操作需要使用(清除廠商, 合併攤位, 刪除攤位...)
          // 蓋完章需要使用
          // BoothDrawer 需要使用
          allCompanies[item.id] = vm;
        });

        this.companies = data;
        this.allCompanies = allCompanies;
      });
    } catch (error) {
      console.log('CompanyDrawerViewModel', 'getExhibitionExhibitor', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CompanyDrawerViewModel;
