import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import './reset.scss';

function CustomCollapse(props) {
  const { items, active, ...ps } = props;
  const defaultActiveKey = active ? items[0].key : undefined;

  return (
    <Collapse
      className="booth-drawer-custom-collapse"
      items={items}
      defaultActiveKey={defaultActiveKey}
      {...ps}
    />
  );
}

CustomCollapse.propTypes = {
  items: PropTypes.array.isRequired,
  active: PropTypes.bool
};

CustomCollapse.defaultProps = {
  active: false
};

export default CustomCollapse;
