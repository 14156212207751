import TagDataModel from './TagDataModel';

class FloorPlanDataModel {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;

    this.map = props.map;

    this.background = props.background;

    this.exhibitions = props.exhibitions.map((item) => {
      const areas = item.area || [];
      const mapAreas = areas.map((area) => {
        return {
          areaNo: area.areaNo,
          areaName: area.areaName
        };
      });

      return {
        id: item.id,
        name: item.name,
        areas: mapAreas
      };
    });
  }
}

export default FloorPlanDataModel;
