import { request, getHost, getHeaders } from './utils';

export const login = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/login`,
    headers: getHeaders(),
    data
  };
  return request(options);
};


export const getProfile = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/user/me/profile`,
    headers: getHeaders()
  };
  return request(options);
};


export const logout = () => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/user/logout`,
    headers: getHeaders()
  };
  return request(options);
};
