import * as PIXI from 'pixi.js';
import { DropShadowFilter } from '@pixi/filter-drop-shadow';
import SelfButton from '../SelfButton';
import Group from '../Group';
import { FONT_FAMILY, DEVICE_PIXEL_RATIO } from '../../global/constants';

class MenuView {
  // 縮放時調整文字解析度
  texts = [];

  view = null;
  colorButton = null;
  clearButton = null;
  mergeButton = null;
  copyButton = null;
  pasteButton = null;
  removeButton = null;
  fontSizeButton = null;

  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  setPosition = (position) => {
    // 限制 menu 的座標

    // x 軸
    const x = position.x * DEVICE_PIXEL_RATIO;
    const menuWidth = this.view.width * DEVICE_PIXEL_RATIO;
    const screenWidth = this.parent.context.app.screen.width;
    const maxX = x + menuWidth;
    const resX = maxX > screenWidth ? position.x - this.view.width : position.x;

    // y 軸
    const y = position.y * DEVICE_PIXEL_RATIO;
    const menuHeight = this.view.height * DEVICE_PIXEL_RATIO;
    const screenHeight = this.parent.context.app.screen.height;
    const maxY = y + menuHeight;
    const resY
      = maxY > screenHeight ? position.y - this.view.height : position.y;

    this.view.x = resX;
    this.view.y = resY;
  };

  show = () => {
    if (this.view) {
      this.view.visible = true;
    }
  };

  hide = () => {
    if (this.view) {
      this.view.visible = false;
    }
  };

  disabled = (type) => {
    switch (type) {
      case 'color':
        this.colorButton.disabled();
        break;

      case 'clear':
        this.clearButton.disabled();
        break;

      case 'merge':
        this.mergeButton.disabled();
        break;

      case 'copy':
        this.copyButton.disabled();
        break;

      case 'paste':
        this.pasteButton.disabled();
        break;

      case 'remove':
        this.removeButton.disabled();
        break;

      case 'fontSize':
        this.fontSizeButton.disabled();
        break;

      default:
        this.colorButton.disabled();
        this.clearButton.disabled();
        this.mergeButton.disabled();
        this.copyButton.disabled();
        this.pasteButton.disabled();
        this.removeButton.disabled();
        this.fontSizeButton.disabled();
        break;
    }
  };

  reset = (type) => {
    switch (type) {
      case 'color':
        this.colorButton.reset();
        break;

      case 'clear':
        this.clearButton.reset();
        break;

      case 'merge':
        this.mergeButton.reset();
        break;

      case 'copy':
        this.copyButton.reset();
        break;

      case 'paste':
        this.pasteButton.reset();
        break;

      case 'remove':
        this.removeButton.reset();
        break;

      case 'fontSize':
        this.fontSizeButton.reset();
        break;

      default:
        this.colorButton.reset();
        this.clearButton.reset();
        this.mergeButton.reset();
        this.copyButton.reset();
        this.pasteButton.reset();
        this.removeButton.reset();
        this.fontSizeButton.reset();
        break;
    }
  };

  onStopPropagation = (event) => event.stopPropagation();

  renderView = () => {
    const view = new PIXI.Container();

    view.visible = false;

    const scale = 1 / DEVICE_PIXEL_RATIO;
    view.scale.set(scale);

    view.on('mousedown', this.onStopPropagation);
    view.on('mouseup', this.onStopPropagation);

    this.view = view;
  };

  renderBackground = () => {
    const rect = new PIXI.Sprite(PIXI.Texture.WHITE);
    const shadow = new DropShadowFilter({ blur: 5, alpha: 0.1, quality: 10 });

    rect.width = 80;
    rect.height = 232;

    rect.x = 0;
    rect.y = 0;

    rect.tint = '#ffffff';

    rect.filters = [shadow];

    this.view.addChild(rect);
  };

  renderButton = ({ x, y, text, onClicked }) => {
    const selfButton = new SelfButton({
      x,
      y,
      fontFamily: FONT_FAMILY,
      text,
      onClicked
    });

    this.texts.push(selfButton.text);

    return selfButton;
  };

  render = () => {
    this.renderView();
    this.renderBackground();

    this.colorButton = this.renderButton({
      x: 0,
      y: 4,
      text: '設定顏色',
      onClicked: this.parent.onSetGroupsColor
    });

    this.clearButton = this.renderButton({
      x: 0,
      y: 36,
      text: '清除廠商',
      onClicked: this.parent.onRemoveAllCompany
    });

    this.mergeButton = this.renderButton({
      x: 0,
      y: 68,
      text: '合併攤位',
      onClicked: this.parent.onMerge
    });

    this.copyButton = this.renderButton({
      x: 0,
      y: 100,
      text: '複製攤位',
      onClicked: this.parent.onCopy
    });

    this.pasteButton = this.renderButton({
      x: 0,
      y: 132,
      text: '在這貼上',
      onClicked: this.parent.onPaste
    });

    this.removeButton = this.renderButton({
      x: 0,
      y: 164,
      text: '刪除攤位',
      onClicked: this.parent.onRemove
    });

    this.fontSizeButton = this.renderButton({
      x: 0,
      y: 196,
      text: '字型大小',
      onClicked: this.parent.onFontSize
    });

    this.view.addChild(this.colorButton.view);
    this.view.addChild(this.clearButton.view);
    this.view.addChild(this.mergeButton.view);
    this.view.addChild(this.copyButton.view);
    this.view.addChild(this.pasteButton.view);
    this.view.addChild(this.removeButton.view);
    this.view.addChild(this.fontSizeButton.view);

    this.parent.context.container.addChild(this.view);
  };
}

export default MenuView;
