import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class PillarsSwitch extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return this.vm.show ? (
      <div className={clsx(['pillarsSwitch', styles.container])}>
        <div className={styles.label}>
          {i18n.t('draw_floor_plan_page_pillars_switch_label')}
        </div>

        <Switch
          checkedChildren={i18n.t(
            'draw_floor_plan_page_pillars_switch_checked_label'
          )}
          unCheckedChildren={i18n.t(
            'draw_floor_plan_page_pillars_switch_unchecked_label'
          )}
          checked={this.vm.checked}
          onChange={this.vm.onCheckedChange}
          disabled={this.vm.disabled.switch}
        />
      </div>
    ) : null;
  }
}

PillarsSwitch.propTypes = {
  vm: PropTypes.object.isRequired
};

PillarsSwitch.defaultProps = {};

export default PillarsSwitch;
