import i18n from 'src/i18n';

export const AUTH_TOKEN_KEY = 'auth';

export const ROUTES = {
  Login: '/login',
  Announcement: '/announcement',
  Map: '/map'
};

export const GROUP_TYPE = {
  admin: 'admin',
  announcementEditor: 'announcementEditor',
  mapEditor: 'mapEditor'
};

export const RECEIVER_TYPE_TC = {
  participant: i18n.t('ann_table_receiver_participant'),
  hasTicket: i18n.t('ann_table_receiver_hasTicket'),
  interesting: i18n.t('ann_table_receiver_interesting')
};

export const EReceiverType = Object.keys(RECEIVER_TYPE_TC).map((el) => ({
  value: el,
  label: RECEIVER_TYPE_TC[el]
}));

export const LANGUAGE_TYPE_TC = {
  chinese: i18n.t('ann_language_chinese'),
  english: i18n.t('ann_language_english')
};

export const ELanguageType = Object.keys(LANGUAGE_TYPE_TC).map((el) => ({
  value: el,
  label: LANGUAGE_TYPE_TC[el]
}));

export const EExhibitionStatusList = [
  {
    value: 'ing',
    label: i18n.t('ann_exhi_status_option_ongoing')
  },
  {
    value: 'soon',
    label: i18n.t('ann_exhi_status_option_upcoming')
  },
  {
    value: 'end',
    label: i18n.t('ann_exhi_status_option_over')
  }
];

export const EAnnouncementStatus = [
  {
    value: true,
    label: i18n.t('ann_status_select_option_sent')
  },
  {
    value: false,
    label: i18n.t('ann_status_select_option_unsend')
  }
];

export const COMPANIES_SORT_TYPES = {
  reg: {
    value: 'reg',
    label: i18n.t('draw_floor_plan_page_company_drawer_companies_sort_reg_label')
  },
  boothNumber: {
    value: 'boothno',
    label: i18n.t(
      'draw_floor_plan_page_company_drawer_companies_sort_booth_number_label'
    )
  },
  boothSize: {
    value: 'boothsize',
    label: i18n.t(
      'draw_floor_plan_page_company_drawer_companies_sort_booth_size_label'
    )
  }
};


export const FONT_SIZES = [
  {
    value: '8',
    label: '8'
  },
  {
    value: '10',
    label: '10'
  },
  {
    value: '12',
    label: '12'
  },
  {
    value: '14',
    label: '14'
  },
  {
    value: '16',
    label: '16'
  },
  {
    value: '18',
    label: '18'
  },
  {
    value: '20',
    label: '20'
  },
  {
    value: '24',
    label: '24'
  },
  {
    value: '28',
    label: '28'
  },
  {
    value: '30',
    label: '30'
  },
  {
    value: '32',
    label: '32'
  },
  {
    value: '36',
    label: '36'
  },
  {
    value: '40',
    label: '40'
  },
  {
    value: '48',
    label: '48'
  },
  {
    value: '56',
    label: '56'
  },
  {
    value: '64',
    label: '64'
  },
  {
    value: '72',
    label: '72'
  },
  {
    value: '84',
    label: '84'
  },
  {
    value: '96',
    label: '96'
  },
  {
    value: '128',
    label: '128'
  }
];

// OtherDrawer
export const MARK_TYPES = {
  common: 'common',
  custom: 'custom',
  icon: 'icon',
  text: 'text'
};

// 顏色檢查
export const RE_COLOR = /^#[0-9a-zA-Z]{6,6}$/;
export const RE_COLOR_START = /^#/;

// 地圖預設字體大小
export const FLOOR_PLAN_DEFAULT_FONT_SIZES = {
  area: {
    zh: '36',
    en: '24'
  },
  company: {
    zh: '28',
    en: '16'
  },
  mark: {
    zh: '28',
    en: '16'
  },
  boothNumber: '30'
};
