import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';

function ToolbarButton(props) {
  return (
    <div className={styles.container}>
      <Button
        type="ghost"
        className={clsx([styles.button, props.active && styles.active])}
        onClick={props.onClick}
      >
        <div className={styles.content}>
          <div className={styles.label}>{props.label}</div>

          <img
            src={props.iconSrc}
            alt={props.iconAlt}
            className={styles.icon}
          />
        </div>
      </Button>
    </div>
  );
}

ToolbarButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  iconAlt: PropTypes.string,
  active: PropTypes.bool
};

ToolbarButton.defaultProps = {
  onClick: () => {},
  label: '',
  iconSrc: '',
  iconAlt: '',
  active: false
};

export default ToolbarButton;
