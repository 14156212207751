import { DEVICE_PIXEL_RATIO, SCALE } from '../../global/constants';
import Events from './Events';
import Sets from './Sets';
import Deletes from './Deletes';

class Context {
  parent = null;
  floorPlan = null;
  app = null;
  container = null;
  viewport = null;

  map = null;

  store = null;

  pause = null;
  resume = null;

  // classes
  background = null;
  menu = null;
  grid = null;
  select = null;
  readSelect = null;
  stamp = null;
  dragObject = null;
  dragMapControl = null;
  resizeViewport = null;
  createGroup = null;
  history = null;

  // 拆模組
  events = new Events(this);
  sets = new Sets(this);
  deletes = new Deletes(this);

  minScale = 0;

  center = null;

  constructor(props) {
    this.parent = props.parent;

    this.app = props.app;
    this.container = props.container;
    this.viewport = props.viewport;

    this.map = props.map;

    this.store = props.store;

    this.pause = props.pause;
    this.resume = props.resume;

    this.updateFloorPlanArgs();
  }

  updateFloorPlanArgs = () => {
    const screenWidth = this.viewport.worldScreenWidth;
    const screenHeight = this.viewport.worldScreenHeight;

    const wordWidth = this.viewport.worldWidth;
    const wordHeight = this.viewport.worldHeight;

    const widthRatio = screenWidth / wordWidth;
    const heightRatio = screenHeight / wordHeight;

    const ratio = Math.min(widthRatio, heightRatio);
    const center = { x: wordWidth / 2, y: wordHeight / 2 };

    // 手動縮小的限制
    this.minScale = Math.round(ratio * 10000) / 10000;
    this.center = center;

    // 手動縮放時的限制
    this.viewport.clampZoom({
      minScale: this.minScale,
      maxScale: SCALE.max.default
    });
  };

  setKeyValue = (key, value) => {
    this[key] = value;
  };

  get isKeydown() {
    return this.dragMapControl.isKeydown;
  }

  get checkPillars() {
    return !!this.store.data.pillars.length;
  }

  get checkPillarsVisible() {
    return !!this.store.data.pillars[0]?.res.visible;
  }

  // 座標格式化
  formatPointToSize = (val, type = 'floor') => {
    switch (type) {
      case 'floor':
        return Math.floor(val / this.store.gridSize) * this.store.gridSize;

      case 'ceil':
        return Math.ceil(val / this.store.gridSize) * this.store.gridSize;

      default:
        return Math.round(val / this.store.gridSize) * this.store.gridSize;
    }
  };

  // 事件在地圖的座標
  getEventContainerPosition = (event) => {
    const global = event.data.global.clone();
    const localClient = event
      .getLocalPosition(this.container, global.clone())
      .clone();

    return localClient;
  };

  // 事件在地圖的座標
  getEventMapPosition = (event) => {
    const global = event.data.global.clone();
    const localClient = event
      .getLocalPosition(this.map, global.clone())
      .clone();

    return localClient;
  };

  closeMenu = () => {
    this.menu.close();
  };

  onChildRightMouseupFromMenu = (event) => {
    if (this.store.check.isEdit) {
      this.menu.onChildRightMouseup(event);
    }
  };

  updateGrid = () => {
    this.grid.update();
  };

  showGrid = () => {
    this.grid.show();
  };

  hideGrid = () => {
    this.grid.hide();
  };

  clearSelects = () => {
    this.select.clearSelects();
  };

  onMouseupFromSelect = (event, target) => {
    if (!this.isKeydown) {
      this.select.onChildMouseup(event, target);
    }
  };

  onChildTouchstartFromReadSelect = (event, target) => {
    this.readSelect.onChildTouchstart(event, target);
  };

  onChildTouchendFromReadSelect = (event, target) => {
    this.readSelect.onChildTouchend(event, target);
  };

  onMousedownFromDragObject = (event, target) => {
    if (this.store.check.isEdit && !this.isKeydown) {
      this.dragObject.onMousedown(event, target);
      event.stopPropagation();
    }
  };

  onResizeFromResizeViewport = () => {
    this.resizeViewport.resize();
  };

  onMouseupFromStamp = (event, target) => {
    if (this.store.check.isEdit && !this.isKeydown) {
      this.stamp.onMouseup(event, target);
    }
  };

  // 上傳背景圖

  setBackground = async (src) => {
    this.viewport.scaled = 1;

    // 畫出背景圖, 同時更新 store col row
    await this.background.setBackground(src);

    // 使用 store col row 畫網格
    if (this.store.check.isEdit) {
      this.updateGrid();
    }

    // 使用 store col row 和 devicePixelRatio 更新 viewport 的寬高
    this.onResizeFromResizeViewport();

    // 讀取 viewport 的寬高, 更新 scale center
    this.updateFloorPlanArgs();
  };

  updateBackground = async (src) => {
    this.viewport.scaled = 1;

    // 畫出背景圖, 同時更新 store col row
    await this.background.setBackground(src);

    // 使用 store col row 畫網格
    if (this.store.check.isEdit) {
      this.updateGrid();
    }

    // 使用 store col row 和 devicePixelRatio 更新 viewport 的寬高
    this.onResizeFromResizeViewport();

    // 讀取 viewport 的寬高, 更新 scale center
    this.updateFloorPlanArgs();

    await this.moveToCenter();
  };

  setStamp = () => {
    this.stamp.setStamp();
    this.closeMenu();
  };

  clearStamp = () => {
    this.stamp.clearStamp();
  };

  // 柱子的顯示或影藏

  showPillars = () => {
    this.store.data.pillars.forEach((item) => item.show());
  };

  hidePillars = () => {
    this.store.data.pillars.forEach((item) => item.hide());
  };

  getGroup = (id) => {
    return this.store.data.groups[id];
  };

  getGroups = () => {
    const groups = Object.values(this.store.data.groups);

    return groups.map((item) => item.get.resSimpleForOutside);
  };

  getCompanies = () => {
    const groups = Object.values(this.store.data.groups);
    const resCompanies = {};

    groups.forEach((item) => {
      const companies = Object.keys(item.companies);
      companies.forEach((key) => {
        resCompanies[key] = true;
      });
    });

    return resCompanies;
  };

  static getCompaniesByData = (props) => {
    const data = JSON.parse(decodeURIComponent(props || '{}'));
    const groups = Object.values(data.groups);
    const companies = [];

    groups.forEach((item) => {
      companies.push(...Object.keys(item.companies));
    });

    return Array(...new Set(companies));
  };

  moveToGroup = (groupId) => {
    return new Promise((res) => {
      const group = this.getGroup(groupId);
      const center = group.get.center;

      if (group) {
        this.viewport.animate({
          time: 300,
          position: center,
          scale: group.get.moveScale,
          callbackOnComplete: res
        });
      } else {
        console.log('moveToGroup', '沒有找到 group');

        res();
      }
    });
  };

  moveToCenter = (time = 300) => {
    return new Promise((res, rej) => {
      this.viewport.animate({
        time,
        position: this.center,
        scale: this.minScale,
        callbackOnComplete: res
      });
    });
  };

  moveLeftTop = (time = 300) => {
    const scale = Number((1 / DEVICE_PIXEL_RATIO).toFixed(4));

    return new Promise((res, rej) => {
      this.viewport.animate({
        time,
        position: { x: 0, y: 0 },
        scale,
        callbackOnComplete: res
      });
    });
  };

  searchGroupByAnything = (ids = []) => {
    const groups = this.store.flat.groups;

    return groups
      .filter((group) => !!ids.find((id) => group.search(id)))
      .map((item) => item.get.resSimpleForOutside);
  };

  // 合併地圖, 不檢查重複
  static mergeFloorPlan = (data = []) => {
    const maps = data.map((item) => {
      if (item.map) {
        return JSON.parse(decodeURIComponent(item.map));
      }

      return { groups: {}, version: '1.0' };
    });
    const res = {
      groups: {},
      version: '1.0'
    };

    maps.forEach((map) => {
      const groups = Object.keys(map.groups);

      groups.forEach((key) => {
        res.groups[key] = map.groups[key];
      });
    });

    return encodeURIComponent(JSON.stringify(res));
  };

  // 合併地圖, 加入柱子
  static mergePillars = (data, pillars) => {
    const map = JSON.parse(decodeURIComponent(data || '{}'));

    map.pillars = pillars;

    console.log('合併', '地圖', map);
    console.log('合併', '柱子', pillars);

    return encodeURIComponent(JSON.stringify(map));
  };
}

export default Context;
