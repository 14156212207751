import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import { computed } from 'mobx';
import { Divider, Button } from 'antd';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import CustomDrawer from 'src/components/CustomDrawer';
import CustomCollapse from './components/CustomCollapse';
import AreaName from './components/AreaName';
import BoothNumberFontSize from './components/BoothNumberFontSize';
import Company from './components/Company';
import styles from './styles.module.scss';

@observer
class BoothDrawer extends React.Component {
  @computed
  get areaNameItems() {
    return [
      {
        key: 'area-name',
        label: i18n.t('draw_floor_plan_page_booth_drawer_area_label'),
        children: (
          <AreaName
            vm={this.vm.areaNameVM}
            disabled={this.vm.disabled.areaName}
            onFocus={this.vm.pauseSelect}
            onBlur={this.vm.resumeSelect}
          />
        )
      }
    ];
  }

  @computed
  get companiesItems() {
    return [
      {
        key: 'companies',
        label: i18n.t('draw_floor_plan_page_booth_drawer_companies_label'),
        children: (
          <Observer>
            {() => {
              return (
                <div className={styles.companies}>
                  <div className={styles.boothNumberSize}>
                    <BoothNumberFontSize
                      vm={this.vm.boothNumberFontSizeVM}
                      onFocus={this.vm.pauseSelect}
                      onBlur={this.vm.resumeSelect}
                    />
                  </div>

                  {this.vm.companies.map((vm) => (
                    <Company
                      vm={vm}
                      disabled={this.vm.disabled.company}
                      key={vm.id}
                      tagPool={this.vm.floorPlanVM.mapTagPool}
                      onEditTagsClick={this.vm.floorPlanVM.boothTagModalVM.open}
                      onFocus={this.vm.pauseSelect}
                      onBlur={this.vm.resumeSelect}
                    />
                  ))}
                </div>
              );
            }}
          </Observer>
        )
      }
    ];
  }

  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      router: props.router
    });
  }

  render() {
    return (
      <CustomDrawer
        vm={this.vm.drawerVM}
        title={i18n.t('draw_floor_plan_page_booth_drawer_title')}
        placement="right"
        width={276}
        afterOpenChange={this.vm.onAfterOpenChange}
      >
        {this.vm.check.group ? (
          <div className={styles.container}>
            <CustomCollapse items={this.areaNameItems} />

            <Divider className={styles.divider} />

            <CustomCollapse items={this.companiesItems} active />

            <div className={styles.saveButtonContainer}>
              <Button
                type="primary"
                className={styles.saveButton}
                onClick={this.vm.onConfirm}
                disabled={this.vm.disabled.confirm}
              >
                {i18n.t(
                  'draw_floor_plan_page_booth_drawer_confirm_button_label'
                )}
              </Button>
            </div>
          </div>
        ) : null}
      </CustomDrawer>
    );
  }
}

BoothDrawer.propTypes = {
  router: PropTypes.object.isRequired,
  vm: PropTypes.object.isRequired
};

BoothDrawer.defaultProps = {};

export default withRouter(BoothDrawer);
