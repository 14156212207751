import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Tag } from 'antd';
import clsx from 'clsx';
import { PlusOutlined } from '@ant-design/icons';
import i18n from 'src/i18n';
import withRouter from 'src/components/withRouter';
import CreateTagViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class CreateTag extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new CreateTagViewModel({
      router: props.router,
      onCustomTagCreated: props.onCustomTagCreated
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <Input
          ref={this.vm.inputRef}
          type="text"
          size="small"
          className={clsx([styles.input, this.vm.show.input && styles.show])}
          value={this.vm.name}
          onChange={this.vm.onNameChange}
          onBlur={this.vm.onBlur}
          onPressEnter={this.vm.onPressEnter}
          disabled={this.vm.disabled.input}
          maxLength={20}
        />

        <Tag
          className={clsx([
            styles.tag,
            this.vm.show.tag && styles.show
          ])}
          onClick={this.vm.onTagClick}
        >
          <PlusOutlined className={styles.createIcon} />
          {i18n.t('draw_floor_plan_page_booth_tag_modal_create_tag_label')}
        </Tag>
      </div>
    );
  }
}

CreateTag.propTypes = {
  router: PropTypes.object.isRequired,
  onCustomTagCreated: PropTypes.func.isRequired
};

CreateTag.defaultProps = {};

export default withRouter(CreateTag);
