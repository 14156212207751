import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';

const defColor = '#FFFFFF';

class ColorPickerModalViewModel {
  @observable color = defColor;
  groups = [];
  floorPlanVM = null;

  timer = null;

  modalVM = new CustomModalViewModel();

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  @action open = (groups) => {
    this.groups = groups;

    this.modalVM.open();

    if (groups.length === 1) {
      this.color = groups[0].boothColor;
    } else {
      this.color = defColor;
    }

    console.log('ColorPickerModal', groups);
  };

  onConfirm = () => {
    this.floorPlanVM.sets.setFloorPlanBoothsColor(this.groups, this.color);
    this.floorPlanVM.updateFloorPlanAPI();
    this.close();
  };

  close = () => {
    this.modalVM.close();
  };

  @action onColorChange = (val) => {
    this.color = val.hex;
  };
}

export default ColorPickerModalViewModel;
