class Delete {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  // 刪除參展商
  removeCompany = (id) => {
    const companies = { ...this.parent.companies };
    const company = this.parent.companies[id];

    if (company) {
      Object.values(company).forEach((item) => item.removeChildToMap());

      companies[id] = undefined;

      delete companies[id];

      this.parent.setCompanies(companies);
    } else {
      console.log('group', 'removeCompany', '找不到廠商');
    }
  };

  // 刪除所有廠商
  removeAllCompany = () => {
    this.parent.get.flatCompanies.texts.forEach((item) =>
      item.removeChildToMap());

    this.parent.setCompanies({});
  };

  // 刪除攤位編號
  removeBoothNumber = () => {
    if (this.parent.boothNumber) {
      this.parent.boothNumber.removeChildToMap();

      this.parent.setBoothNumber(null);
    } else {
      console.log('group', 'removeBoothNumber', '沒有找到攤位編號');
    }
  };

  removeMarkIcon = () => {
    this.parent.markIcon?.removeChildToMap();
    this.parent.setMarkIcon(null);
  };

  removeMarkText = () => {
    this.parent.get.flatMarkText.values.forEach((item) =>
      item.removeChildToMap());
    this.parent.setMarkText({});
  };

  removeMark = () => {
    this.parent.get.flatMarkText.values.forEach((item) =>
      item.removeChildToMap());
    this.parent.markIcon?.removeChildToMap();

    this.parent.setMarkText({});
    this.parent.setMarkIcon(null);
  };

  removeAreaName = () => {
    const areaNames = this.parent.get.flatAreaName.values;

    if (areaNames.length) {
      areaNames.forEach((item) => item.removeChildToMap());

      this.parent.setAreaName({});

      this.parent.get.flatCompanies.texts.forEach((item) => item.show());
    } else {
      console.log('group', 'removeAreaName', '沒有找到專區名字');
    }
  };
}

export default Delete;
