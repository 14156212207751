import * as PIXI from 'pixi.js';
import { OBJECT_ZINDEXS } from '../../global/constants';

class Grid {
  path = new PIXI.Graphics();
  context = null;

  constructor(props) {
    this.path.visible = false;
    this.context = props;
  }

  show = () => {
    this.path.visible = true;
  };

  hide = () => {
    this.path.visible = false;
  };

  init = () => {
    this.path.visible = true;
    this.path.zIndex = OBJECT_ZINDEXS.grid;

    this.context.map.addChild(this.path);
  };

  update = () => {
    const maxX = this.context.store.col * this.context.store.gridSize;
    const maxY = this.context.store.row * this.context.store.gridSize;

    this.path.clear();

    this.path.lineStyle(1, '#B3B3B3', 1);

    for (let i = 0; i <= this.context.store.row; i += 1) {
      this.path.moveTo(0, i * this.context.store.gridSize);
      this.path.lineTo(maxX, i * this.context.store.gridSize);
    }

    for (let i = 0; i <= this.context.store.col; i += 1) {
      this.path.moveTo(i * this.context.store.gridSize, 0);
      this.path.lineTo(i * this.context.store.gridSize, maxY);
    }

    this.path.closePath();
    this.path.endFill();
  };
}

export default Grid;
