import FloorPlan from 'src/components/FloorPlan';
import statusStore from 'src/stores/status';

class Sets {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  setTagPool = (val) => {
    this.parent.setTagPool(val);
  };

  // 地圖語系
  setFloorPlanLanguage = (val) => {
    const lang = val ? FloorPlan.LANGUAGES.zh : FloorPlan.LANGUAGES.en;

    this.parent.floorPlan.setLanguage(lang);

    this.parent.clearSwitch('create');
  };

  // 新增攤位的顏色
  setCreateBoothColor = (val) => {
    this.parent.clearSwitch('stamp');
    this.parent.clearSwitch('booth');
    this.parent.clearSwitch('other');

    this.parent.floorPlan.setRectColor(val);

    this.parent.setOperateTypeCreateBooth();
  };

  // 想要蓋章的參展商
  setStampCompany = (data) => {
    if (this.parent.company && this.parent.company === data) {
      this.parent.setCompany(null);
      this.parent.floorPlan.clearStamp();
    } else {
      this.parent.setCompany(data);
      this.parent.floorPlan.setStamp();
    }
  };

  // 蓋章, booth drawer 使用
  setFloorPlanCompany = (groupId, data) => {
    this.parent.floorPlan.setCompany(groupId, data);

    this.parent.clearSwitch('create');
  };

  // 地圖移動到已蓋章的參展商
  setPosition = (data) => {
    const groups = this.parent.floorPlan.searchGroupByAnything([data.id]);
    const group = groups[0];

    if (group) {
      this.parent.floorPlan.moveToGroup(group.id);
    }

    console.log('setPosition', group);

    this.parent.clearSwitch('create');
  };

  // 將 BoothTagModal 的標籤池更新 BoothDrawer
  setBoothDrawerTags = (val) => this.parent.boothDrawerVM.setTags(val);

  setFloorPlanBoothsColor = (groups, color) => {
    this.parent.floorPlan.setBoothsColor(groups, color);
  };

  setFloorPlanTextsFontSize = (texts, fontSize) => {
    this.parent.floorPlan.setTextsFontSize(texts, fontSize);
  };

  // 蓋章, 其他 drawer 使用
  setFloorPlanBoothNumber = (groupId, data) => {
    this.parent.floorPlan.setBoothNumber(groupId, data);
  };

  setFloorPlanAreaName = (groupId, data) => {
    this.parent.floorPlan.setAreaName(groupId, data);
  };

  // 更新地圖的背景圖
  setFloorPlanBackground = async ({ background, callback }) => {
    await this.parent.updateFloorPlanAPI({ background, callback });
  };

  setFloorPlanMarkIcon = async (groupId, data) => {
    await this.parent.floorPlan.setMarkIcon(groupId, data);
  };

  setFloorPlanMarkText = (groupId, data) => {
    this.parent.floorPlan.setMarkText(groupId, data);
  };

  setFloorPlanBoothDetail = async ({ groupId, detail, history, type }) => {
    await this.parent.floorPlan.setBoothDetail({
      groupId,
      detail,
      history,
      type
    });
  };

  setLoading = () => {
    statusStore.setLoading();
  };

  setCompleted = () => {
    statusStore.setCompleted();
  };
}

export default Sets;
