import React from 'react';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import {
  Table,
  Checkbox,
  Switch,
  Tag,
  Button,
  Divider,
  Input,
  Select
} from 'antd';
import { Waypoint } from 'react-waypoint';
import { ROUTES } from 'src/constants';
import withRouter from 'src/components/withRouter';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class FloorPlanTable extends React.Component {
  router = null;

  constructor(props) {
    super(props);
    this.router = props.router;
  }

  onNavigateDrawFloorPlanPage = (id) => {
    const route = `${ROUTES.Map}/${id}`;
    this.router.navigate(route);
  };

  render() {
    return (
      <div className="floorPlanTable">
        <Table
          dataSource={this.props.data}
          pagination={false}
          rowKey={(item) => item.id}
        >
          <Table.Column
            width={90}
            title={i18n.t('map_page_table_choose_title')}
            render={(vm) => (
              <Observer>
                {() => {
                  const disabled = this.props.disabled || vm.disabled.edit;

                  return (
                    <Checkbox
                      checked={vm.chose}
                      onChange={vm.onChoseChange}
                      disabled={disabled}
                    />
                  );
                }}
              </Observer>
            )}
          />

          <Table.Column
            width={90}
            title={i18n.t('map_page_table_shelve_title')}
            render={(vm) => (
              <Observer>
                {() => {
                  const disabled = this.props.disabled || vm.disabled.edit;

                  return (
                    <Switch
                      checked={vm.active}
                      onChange={vm.onActiveChange}
                      disabled={disabled}
                    />
                  );
                }}
              </Observer>
            )}
          />

          <Table.Column
            width={220}
            title={i18n.t('map_page_table_zh_name_title')}
            render={(vm) => (
              <Observer>
                {() => {
                  const checkEditId = vm.id === this.props.editId;
                  const disabled = this.props.disabled || vm.disabled.edit;

                  return checkEditId ? (
                    <Input
                      size="small"
                      className={styles.input}
                      value={vm.zhName}
                      onChange={vm.onZhNameChange}
                      disabled={disabled}
                    />
                  ) : (
                    <div className={styles.zhNameContainer}>{vm.zhName}</div>
                  );
                }}
              </Observer>
            )}
          />

          <Table.Column
            width={200}
            title={i18n.t('map_page_table_en_name_title')}
            render={(vm) => (
              <Observer>
                {() => {
                  const checkEditId = vm.id === this.props.editId;

                  return checkEditId ? (
                    <Input
                      size="small"
                      className={styles.input}
                      value={vm.enName}
                      onChange={vm.onEnNameChange}
                      disabled={vm.disabled.edit}
                    />
                  ) : (
                    <div className={styles.zhNameContainer}>{vm.enName}</div>
                  );
                }}
              </Observer>
            )}
          />

          <Table.Column
            width={400}
            title={i18n.t('map_page_table_exhibition_title')}
            render={(vm) => (
              <div className={styles.tags}>
                {vm.exhibitions.map((item) => {
                  return (
                    <Tag className={styles.tag} color="red" key={item.id}>
                      {item.name}
                    </Tag>
                  );
                })}
              </div>
            )}
          />

          <Table.Column
            width={400}
            title={i18n.t('map_page_table_search_tag_title')}
            render={(vm) => (
              <div className={styles.tags}>
                <Observer>
                  {() => {
                    const checkEditId = vm.id === this.props.editId;

                    return checkEditId ? (
                      <Select
                        mode="multiple"
                        size="small"
                        labelInValue
                        className={styles.select}
                        options={vm.mapSearchTags}
                        value={vm.tags}
                        onChange={vm.onChangeSearchTags}
                        disabled={vm.disabled.edit}
                      />
                    ) : (
                      vm.tags.map((item) => {
                        return (
                          <Tag
                            color="red"
                            className={styles.tag}
                            key={item.value}
                          >
                            {item.label}
                          </Tag>
                        );
                      })
                    );
                  }}
                </Observer>
              </div>
            )}
          />

          <Table.Column
            width={90}
            title={i18n.t('map_page_table_operate_title')}
            render={(vm, _, i) => (
              <Observer>
                {() => {
                  const checkEditId = this.props.editId === vm.id;
                  const disabled = this.props.disabled || vm.disabled.edit;
                  // 倒數第二個
                  const checkIndex = i === this.props.data.length - 2;

                  return (
                    <div className={styles.operateContainer}>
                      {checkIndex && (
                        <Waypoint onEnter={this.props.onScrollEnd} />
                      )}

                      {!checkEditId && (
                        <div className={styles.buttonContainer}>
                          <Button
                            type="link"
                            className={styles.button}
                            onClick={() => this.props.setEditId(vm.id)}
                            disabled={disabled}
                          >
                            {i18n.t('map_page_table_operate_edit_button_label')}
                          </Button>
                        </div>
                      )}

                      {checkEditId && (
                        <div className={styles.buttonContainer}>
                          <Button
                            type="link"
                            className={styles.button}
                            onClick={() => vm.onSave(this.props.setEditId)}
                            disabled={vm.disabled.edit}
                          >
                            {i18n.t('map_page_table_operate_save_button_label')}
                          </Button>
                        </div>
                      )}

                      <Divider type="vertical" className={styles.divider} />

                      <div className={styles.buttonContainer}>
                        <Button
                          type="link"
                          className={styles.button}
                          onClick={() =>
                            this.onNavigateDrawFloorPlanPage(vm.id)}
                        >
                          {i18n.t('map_page_table_operate_draw_button_label')}
                        </Button>
                      </div>

                      <Divider type="vertical" className={styles.divider} />

                      <div className={styles.buttonContainer}>
                        <Button
                          type="link"
                          className={styles.button}
                          onClick={() => this.props.onRemoveFloorPlan(vm.id)}
                          disabled={disabled}
                        >
                          {i18n.t('map_page_table_operate_remove_button_label')}
                        </Button>
                      </div>
                    </div>
                  );
                }}
              </Observer>
            )}
          />
        </Table>
      </div>
    );
  }
}

FloorPlanTable.propTypes = {
  data: PropTypes.array.isRequired,
  editId: PropTypes.string,
  setEditId: PropTypes.func.isRequired,
  onRemoveFloorPlan: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  router: PropTypes.object.isRequired,
  onScrollEnd: PropTypes.func.isRequired
};

FloorPlanTable.defaultProps = {
  editId: null
};

export default withRouter(FloorPlanTable);
