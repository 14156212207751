import { svgMethods } from '../global/methods';
import { BACKGROUND_TYPES, COLORS } from '../global/constants';

class Export {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  exportData = () => {
    const groups = {};
    const pillars = this.parent.context.store.data.pillars.map(
      (item) => item.res
    );
    const version = this.parent.context.store.data.version;
    const res = { groups, pillars, version };

    Object.values(this.parent.context.store.data.groups).forEach((item) => {
      const groupRes = item.get.res;
      groups[groupRes.id] = groupRes;
    });

    console.log('匯出的地圖', res);

    return encodeURIComponent(JSON.stringify(res));
  };

  downloadJPG = async ({ name = '平面圖', language }) => {
    const oldLan = this.parent.context.store.language;

    // 隱藏網格, Menu, 刪除選取
    this.parent.context.hideGrid();
    this.parent.context.closeMenu();
    this.parent.context.clearSelects();

    // 切換語言
    this.parent.context.sets.setLanguage(language);

    // 移動到正確的大小
    await this.parent.context.moveLeftTop(0);

    const extract = this.parent.context.app.renderer.plugins.extract;
    const img = await extract.image(
      this.parent.context.viewport,
      'image/jpeg',
      1
    );

    // 顯示隔線
    this.parent.context.showGrid();

    // 將語言切換回來
    this.parent.context.sets.setLanguage(oldLan);

    // 移動到中間
    await this.parent.context.moveToCenter(0);

    const link = document.createElement('a');

    link.href = img.src;
    link.download = `${name}.jpeg`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };

  exportSVG = async ({ name, language }) => {
    const oldLan = this.parent.context.store.language;

    // 切換語言
    this.parent.context.sets.setLanguage(language);

    const groups = Object.values(this.parent.context.store.data.groups);
    const resGroups = groups.map((item) => item.get.resForSVG);

    // 將語言切換回來
    this.parent.context.sets.setLanguage(oldLan);

    // 地圖
    const mainSVG = svgMethods.getSVG({
      width: String(
        this.parent.context.store.col * this.parent.context.store.gridSize
      ),
      height: String(
        this.parent.context.store.row * this.parent.context.store.gridSize
      )
    });

    // 背景
    const bgcRes = this.parent.context.background.res;
    let bgc = null;

    switch (bgcRes.type) {
      case BACKGROUND_TYPES.svg: {
        bgc = await svgMethods.getImageXml(bgcRes.src);
        break;
      }

      default: {
        const element = window.document.createElement('a');

        element.href = bgcRes.src;
        element.download = `${name}-背景圖.${bgcRes.type}`;
        element.click();

        bgc = svgMethods.getImage(bgcRes.src);
        break;
      }
    }

    mainSVG.appendChild(bgc);

    // 畫方塊
    resGroups.forEach((group, i) => {
      const groupSVG = svgMethods.getSVG({
        x: String(group.x),
        y: String(group.y)
      });

      group.rects.forEach((rect) => {
        const rectSVG = svgMethods.getRect(rect);
        groupSVG.appendChild(rectSVG);
      });

      const border = svgMethods.getPath(group.border);

      border.setAttribute('stroke', COLORS.normal.border);
      border.setAttribute('stroke-width', '1');
      border.setAttribute('fill', 'none');

      groupSVG.appendChild(border);

      mainSVG.appendChild(groupSVG);
    });

    // 柱子
    if (this.parent.checkPillarsVisible) {
      const resPillars = this.parent.context.store.data.pillars.map(
        (item) => item.resForSVG
      );
      await Promise.all(
        resPillars.map(async (item) => {
          const svg = await svgMethods.getImageXml(item.src);

          svg.setAttribute('x', String(item.x));
          svg.setAttribute('y', String(item.y));

          mainSVG.appendChild(svg);
        })
      );
    }

    // 地標
    await Promise.all(
      resGroups.map(async (group) => {
        if (group.markIcon) {
          const svg = await svgMethods.getImageXml(group.markIcon.src);

          svg.setAttribute('x', String(group.markIcon.x));
          svg.setAttribute('y', String(group.markIcon.y));

          mainSVG.appendChild(svg);
        }

        if (group.markText) {
          const svg = svgMethods.getText(group.markText);
          mainSVG.appendChild(svg);
        }
      })
    );

    resGroups.forEach((group) => {
      // 廠商
      group.companies.forEach((text) => {
        if (text.visible) {
          const svg = svgMethods.getText(text);
          mainSVG.appendChild(svg);
        }
      });

      // 攤位編號
      if (group.boothNumber) {
        const svg = svgMethods.getText(group.boothNumber);
        mainSVG.appendChild(svg);
      }

      // 專區名字
      if (group.areaName && group.areaName?.visible) {
        const svg = svgMethods.getText(group.areaName);
        mainSVG.appendChild(svg);
      }
    });

    return mainSVG;
  };

  downloadSVG = async ({ name = '平面圖', language }) => {
    const svg = await this.exportSVG({ name, language });
    const serializer = new XMLSerializer();
    const source = `<?xml version="1.0" standalone="no"?>\r\n${serializer.serializeToString(
      svg
    )}`;
    const dataUri = `data:image/svg+xml;charset=utf-8,${window.encodeURIComponent(
      source
    )}`;
    const link = document.createElement('a');

    link.href = dataUri;
    link.download = `${name}.svg`;

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
  };
}

export default Export;
