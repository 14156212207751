import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Drawer, Button, Input, Select, DatePicker, Radio } from 'antd';

import { EReceiverType, RECEIVER_TYPE_TC, ELanguageType } from 'src/constants';

import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class AnnouncementEditDrawer extends React.Component {
  render() {
    const { viewModel } = this.props;
    return (
      <Drawer
        closable={false}
        open={viewModel.isOpen}
        onClose={viewModel.onCancel}
        width={700}
        className={styles.drawerContainer}
        title={(
          <div className={styles.drawerTitle}>
            {i18n.t('ann_drawer_title')}
          </div>
        )}
        bodyStyle={{
          display: 'grid',
          gridTemplateRows: '1fr auto'
        }}
      >
        <div className={styles.formContainer}>
          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              {i18n.t('ann_drawer_name_title')}
              {
                !viewModel.isSent
                && (
                  <span className={styles.red}>
                    *
                  </span>
                )
              }
              ：
            </div>
            {
              viewModel.isSent
                ? (
                  <div className={styles.isSentContent}>
                    {viewModel.name}
                  </div>
                )
                : (
                  <Input
                    value={viewModel.name}
                    maxLength={50}
                    onChange={viewModel.onNameChange}
                    placeholder={i18n.t('ann_drawer_name_placeholder')}
                  />
                )
            }
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              {i18n.t('ann_drawer_receiver_title')}
              {
                !viewModel.isSent
                && (
                  <span className={styles.red}>
                    *
                  </span>
                )
              }
              ：
            </div>
            {
              viewModel.isSent
                ? (
                  <div className={styles.isSentContent}>
                    {RECEIVER_TYPE_TC[viewModel.receiverType]}
                  </div>
                )
                : (
                  <Select
                    options={EReceiverType}
                    className={styles.select}
                    value={viewModel.receiverType}
                    onChange={viewModel.onReceiverSelect}
                    placeholder={i18n.t('ann_drawer_receiver_placeholder')}
                  />
                )
            }

          </div>

          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              {i18n.t('ann_drawer_language_title')}
              {
                !viewModel.isSent
                && (
                  <span className={styles.red}>
                    *
                  </span>
                )
              }
              ：
            </div>
            <Radio.Group
              className={styles.radio}
              value={viewModel.language}
              onChange={viewModel.onLanguageSelect}
              disabled={viewModel.isSent}
            >
              {
                ELanguageType.map((el) => (
                  <Radio key={el.value} value={el.value}>
                    {el.label}
                  </Radio>
                ))
              }
            </Radio.Group>
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              {i18n.t('ann_drawer_time_title')}
              {
                !viewModel.isSent
                && (
                  <span className={styles.red}>
                    *
                  </span>
                )
              }
              ：
            </div>
            {
              viewModel.isSent
                ? (
                  <div className={styles.isSentContent}>
                    {viewModel.sendTime.format('YYYY-MM-DD HH:mm')}
                  </div>
                )
                : (
                  <DatePicker
                    value={viewModel.sendTime}
                    onOk={viewModel.onTimeSelect}
                    className={styles.picker}
                    placeholder={i18n.t('ann_drawer_time_placeholder')}
                    format="YYYY-MM-DD HH:mm"
                    showNow={false}
                    // TODO: comment for test use
                    disabledDate={viewModel.disabledDate}
                    // TODO: comment for test use
                    disabledTime={viewModel.disabledDateTime}
                    showTime={{
                      format: 'HH:mm',
                      // TODO: comment for test use
                      minuteStep: 5
                    }}
                  />
                )
            }
          </div>

          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              {i18n.t('ann_drawer_content_title')}
              {
                !viewModel.isSent
                && (
                  <span className={styles.red}>
                    *
                  </span>
                )
              }
              ：
            </div>
            {
              viewModel.isSent
                ? (
                  <div className={clsx(styles.isSentContent, styles.textArea)}>
                    {viewModel.content}
                  </div>

                )
                : (
                  <Input.TextArea
                    value={viewModel.content}
                    onChange={viewModel.onContentChange}
                    maxLength={1000}
                    showCount
                    placeholder={i18n.t('ann_drawer_content_placeholder')}
                    autoSize={{
                      minRows: 6
                    }}
                  />
                )
            }

          </div>
        </div>

        <div className={styles.buttonContainer}>
          <Button
            className={clsx(styles.btn, styles.cancelBtn)}
            onClick={viewModel.onCancel}
          >
            取消
          </Button>
          {
            viewModel.isSent
              ? (
                <Button
                  className={clsx(styles.btn, styles.confirmBtn)}
                  type="primary"
                  onClick={viewModel.onCancel}
                  disabled={!viewModel.canSubmit}
                >
                  完成
                </Button>
              )
              : (
                <Button
                  className={clsx(styles.btn, styles.confirmBtn)}
                  type="primary"
                  onClick={viewModel.onSubmit}
                  disabled={!viewModel.canSubmit}
                >
                  儲存
                </Button>
              )
          }

        </div>
      </Drawer>
    );
  }
}

AnnouncementEditDrawer.propTypes = {
  viewModel: PropTypes.object
};

AnnouncementEditDrawer.defaultProps = {
  viewModel: {}
};

export default AnnouncementEditDrawer;
