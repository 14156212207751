import { makeObservable, observable, computed, action } from 'mobx';
import { diff } from 'deep-diff';
import CheckboxTagViewModel from '../CheckboxTag/viewModel';

class CompanyViewModel {
  id = null;
  exhibitionId = null;

  @observable tags = [];
  @observable tagViewModels = [];

  @observable boothNumber = null;

  @observable name = null;
  @observable zhName = null;
  @observable enName = null;

  @observable zhNameFontSize = null;
  @observable enNameFontSize = null;

  mapId = '';

  @computed
  get mapTagViewModels() {
    return this.tagViewModels
      .filter((item) => item.checked)
      .map((item) => item.id);
  }

  @computed
  get res() {
    return {
      id: this.id,
      exhibitionId: this.exhibitionId,
      zhName: this.zhName.trim() || '',
      enName: this.enName.trim() || '',
      zhNameFontSize: Number(this.zhNameFontSize || '14'),
      enNameFontSize: Number(this.enNameFontSize || '14'),
      boothNumber: this.boothNumber.trim() || '',
      mapId: this.mapId,
      tags: this.mapTagViewModels
    };
  }

  constructor(props) {
    this.id = props.id;
    this.exhibitionId = props.exhibitionId;

    this.boothNumber = props.boothNumber || '';

    this.name = props.name || '';
    this.zhName = props.zhName || '';
    this.enName = props.enName || '';

    this.zhNameFontSize = props.zhNameFontSize || '14';
    this.enNameFontSize = props.enNameFontSize || '14';

    this.mapId = props.mapId;

    this.tags = props.tags;

    makeObservable(this);
  }

  @action init = (props) => {
    this.tagViewModels = props.tagPool.map((item) => {
      const vm = new CheckboxTagViewModel({ companyId: this.id, data: item });
      const checked = !!this.tags.find((tag) => tag.id === item.id);

      vm.setChecked(checked);

      return vm;
    });
  };

  @action onBoothNumberChange = (event) => {
    this.boothNumber = event.target.value;
  };

  @action onZhNameChange = (event) => {
    this.zhName = event.target.value;
  };

  @action onEnNameChange = (event) => {
    this.enName = event.target.value;
  };

  @action onZhNameFontSizeChange = (val) => {
    this.zhNameFontSize = val;
  };

  @action onEnNameFontSizeChange = (val) => {
    this.enNameFontSize = val;
  };

  // 平面圖的資料使用
  @action setZhNameFontSize = (val) => {
    this.zhNameFontSize = String(val);
  };

  @action setEnNameFontSize = (val) => {
    this.enNameFontSize = String(val);
  };

  checkTag = (id) => {
    return !!this.tags[id];
  };
}

export default CompanyViewModel;
