import SelectPath from '../components/SelectPath';
import { EVENT_CODES, OBJECT_TYPES, ERRORS } from '../global/constants';

class Select {
  path = null;

  isPause = false;
  isKeydown = false;
  isCtrl = false;
  isShift = false;

  clientDown = null;
  clientMove = null;

  rect = null;

  context = null;

  get bounding() {
    if (
      this.clientDown
      && this.clientMove
      && this.clientDown === this.clientMove
    ) {
      return {
        minX: this.clientDown.x,
        maxX: this.clientDown.x,
        minY: this.clientDown.y,
        maxY: this.clientDown.y
      };
    }

    if (
      this.clientDown
      && this.clientMove
      && this.clientDown !== this.clientMove
    ) {
      return this.path.bounding;
    }

    return {
      minX: 0,
      minY: 0,
      maxX: 0,
      maxY: 0
    };
  }

  constructor(context) {
    this.path = new SelectPath(context);
    this.context = context;
  }

  init = (type) => {
    this.context.viewport.on('mousedown', this.onViewportMousedown);

    window.addEventListener('keydown', this.onWindowKeydown);
    window.addEventListener('keyup', this.onWindowKeyup);
  };

  destroy = () => {
    window.removeEventListener('keydown', this.onWindowKeydown);
    window.removeEventListener('keyup', this.onWindowKeyup);
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  clearSelectPath = () => {
    this.path.clear();
  };

  clearSelects = () => {
    this.context.store.selects.forEach((item) =>
      item.group ? item.group.inactive() : item.inactive());

    this.context.store.updateSelects([]);
  };

  // 點擊
  // eslint-disable-next-line consistent-return
  onChildMouseup = (event, target) => {
    if (this.isPause) {
      return false;
    }

    const isRect = target.type === OBJECT_TYPES.rect;

    // 多選 + 點方塊
    if (this.isCtrl && isRect) {
      // 你的攤位已經被選取
      if (target.group.isActive) {
        const filterSelects = this.context.store.selects.filter(
          (item) => item !== target.group
        );

        target.group.inactive();

        this.context.store.updateSelects(filterSelects);

        console.log('Select', 'onChildMouseup', '1');
      } else {
        const filterSelects = this.context.store.selects.filter((item) => {
          if (item.group) {
            return item.group !== target.group;
          }

          return item !== target.group;
        });

        filterSelects.push(target.group);

        target.group.active();

        this.context.store.updateSelects(filterSelects);

        console.log('Select', 'onChildMouseup', '2');
      }
    } else if (
      // 多選 + 點文字或 icon
      this.isCtrl
      && !isRect
    ) {
      // 你的攤位已經被選取
      if (target.group.isActive) {
        const filterSelects = this.context.store.selects.filter((item) => {
          if (item.group) {
            return item.group !== target.group;
          }

          return item !== target.group;
        });

        filterSelects.push(target);

        target.group.inactive();
        target.active();

        this.context.store.updateSelects(filterSelects);

        console.log('Select', 'onChildMouseup', '3');
      } else {
        const sliceSelects = this.context.store.selects.slice();

        sliceSelects.push(target);

        target.active();

        this.context.store.updateSelects(sliceSelects);

        console.log('Select', 'onChildMouseup', '4');
      }
    } else if (
      // 點方塊
      isRect
    ) {
      this.clearSelects();

      target.group.active();

      this.context.store.updateSelects([target.group]);

      console.log('Select', 'onChildMouseup', '5');
    } else {
      this.clearSelects();

      target.active();

      this.context.store.updateSelects([target]);

      console.log('Select', 'onChildMouseup', '6');
    }

    this.clearSelectPath();

    // 外部使用
    this.context.events.onSelected();
  };

  // eslint-disable-next-line consistent-return
  onViewportMousedown = (event) => {
    if (this.isPause || event.buttons !== EVENT_CODES.leftButton) {
      return false;
    }

    const localClient = this.context.getEventMapPosition(event);

    this.clientDown = localClient;
    this.clientMove = localClient;

    this.path.init(localClient, false);

    // 範圍多選
    if (this.isShift) {
      this.context.viewport.on('mousemove', this.onMousemove);
    }

    window.addEventListener('mouseup', this.onWindowMouseup);
  };

  // eslint-disable-next-line consistent-return
  onMousemove = (event) => {
    if (this.isPause) {
      return false;
    }

    const clientMove = this.context.getEventMapPosition(event);

    this.clientMove = clientMove;

    this.path.render(clientMove, true);
  };

  // eslint-disable-next-line consistent-return
  onWindowMouseup = () => {
    this.context.viewport.off('mousemove', this.onMousemove);
    window.removeEventListener('mouseup', this.onWindowMouseup);

    if (this.isPause) {
      return false;
    }

    // 新選到的 groups
    const newSelectGroups = this.context.store.flat.rects
      .filter((item) => item.checkOverlap(this.bounding))
      .map((item) => item.group);

    const checkSelects = newSelectGroups.length;

    // 拉範圍多選
    if (checkSelects) {
      const oldSelects = this.context.store.selects.map(
        (item) => item.group || item
      );
      const concatGroups = Array.from(
        new Set(oldSelects.concat(newSelectGroups))
      );

      concatGroups.forEach((item) => item.active());

      this.context.store.updateSelects(concatGroups);

      // 刪除選取匡
      this.clearSelectPath();
    } else {
      // 刪除選取匡
      this.clearSelectPath();
      this.clearSelects();

      this.context.events.onError(ERRORS['0x005']);
    }

    // 外部使用
    this.context.events.onSelected();
  };

  onWindowKeydown = (event) => {
    const isCtrl = event.keyCode === EVENT_CODES.ctrl;
    const isCommand = event.keyCode === EVENT_CODES.command;

    if ((isCtrl || isCommand) && !this.isCtrl && !this.isPause) {
      this.isCtrl = true;
      this.context.pause('dragObject');

      event.preventDefault();
    }

    if (event.keyCode === EVENT_CODES.shift && !this.isShift && !this.isPause) {
      this.isShift = true;
      this.context.pause('dragObject');
      this.context.pause('createGroup');

      event.preventDefault();
    }
  };

  onWindowKeyup = (event) => {
    const isCtrl = event.keyCode === EVENT_CODES.ctrl;
    const isCommand = event.keyCode === EVENT_CODES.command;

    if ((isCtrl || isCommand) && !this.isPause) {
      this.isCtrl = false;
      this.context.resume('dragObject');
    }

    if (event.keyCode === EVENT_CODES.shift && !this.isPause) {
      this.isShift = false;
      this.context.resume('dragObject');
      this.context.resume('createGroup');
    }
  };
}

export default Select;
