import * as PIXI from 'pixi.js';
import {
  COLORS,
  OBJECT_BORDER_WIDTH,
  OBJECT_ZINDEXS
} from '../../global/constants';

class SelectPath {
  view = null;
  startPosition = null;
  bounding = null;
  context = null;

  constructor(context) {
    const path = new PIXI.Graphics();

    path.width = context.store.col * context.store.gridSize;
    path.height = context.store.row * context.store.gridSize;

    path.x = 0;
    path.y = 0;

    path.zIndex = OBJECT_ZINDEXS.selectPath;

    context.map.addChild(path);

    this.view = path;
    this.context = context;
  }

  init = (position, draw = false) => {
    this.clear();

    const x = this.context.formatPointToSize(position.x);
    const y = this.context.formatPointToSize(position.y);
    const gridSize = this.context.store.gridSize;

    this.view.lineStyle(OBJECT_BORDER_WIDTH.active, COLORS.active.border);
    this.view.beginFill(COLORS.active.border, 0.4);

    if (draw) {
      this.view.drawRect(x, y, gridSize, gridSize);
    }

    this.startPosition = position;
    this.setBounding({ x, y, width: gridSize, height: gridSize });
  };

  clear = () => {
    this.view.clear();

    this.startPosition = null;
    this.bounding = null;
  };

  setBounding = ({ x, y, width, height }) => {
    this.bounding = {
      x,
      y,
      width,
      height,
      // RBush 使用
      minX: x,
      maxX: x + width,
      minY: y,
      maxY: y + height
    };
  };

  render = (position, draw = false) => {
    let x;
    let y;
    let width;
    let height;

    this.view.clear();

    this.view.lineStyle(OBJECT_BORDER_WIDTH.active, COLORS.active.border);
    this.view.beginFill(COLORS.active.border, 0.4);

    if (position.x > this.startPosition.x) {
      const downX = this.context.formatPointToSize(
        this.startPosition.x,
        'floor'
      );
      const moveX = this.context.formatPointToSize(position.x, 'ceil');
      const calcWidth = moveX - downX;

      x = downX;
      width
        = calcWidth < this.context.store.gridSize
          ? this.context.store.gridSize
          : calcWidth;
    } else {
      const startX = this.context.formatPointToSize(position.x, 'floor');
      const endX = this.context.formatPointToSize(this.startPosition.x, 'ceil');
      const calcWidth = Math.abs(startX - endX);

      x = startX;
      width
        = calcWidth < this.context.store.gridSize
          ? this.context.store.gridSize
          : calcWidth;
    }

    if (position.y >= this.startPosition.y) {
      const downY = this.context.formatPointToSize(
        this.startPosition.y,
        'floor'
      );
      const moveY = this.context.formatPointToSize(position.y, 'ceil');
      const calcHeight = moveY - downY;

      y = downY;
      height
        = calcHeight < this.context.store.gridSize
          ? this.context.store.gridSize
          : calcHeight;
    } else {
      const startY = this.context.formatPointToSize(position.y, 'floor');
      const endY = this.context.formatPointToSize(this.startPosition.y, 'ceil');
      const calcHeight = Math.abs(startY - endY);

      y = startY;
      height
        = calcHeight < this.context.store.gridSize
          ? this.context.store.gridSize
          : calcHeight;
    }

    if (draw) {
      this.view.drawRect(x, y, width, height);
    }

    this.setBounding({ x, y, width, height });
  };
}

export default SelectPath;
