import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { ChromePicker } from 'react-color';
import { Modal, Button } from 'antd';
import i18n from 'src/i18n';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class ColorPickerModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <Modal
        open={this.vm.modalVM.isOpen}
        width={225}
        centered
        wrapClassName="colorPickerModal"
        closable={false}
        title={null}
        footer={null}
      >
        <div className={styles.container}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {i18n.t('draw_floor_plan_page_color_picker_modal_title')}
            </div>
          </div>

          <ChromePicker
            className="colorPicker"
            color={this.vm.color}
            onChange={this.vm.onColorChange}
            disableAlpha
          />

          <div className={styles.footer}>
            <Button className={styles.cancelButton} onClick={this.vm.close}>
              {i18n.t(
                'draw_floor_plan_page_color_picker_modal_cancel_button_label'
              )}
            </Button>

            <Button type="primary" onClick={this.vm.onConfirm}>
              {i18n.t(
                'draw_floor_plan_page_color_picker_modal_confirm_button_label'
              )}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

ColorPickerModal.propTypes = {
  vm: PropTypes.object.isRequired
};

ColorPickerModal.defaultProps = {};

export default ColorPickerModal;
