import Pushes from './modules/Pushes';
import Backs from './modules/Backs';

// 上一步

class History {
  histories = [];

  isPause = false;

  pushes = new Pushes(this);
  backs = new Backs(this);

  context = null;

  get historiesLength() {
    return this.histories.length;
  }

  get validKeydown() {
    if (this.isCtrl && this.isZ) {
      return true;
    }

    return false;
  }

  get lastHistory() {
    return this.histories[this.histories.length - 1];
  }

  constructor(props) {
    this.context = props;
  }

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  push = (val) => {
    this.histories.push(val);
  };

  pop = () => {
    return this.histories.pop();
  };
}

export default History;
