class Deletes {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  clearRectColor = () => {
    this.parent.store.setRectColor('');
  };

  removeCompany = (groupId, companyId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeCompany(companyId);
    } else {
      console.log('removeCompany', '沒有找到 group');
    }
  };

  removeBoothNumber = (groupId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeBoothNumber(groupId);
    } else {
      console.log('removeBoothNumber', '沒有找到 group');
    }
  };

  removeMarkIcon = (groupId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeMarkIcon();
    } else {
      console.log('removeMarkIcon', '沒有找到 group');
    }
  };

  removeMarkText = (groupId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeMarkText();
    } else {
      console.log('removeMarkText', '沒有找到 group');
    }
  };

  removeMark = (groupId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeMark();
    } else {
      console.log('removeMark', '沒有找到 group');
    }
  };

  removeAreaName = (groupId) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.delete.removeAreaName();
    } else {
      console.log('removeAreaName', '沒有找到 group');
    }
  };
}

export default Deletes;
