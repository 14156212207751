class Removes {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  removeFloorPlanBoothNumber = (groupId) => {
    this.parent.floorPlan.removeBoothNumber(groupId);
  };

  removeFloorPlanAreaName = (groupId) => {
    this.parent.floorPlan.removeAreaName(groupId);
  };

  removeFloorPlanMarkIcon = (groupId) => {
    this.parent.floorPlan.removeMarkIcon(groupId);
  };

  removeFloorPlanMarkText = (groupId) => {
    this.parent.floorPlan.removeMarkText(groupId);
  };

  removeFloorPlanMark = (groupId) => {
    this.parent.floorPlan.removeMark(groupId);
  };
}

export default Removes;
