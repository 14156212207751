import { makeObservable, observable, action, computed } from 'mobx';
import { GROUP_TYPE, ROUTES } from 'src/constants';
import i18n from 'src/i18n';
import { message } from 'antd';

import UserService from 'src/services/user';

export default class LoginPageViewModel {
  @observable email = '';
  @observable password = '';

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = async () => {

  };

  @action onSubmit = async (value, router) => {
    const { username, password } = value;

    try {
      const { user } = await UserService.login({ account: username, password });

      if (user.group === GROUP_TYPE.admin) {
        router.navigate(`${ROUTES.Announcement}?status=soon`);
      } else {
        router.navigate(ROUTES.Map);
      }

    } catch (error) {
      if (error.response?.status === 404) {
        message.error(i18n.t('login_error_status_404'));
      } else {
        message.error(i18n.t('login_error_status_general'));
      }
    }
  };

  @action onSubmitFailed = () => {
    message.error(i18n.t('login_error_data_error'));
  };
}
