import { message } from 'antd';
import i18n from 'src/i18n';
import { FLOOR_PLAN_DEFAULT_FONT_SIZES } from 'src/constants';
import statusStore from 'src/stores/status';
import FloorPlan from 'src/components/FloorPlan';
import FloorPlanService from 'src/services/floorPlanService';

// 地圖事件
class Events {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  onContainerMouseenter = () => {
    this.parent.setMouseenter(true);
  };

  onContainerMouseleave = () => {
    this.parent.setMouseenter(false);
  };

  onContainerMousemove = (event) => {
    if (this.parent.showFollowCompanyName) {
      this.parent.setFollowCompanyLeft(event.clientX);
      this.parent.setFollowCompanyTop(event.clientY);
    }
  };

  onCreated = () => {
    this.parent.clearSwitch('booth');
    this.parent.clearSwitch('other');

    this.parent.updateFloorPlanTimeout();
  };

  // 地圖選取攤位結束後
  onSelected = (groups) => {
    const length = groups.length;
    const group = groups[0];
    const companies = group?.companies || {};
    const checkCompanies = !!Object.keys(companies).length;

    if (length > 1) {
      this.parent.clearSwitch('other');
      this.parent.clearSwitch('booth');
    } else if (length === 1 && checkCompanies) {
      this.parent.clearSwitch('other');

      this.parent.boothDrawerVM.open(group);
    } else if (length === 1) {
      this.parent.clearSwitch('stamp');
      this.parent.clearSwitch('booth');

      this.parent.otherDrawerVM.open(group);
    } else {
      this.parent.clearSwitch('other');
      this.parent.clearSwitch('booth');
    }

    this.parent.setStamped(false);
  };

  // 刪除攤位和刪除參展商
  onBoothCompaniesRemoved = async (ids) => {
    if (ids.length) {
      const companies = await this.parent.getCompaniesDetailAPI(ids);
      const list = companies.map((item) => {
        // 如果有參展商的定位在這張地圖, 需要清除定位
        const mapId = item.mapId === this.parent.id ? '' : item.mapId;

        return { ...item, mapId };
      });

      // 清除參展商的定位
      this.parent.updateCompaniesMapIdAPI(list);
    }

    this.parent.updateFloorPlanAPI();

    this.parent.clearSwitch('booth');
  };

  // 攤位合併
  // 更新攤位中的參展商的攤位編號
  // 更新地圖
  onMerged = async (props) => {
    this.parent.updateFloorPlanTimeout();
  };

  // 地圖蓋章結束後
  // 更新參展商定位的地圖
  // 更新地圖
  onStamped = async (group) => {
    // 地標
    const checkMarkIcon = !!group.markIcon;
    const checkMarkText = !!group.markText;

    if (checkMarkIcon || checkMarkText) {
      console.log('蓋章結束', '不能在有地標的攤位上蓋章');

      // 蓋章是否成功
      return false;
    }

    // 攤位號碼
    const groupBoothNumber = group.boothNumber;
    const companyBoothNumber = this.parent.company.boothNumber;
    const boothNumber = companyBoothNumber || groupBoothNumber;

    if (
      !!groupBoothNumber
      && !!companyBoothNumber
      && groupBoothNumber !== companyBoothNumber
    ) {
      // 攤位和參展商攤位號碼不同
      message.warning(
        i18n.t(
          'draw_floor_plan_page_error_on_stamped_booth_number_incompatible'
        ),
        5
      );

      // 蓋章是否成功
      return false;
    }

    statusStore.setLoading();

    // 給地圖的攤位使用
    const zhData = {
      id: this.parent.company.id,
      content: this.parent.company.zhName,
      language: FloorPlan.LANGUAGES.zh,
      fontSize: Number(FLOOR_PLAN_DEFAULT_FONT_SIZES.company.zh)
    };
    const enData = {
      id: this.parent.company.id,
      content: this.parent.company.enName,
      language: FloorPlan.LANGUAGES.en,
      fontSize: Number(FLOOR_PLAN_DEFAULT_FONT_SIZES.company.en)
    };
    const detail = {
      companies: {
        [this.parent.company.id]: {
          [FloorPlan.LANGUAGES.zh]: zhData,
          [FloorPlan.LANGUAGES.en]: enData
        }
      },
      boothNumber: {
        content: companyBoothNumber,
        fontSize: Number(FLOOR_PLAN_DEFAULT_FONT_SIZES.boothNumber)
      }
    };

    console.log('蓋章', detail);

    // 檢查參展商的地圖是否有上架
    let mapId = null;

    if (this.parent.company.mapId) {
      const res = await this.getMapActiveAPI(this.parent.company.mapId);

      if (res === null) {
        mapId = this.parent.company.mapId;
        console.log('檢查地圖上架失敗, 不更新參展商的 mapId');
      } else {
        mapId = res ? this.parent.company.mapId : this.parent.id;
      }
    } else {
      mapId = this.parent.id;
    }

    // 更新參展商的 api 使用
    const company = {
      id: this.parent.company.id,
      mapId,
      exhibitionId: this.parent.company.exhibitionId,
      name: this.parent.company.name,
      zhName: this.parent.company.zhName,
      enName: this.parent.company.enName,
      boothNumber: boothNumber || ''
    };

    if (
      !!groupBoothNumber
      && !!companyBoothNumber
      && groupBoothNumber === companyBoothNumber
    ) {
      // 攤位和參展商攤位號碼相同
      this.parent.sets.setFloorPlanBoothDetail({
        groupId: group.id,
        detail,
        history: true,
        type: FloorPlan.HISTORIES.stamp
      });
    } else if (!!groupBoothNumber && !companyBoothNumber) {
      // 攤位有攤位號碼
      // 參展商沒有攤位號碼
      this.parent.sets.setFloorPlanBoothDetail({
        groupId: group.id,
        detail,
        history: true,
        type: FloorPlan.HISTORIES.stamp
      });

      company.boothNumber = groupBoothNumber;

      console.log('蓋章結束', '參展商沒有攤位號碼');
    } else if (!groupBoothNumber && !!companyBoothNumber) {
      // 攤位沒有攤位號碼
      // 參展商有攤位號碼
      this.parent.sets.setFloorPlanBoothDetail({
        groupId: group.id,
        detail,
        history: true,
        type: FloorPlan.HISTORIES.stamp
      });

      console.log('蓋章結束', '攤位沒有攤位號碼');
    } else {
      // 攤位和參展商都沒有攤位號碼
      this.parent.sets.setFloorPlanBoothDetail({
        groupId: group.id,
        detail,
        history: true,
        type: FloorPlan.HISTORIES.stamp
      });

      console.log('蓋章結束', '都沒有攤位號碼', this.parent.company);
    }

    await Promise.all([
      this.parent.updateCompaniesMapIdAPI([company]),
      this.parent.updateFloorPlanAPI()
    ]);

    this.parent.floorPlan.clearStamp();
    this.parent.setCompany(null);
    this.parent.setStamped(true);

    statusStore.setCompleted();

    // 蓋章是否成功
    return true;
  };

  onMoved = () => {
    this.parent.updateFloorPlanTimeout();
  };

  onPasted = () => {
    this.parent.updateFloorPlanTimeout();
  };

  onSetGroupsColor = (groups) => {
    this.parent.colorPickerModalVM.open(groups);
  };

  onSetFontSize = (texts) => {
    this.parent.fontSizeModalVM.open(texts);

    // 讀取歷史紀錄數量
    this.parent.getHistoriesLength();
  };

  onBack = async () => {
    statusStore.setLoading();

    this.parent.clearSwitch('create');
    this.parent.clearSwitch('stamp');
    this.parent.clearSwitch('other');
    this.parent.clearSwitch('booth');

    this.parent.boothTagModalVM.closeNoAPI();
    this.parent.colorPickerModalVM.close();
    this.parent.fontSizeModalVM.close();
    this.parent.fontSizeModalVM.close();

    const history = this.parent.floorPlan.getLastHistory();
    // 紀錄參展商是否更新成功
    let updateCompaniesRes = { companies: [], state: true };

    console.log('上一步', history);

    switch (history.type) {
      // BoothDrawer 更新
      case FloorPlan.HISTORIES.companies:
      case FloorPlan.HISTORIES.removeCompanies:
      case FloorPlan.HISTORIES.removeBooths: {
        updateCompaniesRes = await this.onUpdateCompaniesBack(history.before);
        break;
      }

      case FloorPlan.HISTORIES.stamp: {
        updateCompaniesRes = await this.onUpdateCompaniesBack(
          history.after,
          true
        );
        break;
      }

      default:
        break;
    }

    // 參展商更新成功
    if (updateCompaniesRes.state) {
      // 上一步
      this.parent.floorPlan.back();

      /**
       * 上一步時, 因為需要先更新展昭的參展商判斷後續是否執行, 所以在 getCompaniesDetailAPI 判斷參展商是否在地圖上不準確(為了更新 CompanyDrawer 的參展商), 應該在地圖真的重畫後才判斷(floorPlan.back 之後)
       */
      if (updateCompaniesRes.companies.length) {
        this.parent.updateCompanyDrawerCompanies(updateCompaniesRes.companies);
      }

      // 讀取上一步數量
      this.parent.getHistoriesLength();

      await this.parent.updateFloorPlanAPI();
    } else {
      message.warning(i18n.t('draw_floor_plan_page_error_back_fail'), 5);
    }

    statusStore.setCompleted();
  };

  // 更新展昭資料庫的參展商, 地圖沒有重畫, 也沒有更新
  // 刪除參展商, 刪除攤位: 恢復 mapId
  // 蓋章: 刪除 mapId, 或是使用舊的 mapId
  onUpdateCompaniesBack = async (res, removeMapId = false) => {
    const ids = res.map((item) => item.id);

    if (!ids.length) {
      console.log('上一步不需要更新參展商');

      return {
        companies: [],
        state: true
      };
    }

    const companies = await this.parent.getCompaniesDetailAPI(ids);

    console.log('onStampBack', 'companies', companies);

    if (companies.length) {
      // 刪除參展商指定的 mapId
      const mapCompanies = companies
        .map((item) => {
          const findRes = res.find((r) => r.id === item.id);

          if (findRes) {
            let mapId = '';

            // 需要刪除參展商的 mapId, 但是指向這張地圖才刪除
            if (removeMapId) {
              mapId = item.mapId === this.parent.id ? '' : item.mapId;
            } else {
              // 使用舊的 mapId
              // 如果舊的 id 沒有, 使用當前地圖的 id
              mapId = item.mapId || this.parent.id;
            }

            return {
              ...item,
              mapId,
              zhName: findRes.zhName,
              enName: findRes.enName,
              boothNumber: findRes.boothNumber || ''
            };
          }

          return null;
        })
        .filter((item) => !!item);
      const updateRes = await this.parent.updateCompaniesMapIdAPI(mapCompanies);

      return {
        companies: mapCompanies,
        state: updateRes
      };
    }

    return {
      companies: [],
      state: false
    };
  };

  onError = (props) => {
    switch (props.code) {
      case FloorPlan.ERRORS['0x001'].code: {
        message.warning(
          i18n.t('draw_floor_plan_page_error_on_move_overlapping'),
          5
        );
        break;
      }

      case FloorPlan.ERRORS['0x002'].code: {
        message.warning(
          i18n.t('draw_floor_plan_page_error_on_create_group_overlapping'),
          5
        );
        break;
      }

      case FloorPlan.ERRORS['0x003'].code: {
        message.warning(
          i18n.t('draw_floor_plan_page_error_on_paste_overlapping'),
          5
        );
        break;
      }

      default:
        console.log(props);
        break;
    }
  };

  getMapActiveAPI = async (mapId) => {
    try {
      const res = await FloorPlanService.getMapActive({ mapId });

      return res.data.active;
    } catch (error) {
      return null;
    }
  };
}

export default Events;
