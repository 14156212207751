import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import FloorPlanService from 'src/services/floorPlanService';
import statusStore from 'src/stores/status';
import floorPlanViewModel from './viewModels/FloorPlanViewModel';

class DrawFloorPlanPageViewModel {
  id = null;

  @observable data = null;
  @observable isAwait = false;

  floorPlanContainerRef = React.createRef();

  @computed
  get check() {
    return {
      show: !!this.data
    };
  }

  @computed
  get title() {
    return this.data ? this.data.name : '';
  }

  constructor(props) {
    this.id = props.router.params.id;
    makeObservable(this);
  }

  @action didMount = async () => {
    if (!this.isAwait) {
      await this.getMapDetailAPI();
    }
  };

  @action getMapDetailAPI = async () => {
    this.isAwait = true;
    statusStore.setLoading();

    /**
     * 不建議更改順序
     * check.show 會影響元件 didMount, 若元件需要地圖的資料(CompanyDrawer), 故修改順序可能會導致功能壞掉
     */
    try {
      const res = await FloorPlanService.getMapDetail({ id: this.id });

      floorPlanViewModel.init();

      await floorPlanViewModel.load(
        res.data,
        this.floorPlanContainerRef.current
      );

      runInAction(() => {
        this.data = res.data;
        this.isAwait = false;
      });
    } catch (error) {
      console.log('DrawFloorPlanPageViewModel', 'getMapDetailAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });

      statusStore.setCompleted();
    }
  };
}

export default DrawFloorPlanPageViewModel;
