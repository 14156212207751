import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import i18n from 'src/i18n';
import FileItem from './components/FileItem';
import './reset.scss';

@observer
class CustomUpload extends React.Component {
  renderFileItem = (_, file) => (
    <FileItem name={file.name} onFileRemove={this.props.onFileRemove} />
  );

  render() {
    const { title, subtitle, onFileRemove, itemRender, ...ps } = this.props;

    return (
      <div className="customUpload">
        <Upload.Dragger
          {...ps}
          itemRender={this.renderFileItem}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>

          <p className="ant-upload-text">
            {title}
          </p>

          <p className="ant-upload-hint">
            {subtitle}
          </p>
        </Upload.Dragger>
      </div>
    );
  }
}

CustomUpload.propTypes = {
  customRequest: PropTypes.func,
  onFileRemove: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

CustomUpload.defaultProps = {
  customRequest: () => {},
  onFileRemove: () => {},
  title: '',
  subtitle: ''
};

export default CustomUpload;
