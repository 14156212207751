import { makeObservable, observable, computed, action } from 'mobx';
import {
  FONT_SIZES,
  MARK_TYPES,
  FLOOR_PLAN_DEFAULT_FONT_SIZES
} from 'src/constants';
import MARKS from 'src/constants/marks';
import FloorPlan from 'src/components/FloorPlan';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';

class OtherDrawerViewModel {
  @observable data = null;

  @observable type = null;
  @observable commonMark = null;

  @observable zhName = '';
  @observable enName = '';

  @observable zhNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.mark.zh;
  @observable enNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.mark.en;

  @observable boothNumber = '';
  @observable boothNumberFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.boothNumber;

  floorPlanVM = null;

  drawerVM = new CustomDrawerViewModel();

  @computed
  get disabled() {
    return {
      common: this.type !== MARK_TYPES.common,
      custom: this.type !== MARK_TYPES.custom
    };
  }

  @computed
  get check() {
    return {
      type: !!this.type,
      commonMark: !!this.commonMark,
      zhName: !!this.zhName.trim(),
      enName: !!this.enName.trim(),
      boothNumber: !!this.boothNumber.trim()
    };
  }

  @computed
  get commonMarkActiveKey() {
    return this.type === MARK_TYPES.common ? MARK_TYPES.common : null;
  }

  @computed
  get options() {
    const marks = Object.values(MARKS).filter((item) => !!item.src);

    return {
      marks,
      fontSizes: FONT_SIZES
    };
  }

  @computed
  get mark() {
    const checkType = this.check.type;

    // 選擇固定或自訂地標
    if (!checkType) {
      return null;
    }

    // 選擇固定地標
    if (MARKS[this.commonMark]) {
      const icon = MARKS[this.commonMark];
      return {
        ...icon,
        type: MARK_TYPES.icon
      };
    }

    // 自訂地標
    const zhName = this.zhName.trim();
    const enName = this.enName.trim();

    // 沒有輸入任何自訂地標
    if (!zhName && !enName) {
      return null;
    }

    return {
      id: `${zhName},${enName}`,
      type: MARK_TYPES.text,
      zhName,
      enName
    };
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  @action open = (group) => {
    this.data = group;

    // 確認圖片地標
    const checkMarkIcon = !!group.markIcon && !!MARKS[group.markIcon];

    // 確認文字地標
    const markText = Object.values(group.markText)?.[0];
    const checkMarkText = !!markText;

    if (checkMarkIcon) {
      this.type = MARK_TYPES.common;
      this.commonMark = group.markIcon;

      this.resetName();
    } else if (checkMarkText) {
      // 的文字地標
      const zhName = group.markText[FloorPlan.LANGUAGES.zh];
      const enName = group.markText[FloorPlan.LANGUAGES.en];

      this.type = MARK_TYPES.custom;
      this.commonMark = null;

      this.zhName = zhName.content;
      this.enName = enName.content;

      this.zhNameFontSize = String(zhName.fontSize);
      this.enNameFontSize = String(enName.fontSize);
    } else {
      // 沒有地標
      this.type = null;
      this.commonMark = null;

      this.resetName();
    }

    this.boothNumber = group.boothNumber?.content || '';
    this.boothNumberFontSize = String(
      group.boothNumber?.fontSize || FLOOR_PLAN_DEFAULT_FONT_SIZES.boothNumber
    );

    this.drawerVM.open();
  };

  close = () => this.drawerVM.close();

  @action resetName = () => {
    this.zhName = '';
    this.enName = '';

    this.zhNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.mark.zh;
    this.enNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.mark.en;
  };

  @action onTypeChange = (event) => {
    const value = event.target.value;

    this.type = value;

    if (value === MARK_TYPES.common) {
      this.resetName();
    } else {
      this.commonMark = null;
    }
  };

  @action onCommonMarkChange = (event) => {
    this.commonMark = event.target.value;
  };

  @action onZhNameChange = (event) => {
    this.zhName = event.target.value;
  };

  @action onEnNameChange = (event) => {
    this.enName = event.target.value;
  };

  @action onZhNameFontSizeChange = (val) => {
    this.zhNameFontSize = val;
  };

  @action onEnNameFontSizeChange = (val) => {
    this.enNameFontSize = val;
  };

  @action onBoothNumberChange = (event) => {
    this.boothNumber = event.target.value;
  };

  @action onBoothNumberFontSizeChange = (val) => {
    this.boothNumberFontSize = val;
  };

  onConfirm = async () => {
    const groupId = this.data.id;
    const mark = this.mark;
    const isIcon = mark?.type === MARK_TYPES.icon;
    const isText = mark?.type === MARK_TYPES.text;
    const boothNumber = this.boothNumber.trim();
    const detail = {};

    if (mark && isIcon) {
      detail.markIcon = mark;
    }

    if (mark && isText) {
      detail.markText = {
        [FloorPlan.LANGUAGES.zh]: {
          id: mark.id,
          content: mark.zhName,
          language: this.floorPlanVM.LANGUAGES.zh,
          fontSize: Number(this.zhNameFontSize)
        },
        [FloorPlan.LANGUAGES.en]: {
          id: mark.id,
          content: mark.enName,
          language: this.floorPlanVM.LANGUAGES.en,
          fontSize: Number(this.enNameFontSize)
        }
      };
    }

    if (boothNumber) {
      detail.boothNumber = {
        content: this.boothNumber.trim(),
        fontSize: Number(this.boothNumberFontSize)
      };
    }

    await this.floorPlanVM.sets.setFloorPlanBoothDetail({
      groupId,
      detail,
      history: true,
      type: FloorPlan.HISTORIES.other
    });

    this.floorPlanVM.updateFloorPlanAPI();
  };

  pauseSelect = () => {
    this.floorPlanVM.pauseSelect();
  };

  resumeSelect = () => {
    this.floorPlanVM.resumeSelect();
  };
}

export default OtherDrawerViewModel;
