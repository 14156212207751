import Group from 'src/components/FloorPlan/components/Group';
import GroupDataModel from 'src/components/FloorPlan/dataModels/GroupDataModel';
import { HISTORIES, LANGUAGES } from '../../../global/constants';

class Backs {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  // eslint-disable-next-line consistent-return
  back = () => {
    if (this.parent.isPause) {
      return false;
    }

    // 歷史紀錄
    const item = this.parent.lastHistory;

    if (item) {
      // 取消選取
      this.parent.context.clearSelects();

      switch (item.type) {
        case HISTORIES.createBooth: {
          this.backReset();
          break;
        }

        case HISTORIES.move: {
          this.backReset();
          break;
        }

        case HISTORIES.setBoothsColor: {
          this.backReset();
          break;
        }

        case HISTORIES.setTextsFontSize: {
          this.backReset();
          break;
        }

        case HISTORIES.pasteBooths: {
          this.backReset();
          break;
        }

        case HISTORIES.removeCompanies: {
          this.backReset();
          break;
        }

        case HISTORIES.removeBooths: {
          this.backReset();
          break;
        }

        case HISTORIES.mergeBooths: {
          this.backReset();
          break;
        }

        case HISTORIES.stamp: {
          this.backReset();
          break;
        }

        case HISTORIES.companies: {
          this.backReset();
          break;
        }

        case HISTORIES.other: {
          this.backReset();
          break;
        }

        default:
          console.log('上一步類型錯誤', item);
          break;
      }
    } else {
      console.log('沒有上一步');
    }
  };

  // 讀取上一步, 讓外部先 call api
  getLastHistory = () => {
    const item = this.parent.lastHistory;

    return {
      type: item.type,
      before: this.formatGroupsToCompanies(item.before),
      after: this.formatGroupsToCompanies(item.after)
    };
  };

  // 通用的返回
  backReset = () => {
    const item = this.parent.pop();

    // 要先刪除新的
    item.after.forEach((res) => {
      const group = this.parent.context.getGroup(res.id);
      group.remove();
    });

    item.before.forEach((res) => {
      const group = new Group({
        data: new GroupDataModel(res),
        context: this.parent.context
      });

      group.addChildToMap();
    });
  };

  // 將攤位轉為參展商資料
  formatGroupsToCompanies = (groupsRes) => {
    const res = [];

    groupsRes.forEach((group) => {
      Object.keys(group.companies || {}).forEach((companyId) => {
        const company = group.companies[companyId];
        const resCompany = {
          id: companyId,
          // 因為地圖並沒有儲存參展商的攤位編號, 所以只能讀取攤位的攤位編號, 可能會將 A001,B001 這樣的攤位編號寫給參展商
          boothNumber: group.boothNumber?.id || ''
        };

        Object.keys(company).forEach((lang) => {
          const data = company[lang];

          if (lang === LANGUAGES.zh) {
            resCompany.zhName = data.content;
          }

          if (lang === LANGUAGES.en) {
            resCompany.enName = data.content;
          }
        });

        res.push(resCompany);
      });
    });

    return res;
  };
}

export default Backs;
