import { FLOOR_PLAN_DEFAULT_FONT_SIZES } from 'src/constants';
import {
  OBJECT_TYPES,
  COLORS,
  FONT_FAMILY_BOOTH_NUMBER
} from '../../global/constants';
import TextDataModel from '../../dataModels/TextDataModel';
import Rect from '../Rect';
import Text from '../Text';

class Merge {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  addRect = (rect) => {
    const rects = this.parent.rects.slice();
    const diffPosition = {
      x: rect.group.view.x - this.parent.view.x,
      y: rect.group.view.y - this.parent.view.y
    };
    const newRect = new Rect({
      group: this.parent,
      context: this.parent.context,
      data: {
        ...rect.res,
        x: rect.view.x + diffPosition.x,
        y: rect.view.y + diffPosition.y
      }
    });

    newRect.addChildToGroup();

    rects.push(newRect);

    this.parent.mergeRects(rects);

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  addCompany = (company) => {
    const companies = this.parent.companies;
    const companyId = Object.values(company)[0].id;
    const findCompany = this.parent.companies[companyId];

    if (findCompany) {
      console.log('group', 'addCompany', '參展商已蓋章');
    } else {
      const newCompany = {};

      Object.keys(company).forEach((lang) => {
        const text = company[lang];
        const diffPosition = {
          x: text.group.view.x - this.parent.view.x,
          y: text.group.view.y - this.parent.view.y
        };
        const textRes = text.res;
        const checkAreaName = !!this.parent.get.flatAreaName.keys.length;
        const checkLang
          = textRes.language === this.parent.context.store.language;
        const data = new TextDataModel({
          ...textRes,
          translateX: textRes.translateX + diffPosition.x,
          translateY: textRes.translateY + diffPosition.y,
          visible: !checkAreaName && checkLang
        });

        const newText = new Text({
          group: this.parent,
          context: this.parent.context,
          data
        });

        newText.addChildToMap();

        newCompany[lang] = newText;
      });

      companies[companyId] = newCompany;

      this.parent.mergeCompanies(companies);
    }

    if (this.parent.isActive) {
      this.parent.active();
    }
  };

  addBoothNumber = (boothNumber) => {
    if (this.parent.boothNumber) {
      const oldBoothNumber = this.parent.boothNumber.content.split(',');

      oldBoothNumber.push(boothNumber.content);

      const newBoothNumber = Array.from(new Set(oldBoothNumber)).join(',');

      this.parent.boothNumber.updateId(newBoothNumber);
      this.parent.boothNumber.updateContent(newBoothNumber);
    } else {
      const data = new TextDataModel({
        id: boothNumber.content,
        fontFamily: FONT_FAMILY_BOOTH_NUMBER,
        fontSize: Number(FLOOR_PLAN_DEFAULT_FONT_SIZES.boothNumber),
        type: OBJECT_TYPES.boothNumber,
        language: null,
        content: boothNumber.content,
        translateX: this.parent.get.boothNumberBasic.x,
        translateY: this.parent.get.boothNumberBasic.y,
        fill: COLORS.normal.text,
        visible: true
      });
      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data
      });

      text.addChildToMap();

      this.parent.mergeBoothNumber(text);
    }
  };
}

export default Merge;
