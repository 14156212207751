import {
  makeObservable,
  observable,
  computed,
  action
} from 'mobx';

class CheckboxTagViewModel {
  @observable id = '';
  @observable name = '';
  @observable checked = false;

  labelId = '';
  key = '';

  companyId = '';

  constructor(props) {
    this.id = props.data.id;
    this.name = props.data.name;
    this.checked = props.data.checked || false;

    this.labelId = `${this.companyId}${this.id}`;

    // 用於標籤池修改自訂標籤的名稱時, 重新 render
    this.key = `${props.data.id}${props.data.name}`;

    this.companyId = props.companyId;

    makeObservable(this);
  }

  @action onCheckedChange = (event) => {
    this.checked = event.target.checked;
  };

  @action setChecked = (val) => {
    this.checked = val;
  };
}

export default CheckboxTagViewModel;
