import { EVENT_CODES } from '../global/constants';

class DragMapControl {
  isPause = false;
  isKeydown = false;

  context = null;

  constructor(props) {
    this.context = props;
  }

  init = () => {
    this.context.viewport.plugins.pause('drag');

    window.addEventListener('keydown', this.onWindowKeydown);
    window.addEventListener('keyup', this.onWindowKeyup);
  };

  destroy = () => {
    window.removeEventListener('keydown', this.onWindowKeydown);
    window.removeEventListener('keyup', this.onWindowKeyup);
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  onWindowKeydown = (event) => {
    if (
      event.keyCode === EVENT_CODES.space
      && !this.isKeydown
      && !this.isPause
    ) {
      this.isKeydown = true;

      this.context.closeMenu();

      this.context.viewport.plugins.resume('drag');

      this.context.pause('createGroup');
      this.context.pause('select');
      this.context.pause('menu');
      this.context.pause('dragObject');
      this.context.pause('stamp');
    }
  };

  onWindowKeyup = (event) => {
    if (event.keyCode === EVENT_CODES.space && !this.isPause) {
      this.isKeydown = false;

      this.context.viewport.plugins.pause('drag');

      this.context.resume('createGroup');
      this.context.resume('select');
      this.context.resume('menu');
      this.context.resume('dragObject');
      this.context.resume('stamp');
    }
  };
}

export default DragMapControl;
