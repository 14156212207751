import React from 'react';
import PropTypes from 'prop-types';
import { Collapse } from 'antd';
import './reset.scss';

function CustomCollapse(props) {
  return (
    <Collapse
      className="other-drawer-custom-collapse"
      {...props}
    />
  );
}

CustomCollapse.propTypes = {
  items: PropTypes.array.isRequired
};

CustomCollapse.defaultProps = {
};

export default CustomCollapse;
