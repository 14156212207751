import * as PIXI from 'pixi.js';
import { BACKGROUND_TYPES, OBJECT_ZINDEXS } from '../../global/constants';

class Background {
  view = new PIXI.Container();
  background = null;

  size = 0;
  row = 0;
  col = 0;
  image = null;

  type = null;
  src = null;

  context = null;

  get res() {
    return {
      type: this.type,
      src: this.src
    };
  }

  constructor(props) {
    this.context = props;
  }

  init = async () => {
    const gl = document.createElement('canvas').getContext('webgl');
    const maxSize = gl.getParameter(gl.MAX_TEXTURE_SIZE);

    this.size = Math.min(2048, maxSize);

    this.view.zIndex = OBJECT_ZINDEXS.background;

    this.context.map.addChild(this.view);
  };

  // 更新 store
  setRowCol = (src = '') => {
    return new Promise((res, rej) => {
      const image = new Image();

      image.addEventListener('load', () => {
        // grid 使用的 col row
        const col = Math.ceil(image.width / this.context.store.gridSize);
        const row = Math.ceil(image.height / this.context.store.gridSize);
        this.context.store.setSize({ col, row });

        // 自己的 col row
        this.col = Math.ceil(image.width / this.size);
        this.row = Math.ceil(image.height / this.size);
        this.image = image;

        res();
      });

      image.src = src;
    });
  };

  // 判斷背景圖的副檔名
  getBackgroundType = (src = '') => {
    const index = src.indexOf(';');
    const media = src.slice(0, index);

    if (media.includes(BACKGROUND_TYPES.svg)) {
      return BACKGROUND_TYPES.svg;
    }

    if (media.includes(BACKGROUND_TYPES.jpeg)) {
      return BACKGROUND_TYPES.jpeg;
    }

    if (media.includes(BACKGROUND_TYPES.jpg)) {
      return BACKGROUND_TYPES.jpg;
    }

    return null;
  };

  getCuts = () => {
    const cuts = [];

    // y 軸
    for (let i = 0; i < this.row; i += 1) {
      const y = i * this.size;

      // x 軸
      for (let j = 0; j < this.col; j += 1) {
        const x = j * this.size;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        const width
          = this.image.width > x + this.size ? this.size : this.image.width - x;
        const height
          = this.image.height > y + this.size
            ? this.size
            : this.image.height - y;

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(this.image, x, y, width, height, 0, 0, width, height);

        const src = canvas.toDataURL('image/jpeg');

        cuts.push({ x, y, width, height, src });
      }
    }

    return cuts;
  };

  // 切 jpg
  getMergeImage = async () => {
    const cuts = this.getCuts();
    const container = new PIXI.Container();
    const textures = await Promise.all(
      cuts.map((item) => PIXI.Assets.load(item.src))
    );
    const mapCuts = cuts.map((item, i) => {
      return { ...item, texture: textures[i] };
    });

    mapCuts.forEach((item) => {
      const sprite = new PIXI.Sprite(item.texture);

      sprite.x = item.x;
      sprite.y = item.y;
      sprite.width = item.width;
      sprite.height = item.height;

      sprite.cullable = true;

      container.addChild(sprite);
    });

    /**
     * svg 避免變形會增加 padding 與邊界的距離, 但是轉為 jpeg 後, 距離會變成黑線, 故這裡增加 rect 遮擋黑線
     */
    const rect = new PIXI.Sprite(PIXI.Texture.WHITE);

    rect.width = this.image.width;
    rect.height = 20;

    rect.x = 0;
    rect.y = this.image.height - 10;

    rect.tint = '#ffffff';

    container.addChild(rect);

    return container;
  };

  // 產出背景圖
  getBackground = async (src, type) => {
    switch (type) {
      // case BACKGROUND_TYPES.svg: {
      //   const svg = await SVGScene.from(src);
      //   const texture = this.context.app.renderer.generateTexture(svg);
      //   const image = new PIXI.Sprite(texture);

      //   image.width = svg.width;
      //   image.height = svg.height;

      //   const col = Math.ceil(image.width / this.context.store.gridSize);
      //   const row = Math.ceil(image.height / this.context.store.gridSize);
      //   const res = { col, row };

      //   this.context.store.setSize(res);

      //   svg.destroy();

      //   return image;
      // }

      case BACKGROUND_TYPES.svg:
      case BACKGROUND_TYPES.jpeg:
      case BACKGROUND_TYPES.jpg: {
        await this.setRowCol(src);
        const image = await this.getMergeImage();

        return image;
      }

      default:
        return null;
    }
  };

  updateClamp = () => {
    // 限制地圖拖移範圍
    const width = this.context.store.col * this.context.store.gridSize;
    const height = this.context.store.row * this.context.store.gridSize;

    this.context.viewport.clamp({
      left: -1000,
      right: width + 1000,
      top: -1500,
      bottom: height + 1000
    });
  };

  // load 時畫出背景圖
  setBackground = async (src) => {
    this.background?.destroy(true);

    const type = this.getBackgroundType(src);
    const background = await this.getBackground(src, type);

    this.view.addChild(background);
    this.view.removeChild(this.background);

    this.background = background;

    this.src = src;
    this.type = type;

    this.updateClamp();
  };
}

export default Background;
