const PILLAR_TYPE_1
  = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjYiIGhlaWdodD0iNjYiIHZpZXdCb3g9IjAgMCA2NiA2NiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjY2IiBoZWlnaHQ9IjY2IiBmaWxsPSIjQ0NDQ0NDIi8+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNjYgMFY2NkgwVjBINjZaTTMgNS4xMDk0NVYxMC45NzY2TDEwLjk3NjYgM0g1LjEwOTQyTDMgNS4xMDk0NVpNMTMuODA0NyAzTDMgMTMuODA0OFYxOS42NzE5TDE5LjY3MTkgM0gxMy44MDQ3Wk0yMi41IDNMMyAyMi41MDAxVjI4LjM2NzJMMjguMzY3MiAzSDIyLjVaTTMxLjE5NTMgM0wzIDMxLjE5NTRWMzcuMDYyNUwzNy4wNjI1IDNIMzEuMTk1M1pNMzkuODkwNiAzTDMgMzkuODkwN1Y0NS43NTc4TDQ1Ljc1NzggM0gzOS44OTA2Wk00OC41ODU5IDNMMyA0OC41ODYxVjU0LjQ1MzFMNTQuNDUzMSAzSDQ4LjU4NTlaTTU3LjI4MTMgM0wzIDU3LjI4MTRWNjNIMy4xNDg0NEw2MyAzLjE0ODQ0VjNINTcuMjgxM1pNNjMgNS45NzY2OEw1Ljk3NjU3IDYzSDExLjg0MzhMNjMgMTEuODQzOFY1Ljk3NjY4Wk02MyAxNC42NzJMMTQuNjcxOSA2M0gyMC41MzkxTDYzIDIwLjUzOTFWMTQuNjcyWk02MyAyMy4zNjczTDIzLjM2NzIgNjNIMjkuMjM0NEw2MyAyOS4yMzQ0VjIzLjM2NzNaTTYzIDMyLjA2MjZMMzIuMDYyNSA2M0gzNy45Mjk3TDYzIDM3LjkyOTdWMzIuMDYyNlpNNjMgNDAuNzU3OUw0MC43NTc4IDYzSDQ2LjYyNUw2MyA0Ni42MjVWNDAuNzU3OVpNNjMgNDkuNDUzMkw0OS40NTMxIDYzSDU1LjMyMDNMNjMgNTUuMzIwM1Y0OS40NTMyWk02MyA1OC4xNDg1TDU4LjE0ODUgNjNINjNWNTguMTQ4NVoiIGZpbGw9IiM2NjY2NjYiLz4KPC9zdmc+Cg==';

const PILLAR_TYPE_2
  = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iNzEiIHZpZXdCb3g9IjAgMCA4OCA3MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMjQuNSIgeT0iMC41IiB3aWR0aD0iNjIuOSIgaGVpZ2h0PSI2OS41IiBzdHJva2U9IiNEOTRFNTYiLz4KPHBhdGggZD0iTTY4IDFIMjVWNTBINjhWMVoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02OCAxVjUwSDI1VjFINjhaTTI2Ljk1NDUgNC43OTMzOFY5LjE0OTI5TDMyLjE1MTQgMy4yMjcyN0gyOC4zMjg5TDI2Ljk1NDUgNC43OTMzOFpNMzMuOTk0IDMuMjI3MjdMMjYuOTU0NSAxMS4yNDlWMTUuNjA0OUwzNy44MTY1IDMuMjI3MjdIMzMuOTk0Wk0zOS42NTkxIDMuMjI3MjdMMjYuOTU0NSAxNy43MDQ2VjIyLjA2MDVMNDMuNDgxNyAzLjIyNzI3SDM5LjY1OTFaTTQ1LjMyNDIgMy4yMjcyN0wyNi45NTQ1IDI0LjE2MDJWMjguNTE2MUw0OS4xNDY4IDMuMjI3MjdINDUuMzI0MlpNNTAuOTg5MyAzLjIyNzI3TDI2Ljk1NDUgMzAuNjE1OFYzNC45NzE3TDU0LjgxMTkgMy4yMjcyN0g1MC45ODkzWk01Ni42NTQ1IDMuMjI3MjdMMjYuOTU0NSAzNy4wNzE1VjQxLjQyNzNMNjAuNDc3IDMuMjI3MjdINTYuNjU0NVpNNjIuMzE5NiAzLjIyNzI3TDI2Ljk1NDUgNDMuNTI3MVY0Ny43NzI3SDI3LjA1MTNMNjYuMDQ1NSAzLjMzNzQ4VjMuMjI3MjdINjIuMzE5NlpNNjYuMDQ1NSA1LjQzNzIzTDI4Ljg5MzggNDcuNzcyN0gzMi43MTY0TDY2LjA0NTUgOS43OTMxMlY1LjQzNzIzWk02Ni4wNDU1IDExLjg5MjhMMzQuNTU5IDQ3Ljc3MjdIMzguMzgxNUw2Ni4wNDU1IDE2LjI0ODdWMTEuODkyOFpNNjYuMDQ1NSAxOC4zNDg0TDQwLjIyNDEgNDcuNzcyN0g0NC4wNDY3TDY2LjA0NTUgMjIuNzA0M1YxOC4zNDg0Wk02Ni4wNDU1IDI0LjgwNDFMNDUuODg5MiA0Ny43NzI3SDQ5LjcxMThMNjYuMDQ1NSAyOS4xNTk5VjI0LjgwNDFaTTY2LjA0NTUgMzEuMjU5N0w1MS41NTQzIDQ3Ljc3MjdINTUuMzc2OUw2Ni4wNDU1IDM1LjYxNTVWMzEuMjU5N1pNNjYuMDQ1NSAzNy43MTUzTDU3LjIxOTQgNDcuNzcyN0g2MS4wNDJMNjYuMDQ1NSA0Mi4wNzExVjM3LjcxNTNaTTY2LjA0NTUgNDQuMTcwOUw2Mi44ODQ2IDQ3Ljc3MjdINjYuMDQ1NVY0NC4xNzA5WiIgZmlsbD0iIzY2NjY2NiIvPgo8ZWxsaXBzZSBjeD0iNDYiIGN5PSI2MC4yNSIgcng9IjEwIiByeT0iMTAuMjUiIGZpbGw9IiM1QzZFOEEiLz4KPGVsbGlwc2UgY3g9Ijc3Ljk1IiBjeT0iMjYiIHJ4PSI5Ljk1IiByeT0iMTAiIGZpbGw9IiM1QzZFOEEiLz4KPHBhdGggZD0iTTYuNSA0LjQ3MzcyTDIzIDE0TDYuNSAyMy41MjYzTDYuNSA0LjQ3MzcyWiIgc3Ryb2tlPSIjRDk0RTU2Ii8+CjxwYXRoIGQ9Ik0yNCAzOEw2IDQ4LjM5MjNMNiAyNy42MDc3TDI0IDM4WiIgZmlsbD0iIzQwQUYzNiIvPgo8L3N2Zz4K';

const PILLAR_TYPE_3
  = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iODgiIGhlaWdodD0iNzEiIHZpZXdCb3g9IjAgMCA4OCA3MSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMjQuNSIgeT0iMC41IiB3aWR0aD0iNjIuOSIgaGVpZ2h0PSI2OS41IiBzdHJva2U9IiNEOTRFNTYiLz4KPHBhdGggZD0iTTY4IDFIMjVWNTBINjhWMVoiIGZpbGw9IiNDQ0NDQ0MiLz4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02OCAxVjUwSDI1VjFINjhaTTI2Ljk1NDUgNC43OTMzOFY5LjE0OTI5TDMyLjE1MTQgMy4yMjcyN0gyOC4zMjg5TDI2Ljk1NDUgNC43OTMzOFpNMzMuOTk0IDMuMjI3MjdMMjYuOTU0NSAxMS4yNDlWMTUuNjA0OUwzNy44MTY1IDMuMjI3MjdIMzMuOTk0Wk0zOS42NTkxIDMuMjI3MjdMMjYuOTU0NSAxNy43MDQ2VjIyLjA2MDVMNDMuNDgxNyAzLjIyNzI3SDM5LjY1OTFaTTQ1LjMyNDIgMy4yMjcyN0wyNi45NTQ1IDI0LjE2MDJWMjguNTE2MUw0OS4xNDY4IDMuMjI3MjdINDUuMzI0MlpNNTAuOTg5MyAzLjIyNzI3TDI2Ljk1NDUgMzAuNjE1OFYzNC45NzE3TDU0LjgxMTkgMy4yMjcyN0g1MC45ODkzWk01Ni42NTQ1IDMuMjI3MjdMMjYuOTU0NSAzNy4wNzE1VjQxLjQyNzNMNjAuNDc3IDMuMjI3MjdINTYuNjU0NVpNNjIuMzE5NiAzLjIyNzI3TDI2Ljk1NDUgNDMuNTI3MVY0Ny43NzI3SDI3LjA1MTNMNjYuMDQ1NSAzLjMzNzQ4VjMuMjI3MjdINjIuMzE5NlpNNjYuMDQ1NSA1LjQzNzIzTDI4Ljg5MzggNDcuNzcyN0gzMi43MTY0TDY2LjA0NTUgOS43OTMxMlY1LjQzNzIzWk02Ni4wNDU1IDExLjg5MjhMMzQuNTU5IDQ3Ljc3MjdIMzguMzgxNUw2Ni4wNDU1IDE2LjI0ODdWMTEuODkyOFpNNjYuMDQ1NSAxOC4zNDg0TDQwLjIyNDEgNDcuNzcyN0g0NC4wNDY3TDY2LjA0NTUgMjIuNzA0M1YxOC4zNDg0Wk02Ni4wNDU1IDI0LjgwNDFMNDUuODg5MiA0Ny43NzI3SDQ5LjcxMThMNjYuMDQ1NSAyOS4xNTk5VjI0LjgwNDFaTTY2LjA0NTUgMzEuMjU5N0w1MS41NTQzIDQ3Ljc3MjdINTUuMzc2OUw2Ni4wNDU1IDM1LjYxNTVWMzEuMjU5N1pNNjYuMDQ1NSAzNy43MTUzTDU3LjIxOTQgNDcuNzcyN0g2MS4wNDJMNjYuMDQ1NSA0Mi4wNzExVjM3LjcxNTNaTTY2LjA0NTUgNDQuMTcwOUw2Mi44ODQ2IDQ3Ljc3MjdINjYuMDQ1NVY0NC4xNzA5WiIgZmlsbD0iIzY2NjY2NiIvPgo8ZWxsaXBzZSBjeD0iNDYiIGN5PSI2MC4yNSIgcng9IjEwIiByeT0iMTAuMjUiIGZpbGw9IiM1QzZFOEEiLz4KPGVsbGlwc2UgY3g9Ijc3Ljk1IiBjeT0iMjYiIHJ4PSI5Ljk1IiByeT0iMTAiIGZpbGw9IiM1QzZFOEEiLz4KPHBhdGggZD0iTTYuNSA0LjQ3MzcyTDIzIDE0TDYuNSAyMy41MjYzTDYuNSA0LjQ3MzcyWiIgc3Ryb2tlPSIjRDk0RTU2Ii8+Cjwvc3ZnPgo=';

export const PILLAR_TYPES = {
  type1: {
    value: 'type1',
    src: PILLAR_TYPE_1
  },
  type2: {
    value: 'type2',
    src: PILLAR_TYPE_2
  },
  type3: {
    value: 'type3',
    src: PILLAR_TYPE_3
  }
};

export const PILLARS = {
  'ng1-1': [
    { x: 512, y: 818.5, type: 'type2', visible: true },
    { x: 1051.5, y: 818.5, type: 'type3', visible: true },
    { x: 1588.5, y: 818.5, type: 'type2', visible: true },
    { x: 2128.5, y: 818.5, type: 'type3', visible: true },
    { x: 2668.5, y: 818.5, type: 'type2', visible: true },
    { x: 3205.5, y: 818.5, type: 'type3', visible: true },
    { x: 3745.5, y: 818.5, type: 'type2', visible: true },
    { x: 4285.5, y: 818.5, type: 'type3', visible: true },
    { x: 4822.5, y: 818.5, type: 'type2', visible: true },
    { x: 5362.5, y: 818.5, type: 'type3', visible: true },
    { x: 512, y: 1359.5, type: 'type2', visible: true },
    { x: 1051.5, y: 1359.5, type: 'type3', visible: true },
    { x: 1588.5, y: 1359.5, type: 'type2', visible: true },
    { x: 2128.5, y: 1359.5, type: 'type3', visible: true },
    { x: 2668.5, y: 1359.5, type: 'type2', visible: true },
    { x: 3205.5, y: 1359.5, type: 'type3', visible: true },
    { x: 3745.5, y: 1359.5, type: 'type2', visible: true },
    { x: 4285.5, y: 1359.5, type: 'type3', visible: true },
    { x: 4822.5, y: 1359.5, type: 'type2', visible: true },
    { x: 5362.5, y: 1359.5, type: 'type3', visible: true },
    { x: 512, y: 1898.5, type: 'type2', visible: true },
    { x: 1051.5, y: 1898.5, type: 'type3', visible: true },
    { x: 1588.5, y: 1898.5, type: 'type2', visible: true },
    { x: 2128.5, y: 1898.5, type: 'type3', visible: true },
    { x: 2668.5, y: 1898.5, type: 'type2', visible: true },
    { x: 3205.5, y: 1898.5, type: 'type3', visible: true },
    { x: 3745.5, y: 1898.5, type: 'type2', visible: true },
    { x: 4285.5, y: 1898.5, type: 'type3', visible: true },
    { x: 4822.5, y: 1898.5, type: 'type2', visible: true },
    { x: 5362.5, y: 1898.5, type: 'type3', visible: true },
    { x: 512, y: 2438.5, type: 'type2', visible: true },
    { x: 1051.5, y: 2438.5, type: 'type3', visible: true },
    { x: 1588.5, y: 2438.5, type: 'type2', visible: true },
    { x: 2128.5, y: 2438.5, type: 'type3', visible: true },
    { x: 2668.5, y: 2438.5, type: 'type2', visible: true },
    { x: 3205.5, y: 2438.5, type: 'type3', visible: true },
    { x: 3745.5, y: 2438.5, type: 'type2', visible: true },
    { x: 4285.5, y: 2438.5, type: 'type3', visible: true },
    { x: 4822.5, y: 2438.5, type: 'type2', visible: true },
    { x: 5362.5, y: 2438.5, type: 'type3', visible: true },
    { x: 512, y: 2976.5, type: 'type2', visible: true },
    { x: 1051.5, y: 2976.5, type: 'type3', visible: true },
    { x: 1588.5, y: 2976.5, type: 'type2', visible: true },
    { x: 2128.5, y: 2976.5, type: 'type3', visible: true },
    { x: 2668.5, y: 2976.5, type: 'type2', visible: true },
    { x: 3205.5, y: 2976.5, type: 'type3', visible: true },
    { x: 3745.5, y: 2976.5, type: 'type2', visible: true },
    { x: 4285.5, y: 2976.5, type: 'type3', visible: true },
    { x: 4822.5, y: 2976.5, type: 'type2', visible: true },
    { x: 5362.5, y: 2976.5, type: 'type3', visible: true },
    { x: 512, y: 3513.5, type: 'type2', visible: true },
    { x: 1051.5, y: 3513.5, type: 'type3', visible: true },
    { x: 1588.5, y: 3513.5, type: 'type2', visible: true },
    { x: 2128.5, y: 3513.5, type: 'type3', visible: true },
    { x: 2668.5, y: 3513.5, type: 'type2', visible: true },
    { x: 3205.5, y: 3513.5, type: 'type3', visible: true },
    { x: 3745.5, y: 3513.5, type: 'type2', visible: true },
    { x: 4285.5, y: 3513.5, type: 'type3', visible: true },
    { x: 4822.5, y: 3513.5, type: 'type2', visible: true },
    { x: 5362.5, y: 3513.5, type: 'type3', visible: true }
  ],
  'ng1-4': [],
  'ng2-1': [
    { x: 493, y: 492, type: 'type1', visible: true },
    { x: 1033, y: 492, type: 'type1', visible: true },
    { x: 1573, y: 492, type: 'type1', visible: true },
    { x: 2113, y: 492, type: 'type1', visible: true },
    { x: 2653, y: 492, type: 'type1', visible: true },
    { x: 3193, y: 492, type: 'type1', visible: true },
    { x: 493, y: 1032, type: 'type1', visible: true },
    { x: 1033, y: 1032, type: 'type1', visible: true },
    { x: 1573, y: 1032, type: 'type1', visible: true },
    { x: 2113, y: 1032, type: 'type1', visible: true },
    { x: 2653, y: 1032, type: 'type1', visible: true },
    { x: 3193, y: 1032, type: 'type1', visible: true },
    { x: 493, y: 1572, type: 'type1', visible: true },
    { x: 1033, y: 1572, type: 'type1', visible: true },
    { x: 1573, y: 1572, type: 'type1', visible: true },
    { x: 2113, y: 1572, type: 'type1', visible: true },
    { x: 2653, y: 1572, type: 'type1', visible: true },
    { x: 3193, y: 1572, type: 'type1', visible: true },
    { x: 493, y: 2112, type: 'type1', visible: true },
    { x: 1033, y: 2112, type: 'type1', visible: true },
    { x: 1573, y: 2112, type: 'type1', visible: true },
    { x: 2113, y: 2112, type: 'type1', visible: true },
    { x: 2653, y: 2112, type: 'type1', visible: true },
    { x: 3193, y: 2112, type: 'type1', visible: true },
    { x: 493, y: 2652, type: 'type1', visible: true },
    { x: 1033, y: 2652, type: 'type1', visible: true },
    { x: 1573, y: 2652, type: 'type1', visible: true },
    { x: 2113, y: 2652, type: 'type1', visible: true },
    { x: 2653, y: 2652, type: 'type1', visible: true },
    { x: 3193, y: 2652, type: 'type1', visible: true },
    { x: 493, y: 3192, type: 'type1', visible: true },
    { x: 1033, y: 3192, type: 'type1', visible: true },
    { x: 1573, y: 3192, type: 'type1', visible: true },
    { x: 2113, y: 3192, type: 'type1', visible: true },
    { x: 2653, y: 3192, type: 'type1', visible: true },
    { x: 3193, y: 3192, type: 'type1', visible: true },
    { x: 493, y: 3732, type: 'type1', visible: true },
    { x: 1033, y: 3732, type: 'type1', visible: true },
    { x: 1573, y: 3732, type: 'type1', visible: true },
    { x: 2113, y: 3732, type: 'type1', visible: true },
    { x: 2653, y: 3732, type: 'type1', visible: true },
    { x: 3193, y: 3732, type: 'type1', visible: true }
  ],
  'ng2-4': [
    { x: 523, y: 522, type: 'type1', visible: true },
    { x: 1063, y: 522, type: 'type1', visible: true },
    { x: 1603, y: 522, type: 'type1', visible: true },
    { x: 2143, y: 522, type: 'type1', visible: true },
    { x: 2683, y: 522, type: 'type1', visible: true },
    { x: 3223, y: 522, type: 'type1', visible: true },
    { x: 523, y: 1062, type: 'type1', visible: true },
    { x: 1063, y: 1062, type: 'type1', visible: true },
    { x: 1603, y: 1062, type: 'type1', visible: true },
    { x: 2143, y: 1062, type: 'type1', visible: true },
    { x: 2683, y: 1062, type: 'type1', visible: true },
    { x: 3223, y: 1062, type: 'type1', visible: true },
    { x: 523, y: 1602, type: 'type1', visible: true },
    { x: 1063, y: 1602, type: 'type1', visible: true },
    { x: 1603, y: 1602, type: 'type1', visible: true },
    { x: 2143, y: 1602, type: 'type1', visible: true },
    { x: 2683, y: 1602, type: 'type1', visible: true },
    { x: 3223, y: 1602, type: 'type1', visible: true },
    { x: 523, y: 2142, type: 'type1', visible: true },
    { x: 1063, y: 2142, type: 'type1', visible: true },
    { x: 1603, y: 2142, type: 'type1', visible: true },
    { x: 2143, y: 2142, type: 'type1', visible: true },
    { x: 2683, y: 2142, type: 'type1', visible: true },
    { x: 3223, y: 2142, type: 'type1', visible: true },
    { x: 523, y: 2682, type: 'type1', visible: true },
    { x: 1063, y: 2682, type: 'type1', visible: true },
    { x: 1603, y: 2682, type: 'type1', visible: true },
    { x: 2143, y: 2682, type: 'type1', visible: true },
    { x: 2683, y: 2682, type: 'type1', visible: true },
    { x: 3223, y: 2682, type: 'type1', visible: true },
    { x: 523, y: 3222, type: 'type1', visible: true },
    { x: 1063, y: 3222, type: 'type1', visible: true },
    { x: 1603, y: 3222, type: 'type1', visible: true },
    { x: 2143, y: 3222, type: 'type1', visible: true },
    { x: 2683, y: 3222, type: 'type1', visible: true },
    { x: 3223, y: 3222, type: 'type1', visible: true },
    { x: 523, y: 3762, type: 'type1', visible: true },
    { x: 1063, y: 3762, type: 'type1', visible: true },
    { x: 1603, y: 3762, type: 'type1', visible: true },
    { x: 2143, y: 3762, type: 'type1', visible: true },
    { x: 2683, y: 3762, type: 'type1', visible: true },
    { x: 3223, y: 3762, type: 'type1', visible: true }
  ]
};
