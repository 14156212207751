import { user } from 'src/api';

export default class UserService {
  static async login(data) {
    const res = await user.login(data);

    return res.data.result;
  }

  static async getProfile() {
    const res = await user.getProfile();

    return res.data.result;
  }

  static async logout() {
    await user.logout();
  }
}
