import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class BoothNumberFontSizeViewModel {
  @observable fontSize = '14';

  @computed
  get res() {
    return Number(this.fontSize);
  }

  constructor(props) {
    makeObservable(this);
  }

  @action onFontSizeChange = (val) => {
    this.fontSize = val;
  };

  @action setFontSize = (val = '14') => {
    this.fontSize = String(val);

    console.log('BoothNumberFontSizeViewModel', 'setFontSize', val);
  };
}

export default BoothNumberFontSizeViewModel;
