import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import ForWebViewFloorPlanPageViewModel from './viewModel';
import styles from './styles.module.scss';

@observer
class ForWebViewFloorPlanPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new ForWebViewFloorPlanPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.willUnmount();
  }

  render() {
    return (
      <div className={styles.container} ref={this.vm.containerRef} />
    );
  }
}

ForWebViewFloorPlanPage.propTypes = {};

ForWebViewFloorPlanPage.defaultProps = {};

export default ForWebViewFloorPlanPage;
