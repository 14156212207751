import { EVENT_CODES, ERRORS } from '../../global/constants';
import Group from '../Group';
import GroupDataModel from '../../dataModels/GroupDataModel';
import MenuView from './view';

class Menu {
  isPause = false;
  view = null;
  context = null;

  mapPoint = null;

  get check() {
    return {
      visible: !!this.view.view?.visible
    };
  }

  constructor(context) {
    this.view = new MenuView(this);
    this.context = context;
  }

  init = () => {
    this.view.render();

    this.context.viewport.on('mouseup', this.onViewportMouseup);
    this.context.viewport.on('rightup', this.onViewportRightMouseup);
  };

  open = () => {
    this.view.disabled();

    this.resets();

    this.view.show();
  };

  close = () => {
    this.view.hide();
  };

  resets = () => {
    const hasSelects = this.context.store.check.hasSelects;
    const hasCopies = this.context.store.check.hasCopies;
    const checkGroupMerge = this.context.store.checkGroupMerge;

    // 你選的東西
    const flatSelects = this.context.store.flatSelects;
    const groups = flatSelects.groups;
    const texts = flatSelects.texts;
    const marks = flatSelects.marks;

    // 操作 group 不能選到其他東西
    const invalidGroup = !texts.length && !marks.length;
    // 操作 text 不能選到其他東西
    const invalidTexts = !groups.length && !marks.length;

    if (hasSelects && invalidGroup) {
      this.view.reset('color');
      this.view.reset('clear');
      this.view.reset('copy');
      this.view.reset('remove');
    }

    if (hasSelects && invalidGroup && checkGroupMerge) {
      this.view.reset('merge');
    }

    if (this.mapPoint && hasCopies) {
      this.view.reset('paste');
    }

    if (hasSelects && invalidTexts && texts.length >= 2) {
      this.view.reset('fontSize');
    }
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  // eslint-disable-next-line consistent-return
  onChildRightMouseup = (event) => {
    if (this.isPause || event.buttons !== EVENT_CODES.rightButton) {
      return false;
    }

    const position = this.context.getEventContainerPosition(event);

    this.mapPoint = null;

    this.view.setPosition(position);

    this.open();

    // viewport right up 會關閉 menu
    // 所以沒有 right up 的物件都會觸發 onViewportMouseup
    event.stopPropagation();
  };

  onViewportMouseup = () => {
    this.close();
  };

  // eslint-disable-next-line consistent-return
  onViewportRightMouseup = (event) => {
    if (this.isPause) {
      return false;
    }

    const position = this.context.getEventContainerPosition(event);
    const mapPosition = this.context.getEventMapPosition(event);
    const formatPosition = {
      x: this.context.formatPointToSize(mapPosition.x),
      y: this.context.formatPointToSize(mapPosition.y)
    };

    this.mapPoint = formatPosition;

    this.context.clearSelects();
    // 外部使用, 關閉 drawer
    this.context.events.onSelected();

    this.view.setPosition(position);

    this.open();
  };

  onSetGroupsColor = () => {
    this.context.events.onSetGroupsColor();
    this.close();
  };

  onRemoveAllCompany = () => {
    this.context.events.onCompaniesRemoved();
    this.close();
  };

  onMerge = () => {
    const groups = this.context.store.flatSelects.groups;
    // 紀錄上一步
    const history = {
      before: groups.map((item) => item.get.res),
      after: []
    };
    let x = 0;
    let y = 0;

    groups.forEach((group) => {
      if (group.get.x < x) {
        x = group.get.x;
      }

      if (group.get.y < y) {
        y = group.get.y;
      }
    });

    const newGroup = new Group({
      context: this.context,
      data: new GroupDataModel({ x, y })
    });

    groups.forEach((item) => {
      const companies = Object.values(item.companies);

      item.rects.forEach((rect) => {
        newGroup.merge.addRect(rect);
      });

      companies.forEach((company) => {
        newGroup.merge.addCompany(company);
      });

      if (item.boothNumber) {
        newGroup.merge.addBoothNumber(item.boothNumber);
      }
    });

    // 刪除被合併的攤位
    groups.forEach((item) => item.remove());
    newGroup.addChildToMap();

    // 紀錄合併的結果
    history.after.push(newGroup);

    // 紀錄上一步
    this.context.history.pushes.mergeBooths(history);

    // 通知外部
    this.context.events.onMerged();

    this.close();

    // 合併完成後選取新的攤位
    newGroup.active();
    this.context.store.updateSelects([newGroup]);
    this.context.events.onSelected();
  };

  onCopy = () => {
    const groups = this.context.store.flatSelects.groups.map(
      (group) => group.get.copy
    );

    this.close();

    this.context.store.updateCopies(groups);

    console.log('copy', 'flatCopies', this.context.store.flatCopies);
  };

  // 貼上
  onPaste = () => {
    const copyBasic = this.context.store.copyBasic;
    const rects = this.context.store.flat.rects;
    const copyGroups = this.context.store.flatCopies.groups.map(
      (item) => item.get.copy
    );
    const copyRects = copyGroups.map((item) => item.rects).flat();

    const diffPosition = {
      x: this.mapPoint.x - copyBasic.x,
      y: this.mapPoint.y - copyBasic.y
    };

    // 將所有 copy 的 group 基準改為貼上的位置
    copyGroups.forEach((group) => {
      group.move(diffPosition);
      group.updateBasicPosition();
    });

    const overlap = !!copyRects.find((fakeRect) => {
      const checkOverlapRect = rects.find((rect) =>
        rect.checkOverlap(fakeRect));

      return checkOverlapRect;
    });

    if (overlap) {
      this.context.events.onError(ERRORS['0x003']);
    } else {
      const historyGroups = [];
      copyGroups.forEach((group) => {
        historyGroups.push(group.get.res);
        group.addChildToMap();
      });

      // 紀錄上一步
      this.context.history.pushes.pasteBooths(historyGroups);

      this.context.events.onPasted();

      this.close();
    }
  };

  onRemove = () => {
    this.context.events.onBoothRemoved();
    this.close();
  };

  onFontSize = () => {
    this.context.events.onSetFontSize();
    this.close();
  };
}

export default Menu;
