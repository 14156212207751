import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Switch } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import floorPlanViewModel from '../../viewModels/FloorPlanViewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class SwitchLanguage extends React.Component {
  onChange = (val) => floorPlanViewModel.sets.setFloorPlanLanguage(val);

  render() {
    return (
      <div className={clsx(['switchLanguage', styles.container])}>
        <Switch
          defaultChecked
          checkedChildren={i18n.t(
            'draw_floor_plan_page_switch_language_zh_label'
          )}
          unCheckedChildren={i18n.t(
            'draw_floor_plan_page_switch_language_en_label'
          )}
          onChange={this.onChange}
          disabled={floorPlanViewModel.disabled.language}
        />
      </div>
    );
  }
}

SwitchLanguage.propTypes = {
};

SwitchLanguage.defaultProps = {
};

export default SwitchLanguage;
