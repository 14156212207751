import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Button } from 'antd';
import i18n from 'src/i18n';
import Header from 'src/components/Header';
import ConfirmModal from 'src/components/ConfirmModal';
import FloorPlanTable from './components/FloorPlanTable';
import MapExhibitionPageViewModel from './viewModel';
import CreateFloorPlanModal from './components/CreateFloorPlanModal';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class MapExhibitionPage extends React.Component {
  constructor(props) {
    super(props);
    this.vm = new MapExhibitionPageViewModel(props);
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <div className="papExhibitionPage">
        <Header
          backTo={null}
          title={i18n.t('map_page_title')}
          breadcrumb={[{ title: i18n.t('map_exhi_page_breadcrumb') }]}
        />

        <div className={styles.pageContainer}>
          <div className={styles.pageContent}>
            <div className={styles.searchContainer}>
              <Input.Search
                className={styles.search}
                placeholder={i18n.t('map_page_search_placeholder')}
                value={this.vm.searchKeyword}
                onChange={this.vm.onChangeSearchKeyword}
                onSearch={this.vm.onSearch}
                disabled={this.vm.disabled.search}
                maxLength={50}
              />
            </div>

            <div className={styles.modalButtons}>
              <Button
                type="primary"
                onClick={this.vm.onOpenMerge}
                disabled={this.vm.merge.disabled}
              >
                {i18n.t('map_page_merge_button_label')}
              </Button>

              <Button
                type="primary"
                onClick={this.vm.createVM.open}
                disabled={this.vm.disabled.create}
              >
                {i18n.t('map_page_create_button_label')}
              </Button>
            </div>

            <div className={styles.tableContainer}>
              <FloorPlanTable
                data={this.vm.data}
                editId={this.vm.editId}
                setEditId={this.vm.setEditId}
                onRemoveFloorPlan={this.vm.onRemoveFloorPlan}
                disabled={this.vm.isAwait}
                onScrollEnd={this.vm.onScrollEnd}
              />
            </div>
          </div>
        </div>

        <CreateFloorPlanModal
          vm={this.vm.createVM}
          onCreated={this.vm.onCreated}
        />

        <ConfirmModal
          vm={this.vm.removeModalVM}
          title="確定要刪除嗎?"
          cancelText="取消"
          okText="確認"
          width={300}
          centered
          cancelButtonProps={{
            onClick: this.vm.removeModalVM.close,
            disabled: this.vm.disabled.deleteModal
          }}
          okButtonProps={{
            onClick: this.vm.onRemoveFloorPlanConfirm,
            disabled: this.vm.disabled.deleteModal
          }}
          keyboard={false}
          maskClosable={false}
          closeIcon={false}
        />
      </div>
    );
  }
}

export default MapExhibitionPage;
