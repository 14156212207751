import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';

function FileItem(props) {
  return (
    <div className={styles.container}>
      <div className={styles.group}>
        <div className={styles.fileIconContainer}>
          <PaperClipOutlined className={styles.icon} />
        </div>

        <div className={styles.fileName}>{props.name}</div>
      </div>

      <div className={styles.group}>
        <Button
          type="link"
          size="small"
          className={styles.removeButton}
          onClick={props.onFileRemove}
        >
          <DeleteOutlined className={styles.icon} />
        </Button>
      </div>
    </div>
  );
}

FileItem.propTypes = {
  name: PropTypes.string.isRequired,
  onFileRemove: PropTypes.func.isRequired
};

FileItem.defaultProps = {};

export default FileItem;
