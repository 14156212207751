import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Select, Button } from 'antd';
import { SettingFilled } from '@ant-design/icons';
import i18n from 'src/i18n';
import { FONT_SIZES } from 'src/constants';
import CheckboxTag from '../CheckboxTag';
import styles from './styles.module.scss';

@observer
class Company extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ tagPool: props.tagPool });
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{this.vm.name}</div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t(
              'draw_floor_plan_page_booth_drawer_companies_booth_number_label'
            )}
          </div>

          <div className={styles.form}>
            <Input
              value={this.vm.boothNumber}
              onChange={this.vm.onBoothNumberChange}
              disabled={this.props.disabled}
              maxLength={20}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
            />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t(
              'draw_floor_plan_page_booth_drawer_companies_zh_nickname_label'
            )}
          </div>

          <div className={styles.form}>
            <Select
              className={styles.select}
              value={this.vm.zhNameFontSize}
              onChange={this.vm.onZhNameFontSizeChange}
              options={FONT_SIZES}
              disabled={this.props.disabled}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
            />
          </div>

          <div className={styles.form}>
            <Input.TextArea
              value={this.vm.zhName}
              onChange={this.vm.onZhNameChange}
              disabled={this.props.disabled}
              autoSize
              maxLength={50}
              showCount
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
            />
          </div>
        </div>

        <div className={styles.item}>
          <div className={styles.label}>
            {i18n.t(
              'draw_floor_plan_page_booth_drawer_companies_en_nickname_label'
            )}
          </div>

          <div className={styles.form}>
            <Select
              className={styles.select}
              value={this.vm.enNameFontSize}
              onChange={this.vm.onEnNameFontSizeChange}
              options={FONT_SIZES}
              disabled={this.props.disabled}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
            />
          </div>

          <div className={styles.form}>
            <Input.TextArea
              value={this.vm.enName}
              onChange={this.vm.onEnNameChange}
              disabled={this.props.disabled}
              autoSize
              maxLength={50}
              showCount
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
            />
          </div>
        </div>

        <div className={styles.tags}>
          <div className={styles.label}>
            <div className={styles.text}>
              {i18n.t('draw_floor_plan_page_booth_drawer_tags_label')}
            </div>

            <Button
              type="ghost"
              className={styles.tagsButton}
              onClick={this.props.onEditTagsClick}
              disabled={this.props.disabled}
            >
              <SettingFilled className={styles.icon} />
            </Button>
          </div>

          <div className={styles.content}>
            {this.vm.tagViewModels.map((vm) => (
              <CheckboxTag
                vm={vm}
                disabled={this.props.disabled}
                key={vm.key}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Company.propTypes = {
  vm: PropTypes.object.isRequired,
  onEditTagsClick: PropTypes.func,
  disabled: PropTypes.bool,
  tagPool: PropTypes.array,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Company.defaultProps = {
  onEditTagsClick: () => {},
  disabled: false,
  tagPool: [],
  onFocus: () => {},
  onBlur: () => {}
};

export default Company;
