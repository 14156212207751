import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import statusStore from 'src/stores/status';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import floorPlanViewModel from '../../viewModels/FloorPlanViewModel';

class OperateMenuViewModel {
  @observable fileList = [];
  @observable isAwait = null;
  background = null;
  form = null;

  modalVM = new CustomModalViewModel();

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      modal: this.isAwait
    };
  }

  constructor(props) {
    this.form = props.form;
    makeObservable(this);
  }

  @action onFileChange = (event) => {
    const fileReader = new FileReader();

    fileReader.addEventListener('load', this.onFileLoad);
    fileReader.readAsDataURL(event.file.originFileObj);

    this.isAwait = true;
    this.fileList = [event.file];
    this.form.setFieldValue('background', event.file);
  };

  @action onFileLoad = (event) => {
    const url = event.target.result;

    this.isAwait = false;
    this.background = url;
  };

  @action onAfterClose = () => {
    this.fileList = [];
    this.background = null;
    this.form.setFieldValue('background', null);
  };

  @action onDownloadFloorPlan = async (props) => {
    this.isAwait = true;
    statusStore.setLoading();

    await floorPlanViewModel.downloadFloorPlan(props);

    runInAction(() => {
      this.isAwait = false;
      statusStore.setCompleted();
    });
  };

  @action onOk = async () => {
    this.isAwait = true;

    try {
      console.log('background', this.background);

      await this.form.validateFields();
      await floorPlanViewModel.sets.setFloorPlanBackground({
        background: this.background,
        callback: this.modalVM.close
      });
    } catch (error) {
      console.log('OperateMenuViewModel', 'onOk', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default OperateMenuViewModel;
