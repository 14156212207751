import * as PIXI from 'pixi.js';
import { Viewport } from 'pixi-viewport';
import Store from './stores/store';
import Context from './stores/context';
import {
  LANGUAGES,
  MODES,
  ERRORS,
  DEVICE_PIXEL_RATIO,
  HISTORIES
} from './global/constants';
import Export from './modules/Export';
import Resume from './modules/Resume';
import Pause from './modules/Pause';
import Load from './modules/Load';
import Init from './modules/Init';

class FloorPlan {
  static LANGUAGES = LANGUAGES;
  static MODES = MODES;
  static HISTORIES = HISTORIES;
  static ERRORS = ERRORS;

  resizeObserver = null;
  context = null;

  // 拆模組
  _export = new Export(this);
  _resume = new Resume(this);
  _pause = new Pause(this);
  _init = new Init(this);

  get checkPillars() {
    return this.context.checkPillars;
  }

  get checkPillarsVisible() {
    return this.context.checkPillarsVisible;
  }

  get historiesLength() {
    return this.context.history.historiesLength;
  }

  constructor(props) {
    const observer = new ResizeObserver(this.resize);

    observer.observe(props.parent);

    /**
     * pixi 使用正確的解析度比率
     * viewport 的寬度使用計算後的
     * https://github.com/davidfig/pixi-viewport/issues/326
     */
    const app = new PIXI.Application({
      resizeTo: props.parent,
      background: '#ffffff',
      antialias: true,
      resolution: DEVICE_PIXEL_RATIO
    });

    // 最外層
    const container = new PIXI.Container();

    container.interactive = true;

    // 平面圖
    const map = new PIXI.Container();

    map.sortableChildren = true;

    const viewport = new Viewport({
      screenWidth: props.parent.clientWidth / DEVICE_PIXEL_RATIO,
      screenHeight: props.parent.clientHeight / DEVICE_PIXEL_RATIO,
      worldWidth: 6000,
      worldHeight: 3000,
      events: app.renderer.events,
      divWheel: app.view,
      passiveWheel: false,
      stopPropagation: true,
      disableOnContextMenu: true
    });

    viewport.addChild(map);
    container.addChild(viewport);

    app.stage.addChild(container);

    const store = new Store();

    store.setLanguage(props.language);

    const context = new Context({
      parent: props.parent,
      app,
      container,
      viewport,
      map,
      store,
      pause: this._pause.pause,
      resume: this._resume.resume
    });

    this.resizeObserver = observer;
    this.context = context;

    this._init.init();
    this._init.modules(props);
  }

  setKeyValue = (key, val) => {
    this[key] = val;
  };

  resize = () => {
    const width = this.context.parent.clientWidth;
    const height = this.context.parent.clientHeight;

    this.context.app.resize(width, height);

    this.context.resizeViewport.resize();
  };

  addEventListener = (type, func) => {
    this.context.events.addEventListener(type, func);
  };

  removeEventListener = (type) => {
    this.context.events.removeEventListener(type);
  };

  destroy = () => {
    this.resizeObserver.disconnect();

    this.context.store.setLanguage(LANGUAGES.zh);

    this.context.container.destroy(true);
    this.context.app.destroy(true, true);

    this.context.createGroup.destroy();

    this.context.dragMapControl.destroy();
    this.context.dragObject.destroy();

    this.context.select.destroy();
    this.context.readSelect.destroy();
  };

  /**
   * 使用資料畫地圖
   * @param {object} props
   * @param {element} props.parent
   * @param {string} props.data
   * @returns {floorPlan}
   */
  static load = async (props) => {
    const _load = new Load(FloorPlan);
    const floorPlan = await _load.load(props);
    return floorPlan;
  };

  static readLoad = async (props) => {
    const _load = new Load(FloorPlan);
    const floorPlan = await _load.readLoad(props);
    return floorPlan;
  };

  // 合併地圖
  static mergeFloorPlan = (data = []) => {
    return Context.mergeFloorPlan(data);
  };

  // 合併柱子
  static mergePillars = (data, pillars) => {
    return Context.mergePillars(data, pillars);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  setLanguage = (lan) => {
    this.context.sets.setLanguage(lan);
  };

  // 背景圖

  setBackground = async (src) => {
    await this.context.setBackground(src);
  };

  updateBackground = async (src) => {
    await this.context.updateBackground(src);
  };

  exportData = () => {
    return this._export.exportData();
  };

  downloadJPG = async (props) => {
    await this._export.downloadJPG(props);
  };

  downloadSVG = async (props) => {
    await this._export.downloadSVG(props);
  };

  // 取消選取
  clearSelects = () => {
    this.context.clearSelects();
  };

  // 畫攤位的顏色

  setRectColor = (val) => {
    this.context.sets.setRectColor(val);
  };

  clearRectColor = () => {
    this.context.deletes.clearRectColor();
  };

  // 參展商

  setStamp = () => {
    this.context.setStamp();
  };

  clearStamp = () => {
    this.context.clearStamp();
  };

  showPillars = () => {
    this.context.showPillars();
  };

  hidePillars = () => {
    this.context.hidePillars();
  };

  setCompany = (groupId, data) => {
    this.context.sets.setCompany(groupId, data);
  };

  removeCompany = (groupId, companyId) => {
    this.context.deletes.removeCompany(groupId, companyId);
  };

  // 更新所有文字內容
  setTextContentById = (data, lang) => {
    this.context.sets.setLanguage(lang);
    this.context.sets.setTextContentById(data, lang);
  };

  // 更新所有地標圖片
  setMarkIconById = async (data) => {
    await this.context.sets.setMarkIconById(data);
  };

  getLanguage = () => {
    return this.context.store.language;
  };

  getGroups = () => {
    return this.context.getGroups();
  };

  getCompanies = () => {
    return this.context.getCompanies();
  };

  static getCompaniesByData = (data) => {
    return Context.getCompaniesByData(data);
  };

  // 攤位編號

  setBoothNumber = (groupId, data) => {
    this.context.sets.setBoothNumber(groupId, data);
  };

  setBoothNumberById = (groupId, content) => {
    this.context.sets.setBoothNumberById(groupId, content);
  };

  removeBoothNumber = (groupId) => {
    this.context.deletes.removeBoothNumber(groupId);
  };

  // 攤位的顏色

  setBoothColor = (groupId, color) => {
    this.context.sets.setBoothColor(groupId, color);
  };

  setBoothsColor = (groups, color) => {
    this.context.sets.setBoothsColor(groups, color);
  };


  // 調整文字的字型大小
  // text 是 floorPlan 的 class
  setTextsFontSize = (texts, fontSize) => {
    this.context.sets.setTextsFontSize(texts, fontSize);
  };

  // 地標

  setMarkIcon = async (groupId, data) => {
    await this.context.sets.setMarkIcon(groupId, data);
  };

  setMarkText = (groupId, data) => {
    this.context.sets.setMarkText(groupId, data);
  };

  removeMarkIcon = (groupId) => {
    this.context.deletes.removeMarkIcon(groupId);
  };

  removeMarkText = (groupId) => {
    this.context.deletes.removeMarkText(groupId);
  };

  removeMark = (groupId) => {
    this.context.deletes.removeMark(groupId);
  };

  // 專區名字

  setAreaName = (groupId, data) => {
    this.context.sets.setAreaName(groupId, data);
  };

  removeAreaName = (groupId) => {
    this.context.deletes.removeAreaName(groupId);
  };

  setBoothDetail = async ({ groupId, detail, history, type }) => {
    await this.context.sets.setBoothDetail({ groupId, detail, history, type });
  };

  // 導航地圖

  moveToGroup = async (groupId) => {
    await this.context.moveToGroup(groupId);
  };

  moveToCenter = async (time) => {
    await this.context.moveToCenter(time);
  };

  // 搜尋地圖

  searchGroupByAnything = (ids) => {
    return this.context.searchGroupByAnything(ids);
  };

  // 編輯模式, 需要暫停和啟用選取

  pause = (type) => {
    this._pause.pause(type);
  };

  resume = (type) => {
    this._resume.resume(type);
  };

  // 上一步
  getLastHistory = () => {
    return this.context.history.backs.getLastHistory();
  };

  back = () => {
    this.context.history.backs.back();
  };
}

export default FloorPlan;
