class RectDataModel {
  constructor(props) {
    this.x = props.x || 0;
    this.y = props.y || 0;
    this.width = props.width || 100;
    this.height = props.height || 100;
    this.tint = props.tint || '#000000';
  }
}

export default RectDataModel;
