import { v4 } from 'uuid';
import { HISTORIES } from '../../../global/constants';

class Pushes {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  /**
   * 新增攤位
   * @param {object} group
   */
  createBooth = (group) => {
    const data = {
      id: v4(),
      type: HISTORIES.createBooth,
      before: [],
      after: [group]
    };

    this.parent.push(data);
  };

  /**
   * 移動
   * @param {array} before
   * @param {array} after
   */
  move = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.move,
      before,
      after
    };

    this.parent.push(data);
  };

  /**
   * 攤位顏色
   * @param {array} before
   * @param {array} after
   */
  setBoothsColor = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.setBoothsColor,
      before,
      after
    };

    this.parent.push(data);
  };

  /**
   * 字型大小
   * @param {array} before
   * @param {array} after
   */
  setTextsFontSize = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.setTextsFontSize,
      before,
      after
    };

    this.parent.push(data);
  };

  /**
   * 貼上攤位
   * @param {array} groups
   */
  pasteBooths = (groups) => {
    const data = {
      id: v4(),
      type: HISTORIES.pasteBooths,
      before: [],
      after: groups
    };

    this.parent.push(data);
  };

  /**
   * 刪除參展商
   * @param {array} before
   * @param {array} after
   */
  removeCompanies = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.removeCompanies,
      before,
      after
    };

    this.parent.push(data);
  };

  /**
   * 刪除攤位
   * @param {array} before
   * @param {array} after
   */
  removeBooths = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.removeBooths,
      before,
      after
    };

    this.parent.push(data);
  };

  /**
   * 合併攤位
   * @param {array} before
   * @param {array} after
   */
  mergeBooths = ({ before, after }) => {
    const data = {
      id: v4(),
      type: HISTORIES.mergeBooths,
      before,
      after
    };

    this.parent.push(data);
  };

  // 通用版
  detail = ({ before, after, type }) => {
    const data = {
      id: v4(),
      type,
      before,
      after
    };

    this.parent.push(data);
  };
}

export default Pushes;
