import * as PIXI from 'pixi.js';
import {
  OBJECT_BORDER_WIDTH,
  COLORS,
  OBJECT_ZINDEXS
} from '../../global/constants';
import Rect from '../Rect';
import Mark from '../Mark';
import Text from '../Text';

class Render {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  // 畫邊框或是 highline
  updateBorder = () => {
    const lines = this.parent.get.borderLines;

    this.parent.normalBorder.clear();
    this.parent.normalBorder.lineStyle(
      OBJECT_BORDER_WIDTH.normal,
      COLORS.normal.border
    );

    this.parent.activeBorder.clear();
    this.parent.activeBorder.lineStyle(
      OBJECT_BORDER_WIDTH.active,
      COLORS.active.border
    );

    lines.forEach((key) => {
      const line = JSON.parse(key);

      this.parent.normalBorder.moveTo(line.x1, line.y1);
      this.parent.normalBorder.lineTo(line.x2, line.y2);

      this.parent.activeBorder.moveTo(line.x1, line.y1);
      this.parent.activeBorder.lineTo(line.x2, line.y2);
    });

    this.parent.normalBorder.endFill();
    this.parent.activeBorder.endFill();
  };

  renderView = (data) => {
    const view = new PIXI.Container();
    const basicPosition = { x: data.x, y: data.y };

    view.x = data.x;
    view.y = data.y;

    view.cullable = true;

    view.zIndex = OBJECT_ZINDEXS.group.normal;

    view.sortableChildren = true;

    this.parent.setView(view);
    this.parent.setBasicPosition(basicPosition);
  };

  renderRects = (data) => {
    const rects = data.map((item) => {
      const rect = new Rect({
        group: this.parent,
        context: this.parent.context,
        data: item
      });

      rect.addChildToGroup();

      return rect;
    });

    this.parent.setRects(rects);
  };

  renderBorder = () => {
    const normalBorder = new PIXI.Graphics();
    const activeBorder = new PIXI.Graphics();

    normalBorder.visible = true;
    activeBorder.visible = false;

    normalBorder.zIndex = OBJECT_ZINDEXS.border;
    activeBorder.zIndex = OBJECT_ZINDEXS.border;

    this.parent.view.addChild(normalBorder);
    this.parent.view.addChild(activeBorder);

    this.parent.setNormalBorder(normalBorder);
    this.parent.setActiveBorder(activeBorder);

    this.updateBorder();
  };

  renderCompanies = (data) => {
    const newCompanies = {};

    Object.keys(data).forEach((companyId) => {
      const company = data[companyId];
      const newCompany = {};

      Object.keys(company).forEach((lang) => {
        const text = new Text({
          group: this.parent,
          context: this.parent.context,
          data: company[lang]
        });

        newCompany[lang] = text;
      });

      newCompanies[companyId] = newCompany;
    });

    this.parent.setCompanies(newCompanies);
  };

  renderBoothNumber = (data) => {
    if (data) {
      const boothNumber = new Text({
        group: this.parent,
        context: this.parent.context,
        data
      });

      this.parent.setBoothNumber(boothNumber);
    }
  };

  renderMarkText = (data) => {
    const markText = {};

    Object.keys(data).forEach((lang) => {
      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data: data[lang]
      });

      markText[lang] = text;
    });

    this.parent.setMarkText(markText);
  };

  renderMarkIcon = (data) => {
    if (data) {
      const icon = Mark.forInactive({
        group: this.parent,
        context: this.parent.context,
        data
      });

      this.parent.setMarkIcon(icon);
    }
  };

  renderAreaName = (data) => {
    const areaName = {};

    Object.keys(data).forEach((lang) => {
      const text = new Text({
        group: this.parent,
        context: this.parent.context,
        data: data[lang]
      });

      areaName[lang] = text;
    });

    this.parent.setAreaName(areaName);
  };
}

export default Render;
