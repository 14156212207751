import * as PIXI from 'pixi.js';
import { SVGScene } from '@pixi-essentials/svg';
import { PILLAR_TYPES } from 'src/constants/pillars';
import { OBJECT_ZINDEXS } from '../../global/constants';

class Pillar {
  view = null;
  context = null;

  // 哪種柱子
  type = null;

  get res() {
    return {
      x: this.view.x,
      y: this.view.y,
      type: this.type,
      visible: this.view.visible
    };
  }

  get resForSVG() {
    return {
      src: PILLAR_TYPES[this.type].src,
      x: this.view.x,
      y: this.view.y,
      visible: this.view.visible
    };
  }

  constructor(props) {
    // 舊的地圖沒有 type
    this.type = props.data.type || PILLAR_TYPES.type1.value;
    this.context = props.context;
  }

  init = async (data) => {
    const svg = await SVGScene.from(PILLAR_TYPES[this.type].src);
    const texture = this.context.app.renderer.generateTexture(svg);
    const icon = new PIXI.Sprite(texture);

    icon.x = data.x;
    icon.y = data.y;

    icon.zIndex = OBJECT_ZINDEXS.pillar;

    icon.cullable = true;

    // 這是新的欄位, 而且地圖已經上 pro, 所以需要判斷
    icon.visible = !!data.visible;

    this.view = icon;

    svg.destroy();
  };

  show = () => {
    this.view.visible = true;
  };

  hide = () => {
    this.view.visible = false;
  };

  addChildToMap = () => {
    const pillars = this.context.store.data.pillars.slice();

    this.context.map.addChild(this.view);
    this.context.store.updatePillars([...pillars, this]);
  };

  static render = async (props) => {
    const pillar = new Pillar(props);

    await pillar.init(props.data);

    return pillar;
  };
}

export default Pillar;
