import * as PIXI from 'pixi.js';
import MARKS from 'src/constants/marks';
import { LANGUAGES } from '../../global/constants';

class Sets {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  setLanguage = (lan) => {
    this.parent.store.setLanguage(lan);
    this.parent.store.flat.groups.forEach((item) => item.switchLanguage());
  };

  setRectColor = (val) => {
    this.parent.store.setRectColor(val);

    this.parent.clearStamp();
    this.parent.closeMenu();
  };

  setCompany = (groupId, data) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.set.setCompany(data);
    } else {
      console.log('setCompany', '沒有找到 group');
    }
  };

  /**
   * 為了紀錄上一步, 需要將設置集中, 用於蓋章, BoothDrawer, OtherDrawer
   * dat 範例
   * {
   *  areaName: {},
   *  companies: {},
   *  boothNumber: {},
   *  markText: {},
   *  markIcon: {}
   * }
   */
  // eslint-disable-next-line consistent-return
  setBoothDetail = async ({ groupId, detail, history = false, type }) => {
    const group = this.parent.getGroup(groupId);
    const detailHistory = {
      before: [group.get.res],
      after: [],
      type
    };

    if (!group) {
      console.log('setBoothDetail', '沒有找到 group');
      return false;
    }

    if (detail.areaName) {
      Object.values(detail.areaName).forEach((lang) =>
        group.set.setAreaName(lang));
    } else {
      group.delete.removeAreaName();
    }

    if (detail.companies) {
      Object.values(detail.companies || {}).forEach((company) =>
        Object.values(company).forEach((lang) => group.set.setCompany(lang)));
    }

    if (detail.boothNumber) {
      group.set.setBoothNumber(detail.boothNumber);
    } else {
      group.delete.removeBoothNumber();
    }

    if (detail.markText) {
      Object.values(detail.markText).forEach((lang) =>
        group.set.setMarkText(lang));
    } else {
      group.delete.removeMarkText();
    }

    if (detail.markIcon) {
      await group.set.setMarkIcon(detail.markIcon);
    } else {
      group.delete.removeMarkIcon();
    }

    detailHistory.after.push(group.get.res);

    // 有可能不需要紀錄歷史紀錄, 例如自動更新
    if (history) {
      this.parent.history.pushes.detail(detailHistory);
    }
  };

  /**
   * 設定所有文字
   * @param {string} id
   * @param {object[]} data
   * @param {string} zhName
   * @param {string} zhName
   */
  setTextContentById = (data, lang) => {
    const texts = this.parent.store.flat.texts;

    this.parent.store.setLanguage(lang);

    data.forEach((item) => {
      const filterTexts = texts.filter((text) => text.id === String(item.id));

      filterTexts.forEach((text) => {
        switch (text.language) {
          case LANGUAGES.zh:
            text.updateContent(item.zhName);
            break;

          case LANGUAGES.en:
            text.updateContent(item.enName);
            break;

          default:
            break;
        }
      });
    });
  };

  // 調整多個文字的字型大小
  // text 是 floorPlan 的 class
  setTextsFontSize = (texts = [], fontSize = 14) => {
    const history = {
      before: [],
      after: []
    };
    // texts 的 group 可能是同一個
    const groups = Array.from(new Set(texts.map((text) => text.group)));

    const beforeRes = groups.map((group) => group.get.res);

    history.before.push(...beforeRes);

    texts.forEach((text) => text.updateFontSize(fontSize));

    const afterRes = groups.map((group) => group.get.res);

    history.after.push(...afterRes);

    this.parent.history.pushes.setTextsFontSize(history);
  };

  // 更新所有圖片地標
  setMarkIconById = async (props = []) => {
    const marks = this.parent.store.flat.marks;
    const promises = [];

    props.forEach((item) => {
      const filterMarks = marks.filter((mark) => mark.id === item.id);

      filterMarks.forEach((mark) => {
        const searchId = `${item.zhName},${item.enName}`;
        const data = { id: mark.id, searchId, src: item.src };

        promises.push(mark.update(data));
      });
    });

    await Promise.all(promises);
  };

  // 攤位編號

  setBoothNumber = (groupId, data) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.set.setBoothNumber(data);
    } else {
      console.log('setBoothNumber', '沒有找到 group');
    }
  };

  // 只改攤位編號的文字
  setBoothNumberById = (groupId, content) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.set.setBoothNumberById(content);
    } else {
      console.log('setBoothNumber', '沒有找到 group');
    }
  };

  // 攤位的顏色

  setBoothColor = (groupId, color) => {
    const group = this.parent.getGroup(groupId);
    const isEdit = this.parent.store.check.isEdit;

    if (group && isEdit) {
      group.set.setBoothColor(color);
    } else if (group && !isEdit) {
      group.set.setBoothHighlight(color);
    } else {
      console.log('setBoothColor', '沒有找到 group');
    }
  };

  setBoothsColor = (groups = [], color = '#ffffff') => {
    const res = {
      before: [],
      after: []
    };

    groups.forEach((item) => {
      const group = this.parent.getGroup(item.id);
      const isEdit = this.parent.store.check.isEdit;

      if (group && isEdit) {
        res.before.push(group.get.res);

        group.set.setBoothColor(color);

        res.after.push(group.get.res);
      } else if (group && !isEdit) {
        group.set.setBoothHighlight(color);
      } else {
        console.log('setBoothColor', '沒有找到 group');
      }
    });

    this.parent.history.pushes.setBoothsColor(res);
  };

  // 地標

  setMarkText = (groupId, data) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.set.setMarkText(data);
    } else {
      console.log('setMarkText', '沒有找到 group');
    }
  };

  setMarkIcon = async (groupId, data) => {
    const group = this.parent.getGroup(groupId);
    const mark = MARKS[data.id];
    const searchId = `${mark.zhName},${mark.enName}`;

    if (group) {
      await group.set.setMarkIcon({
        id: data.id,
        searchId,
        src: mark.src
      });
    } else {
      console.log('setMarkText', '沒有找到 group');
    }
  };

  // 專區名字
  setAreaName = (groupId, data) => {
    const group = this.parent.getGroup(groupId);

    if (group) {
      group.set.setAreaName(data);
    } else {
      console.log('setAreaName', '沒有找到 group');
    }
  };
}

export default Sets;
