import axios from 'axios';
import * as PIXI from 'pixi.js';

export const svgMethods = {
  getSVG: (data) => {
    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');

    if (data?.width) {
      svg.setAttribute('width', data.width);
    }

    if (data?.height) {
      svg.setAttribute('height', data.height);
    }

    if (data?.x) {
      svg.setAttribute('x', data.x);
    }

    if (data?.y) {
      svg.setAttribute('y', data.y);
    }

    return svg;
  },

  getRect: (data) => {
    const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

    rect.setAttribute('x', String(data.x));
    rect.setAttribute('y', String(data.y));
    rect.setAttribute('width', String(data.width));
    rect.setAttribute('height', String(data.height));
    rect.setAttribute('fill', data.tint);

    return rect;
  },

  getTspan: (data) => {
    const tspan = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'tspan'
    );

    tspan.setAttribute('x', String(data.x));
    tspan.setAttribute('y', String(data.y));

    tspan.innerHTML = data.content;

    return tspan;
  },

  getText: (data) => {
    const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');

    text.setAttribute('x', String(data.x));
    text.setAttribute('y', String(data.y));
    text.setAttribute('font-size', String(data.fontSize));
    text.setAttribute('font-family', String(data.fontFamily));
    text.setAttribute('fill', data.fill);

    // 保留空白
    text.setAttribute('xml:space', 'preserve');

    data.content.split('\n').forEach((item, i) => {
      const y = data.y + i * (data.fontSize * 1.2);
      const tspanSVG = svgMethods.getTspan({ content: item, x: data.x, y });

      text.appendChild(tspanSVG);
    });

    return text;
  },

  getPath: (lines) => {
    const path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    const mapLines = lines.map((key) => {
      const line = JSON.parse(key);

      return `M${line.x1},${line.y1} ${line.x2},${line.y2}z`;
    });
    const joinLines = mapLines.join(' ');

    path.setAttribute('d', joinLines);

    return path;
  },

  getImageXml: async (src) => {
    const res = await axios.get(src);
    const parser = new DOMParser();
    const doc = parser.parseFromString(res.data, 'image/svg+xml');
    const svg = doc.querySelector('svg');

    const group = document.createElementNS('http://www.w3.org/2000/svg', 'g');
    const children = Array.from(svg.children);

    children.forEach((child) => {
      svg.removeChild(child);
      group.appendChild(child);
    });

    svg.appendChild(group);

    return svg;
  },

  getImage: (src) => {
    const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');

    image.setAttribute('href', src);

    return image;
  }
};

export function pickTextColorBasedOnBgColorAdvanced(
  bgColor,
  lightColor,
  darkColor
) {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor;

  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB

  const uiColors = [r / 255, g / 255, b / 255];

  const c = uiColors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return ((col + 0.055) / 1.055) ** 2.4;
  });

  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];

  return L > 0.179 ? darkColor : lightColor;
}

const ELLIPSIS = '...';

export function truncWithEllipsis(text, style, maxWidth) {
  const chars = text.split('');
  const metrics = PIXI.TextMetrics.measureText(
    `${ELLIPSIS}\n${chars.join('\n')}`,
    style
  );
  const [ellipsisWidth, ...charWidths] = metrics.lineWidths;
  const { str: truncated, overflow } = charWidths.reduce(
    (data, w, i) => {
      if (data.width + w + ellipsisWidth >= maxWidth) {
        return { ...data, width: maxWidth, overflow: true };
      }

      return {
        str: data.str + (chars[i] ?? ''),
        width: data.width + w,
        overflow: false
      };
    },
    { str: '', width: 0, overflow: false }
  );
  return truncated + (overflow ? ELLIPSIS : '');
}
