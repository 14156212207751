import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { ROUTES } from 'src/constants';
import ExhibitionService from 'src/services/exhibition';
import statusStore from 'src/stores/status';

export default class AnnouncementExhibitionPageViewModel {
  @observable exhibitionList = [];
  @observable page = 1;
  @observable totalPages = 0;

  @observable selectedStatus = 'soon';
  @observable selectedCountry = 'all';

  @observable sortBy = 'dateDesc';

  @observable search = '';
  @observable router = null;

  // for test use.
  @observable trigger = false;

  constructor() {
    makeObservable(this);
  }

  @action didMount = async (router) => {
    if (this.trigger) {
      return;
    }

    if (!this.router) {
      this.updateSelect(router);
    }
    this.trigger = true;
    this.exhibitionList = [];
    await this.getExhibition();
  };

  @action getExhibition = async () => {
    const params = {
      // ...(this.anchor && { anchor: this.anchor }),
      Type: this.selectedStatus,
      Page: this.page,
      ...(this.selectedCountry !== 'all' && { CountryCode: this.selectedCountry }),
      ...(this.search.trim() && { Keyword: this.search }),
      ...(this.sortBy === 'dateDesc' ? { Sort: 'DESC' } : { Sort: 'ASC' })
    };
    try {
      statusStore.setLoading();
      const { exhibitions, totalPages } = await ExhibitionService.getExhibitions(params);
      runInAction(() => {
        this.exhibitionList = [...this.exhibitionList, ...exhibitions];
        this.totalPages = totalPages;
        this.page += 1;
      });
    } catch (error) {
      console.log(error);
    } finally {
      statusStore.setCompleted();
    }
  };

  @action updateParams = () => {
    const params = new URLSearchParams({
      status: this.selectedStatus,
      ...((this.selectedCountry && this.selectedCountry !== 'all') && { country: this.selectedCountry }),
      ...(this.search.trim() && { keyword: this.search })
    });

    this.router.setSearchParams(params);
  };

  @action updateSelect = (router) => {
    this.router = router;
    const { searchParams } = this.router;
    const status = searchParams.get('status');
    const country = searchParams.get('country');
    const keyword = searchParams.get('keyword');

    this.selectedStatus = status ?? 'soon';
    this.selectedCountry = country ?? 'all';
    this.search = keyword ?? '';

  };

  @action onStatusSelect = (e) => {
    this.selectedStatus = e;

    this.updateParams();

    this.page = 1;
    this.totalPages = 0;
    this.exhibitionList = [];

    this.getExhibition();
  };

  @action onCountrySelect = (e) => {
    this.selectedCountry = e;

    this.updateParams();

    this.page = 1;
    this.totalPages = 0;
    this.exhibitionList = [];

    this.getExhibition();
  };

  @action onSearchChange = (e) => {
    this.search = e.target.value;
  };

  @action onSearch = async () => {
    this.updateParams();

    this.page = 1;
    this.totalPages = 0;
    this.exhibitionList = [];

    this.getExhibition();
  };

  @action onSortChange = async () => {
    if (this.sortBy === 'dateDesc') {
      this.sortBy = 'dateAsc';
    } else {
      this.sortBy = 'dateDesc';
    }

    this.page = 1;
    this.totalPages = 0;
    this.exhibitionList = [];

    statusStore.setLoading();
    await this.getExhibition();
    statusStore.setCompleted();
  };
}
