/* eslint-disable */
export default {
  "ID": "zh-TW",
  "": "",
  "common_select_option_all": "全部",
  "common_btn_delete": "刪除",
  "common_btn_confirm": "確定",
  "common_btn_cancel": "取消",
  "common_menu_announcement": "公告設定",
  "common_menu_map": "平面圖繪製",
  "login_title": "登入 Login",
  "login_email_auth_required": "請輸入你的帳號",
  "login_email_auth_illegal": "不合法的電子信箱，請重新確認！",
  "login_password_auth_required": "請輸入你的密碼",
  "login_submit": "登入",
  "logout_title": "登出Logout",
  "logout_description": "確定要登出嗎？",
  "ann_page_title": "公告設定",
  "ann_exhi_page_breadcrumb": "專案公告設定",
  "ann_exhi_page_title": "選擇展覽",
  "ann_exhi_page_sub_title": "針對特定展覽的參展用戶發布公告",
  "ann_exhi_status_select_title": "展覽狀態",
  "ann_exhi_status_select_placeholder": "請選擇展覽狀態",
  "ann_exhi_status_option_ongoing": "進行中",
  "ann_exhi_status_option_upcoming": "即將開始",
  "ann_exhi_status_option_over": "已結束",
  "ann_exhi_country_select_title": "展覽國家",
  "ann_exhi_country_select_placeholder": "請選擇展覽國家",
  "ann_exhi_search_placeholder": "搜尋展覽名稱、展覽代碼",
  "ann_exhi_table_column_code": "展覽代碼",
  "ann_exhi_table_column_name": "展覽名稱",
  "ann_exhi_table_column_time": "展覽時間",
  "ann_exhi_table_column_country": "國家",
  "ann_exhi_table_column_place": "地點",
  "ann_exhi_table_column_manipulate": "操作",
  "ann_exhi_table_ann_detail": "公告詳情",
  "ann_status_select_title": "公告狀態",
  "ann_status_select_placeholder": "請選擇公告狀態",
  "ann_status_select_option_sent": "已發布",
  "ann_status_select_option_unsend": "未發佈",
  "ann_status_add": "新增公告",
  "ann_table_column_name": "公告名稱",
  "ann_table_column_receiver": "公告對象",
  "ann_table_receiver_participant": "當日入場用戶",
  "ann_table_receiver_hasTicket": "所有已索票用戶",
  "ann_table_receiver_interesting": "感興趣的用戶",
  "ann_table_column_language": "公告語系",
  "ann_language_chinese": "中文",
  "ann_language_english": "英文",
  "ann_table_column_time": "公告時間",
  "ann_table_column_status": "公告狀態",
  "ann_table_column_manipulate": "操作",
  "ann_table_manipulate_edit": "編輯",
  "ann_table_manipulate_delete": "刪除",
  "ann_table_manipulate_view": "查看",
  "ann_table_manipulate_active": "上架",
  "ann_table_manipulate_inactive": "下架",
  "ann_drawer_title": "參展用戶公告",
  "ann_drawer_name_title": "公告名稱",
  "ann_drawer_name_placeholder": "請輸入公告名稱",
  "ann_drawer_receiver_title": "公告對象",
  "ann_drawer_receiver_placeholder": "請選擇公告對象",
  "ann_drawer_language_title": "公告語系",
  "ann_drawer_time_title": "公告時間",
  "ann_drawer_time_placeholder": "請選擇公告時間",
  "ann_drawer_content_title": "公告內容",
  "ann_drawer_content_placeholder": "請輸入公告內容",
  "ann_delete_confirm_title": "確定要刪除嗎？",
  "ann_inactive_confirm_title": "確定要下架嗎？",
  "ann_active_confirm_title": "確定要上架嗎？",
  "map_page_title": "平面圖繪製",
  "map_exhi_page_breadcrumb": "平面圖繪製",
  "map_page_search_placeholder": "搜尋展覽名稱、平面圖名稱",
  "map_page_create_button_label": "新增平面圖",
  "map_page_merge_button_label": "合併平面圖",
  "map_page_table_choose_title": "選擇",
  "map_page_table_shelve_title": "上架",
  "map_page_table_zh_name_title": "平面圖中文名稱",
  "map_page_table_en_name_title": "平面圖英文名稱",
  "map_page_table_exhibition_title": "所屬展覽",
  "map_page_table_search_tag_title": "搜尋標籤",
  "map_page_table_operate_title": "操作",
  "map_page_table_operate_edit_button_label": "編輯",
  "map_page_table_operate_draw_button_label": "繪製",
  "map_page_table_operate_remove_button_label": "刪除",
  "map_page_table_operate_save_button_label": "儲存",
  "map_page_merge_floor_plan_group_overlapping_error": "操作失敗，有攤位重疊",
  "map_page_merge_floor_plan_success": "合併成功",
  "map_page_create_modal_title": "新增平面圖",
  "map_page_create_modal_merge_title": "合併平面圖",
  "map_page_create_modal_zh_name_label": "平面圖中文名稱",
  "map_page_create_modal_zh_name_placeholder": "請輸入",
  "map_page_create_modal_zh_name_required": "請輸入",
  "map_page_create_modal_en_name_label": "平面圖英文名稱",
  "map_page_create_modal_en_name_placeholder": "請輸入",
  "map_page_create_modal_en_name_required": "請輸入",
  "map_page_create_modal_exhibition_label": "展覽選擇",
  "map_page_create_modal_exhibition_placeholder": "請輸入",
  "map_page_create_modal_exhibition_required": "請輸入",
  "map_page_create_modal_template_label": "模板選擇",
  "map_page_create_modal_template_placeholder": "請輸入",
  "map_page_create_modal_template_required": "請輸入",
  "map_page_create_modal_upload_background_upload_title": "上傳自訂模板",
  "map_page_create_modal_upload_background_upload_subtitle": "點擊或將您的jpg圖檔拖曳到此處",
  "map_page_create_modal_upload_background_required": "請輸入",
  "draw_floor_plan_page_operate_menu_update_background_label": "更換模板",
  "draw_floor_plan_page_operate_menu_update_background_sub_label": "自訂底圖",
  "draw_floor_plan_page_operate_menu_update_background_modal_title": "上傳自訂底圖",
  "draw_floor_plan_page_operate_menu_update_background_modal_upload_title": "更換模板上傳自訂底圖",
  "draw_floor_plan_page_operate_menu_update_background_modal_upload_subtitle": "點擊或將您的jpg圖檔拖曳到此處",
  "draw_floor_plan_page_operate_menu_update_background_modal_required": "請輸入",
  "draw_floor_plan_page_operate_menu_update_download_label": "下載平面圖",
  "draw_floor_plan_page_operate_menu_update_download_sub_label_1": "下載 中文版 jpg",
  "draw_floor_plan_page_operate_menu_update_download_sub_label_2": "下載 中文版 svg",
  "draw_floor_plan_page_operate_menu_update_download_sub_label_3": "下載 英文版 jpg",
  "draw_floor_plan_page_operate_menu_update_download_sub_label_4": "下載 英文版 svg",
  "draw_floor_plan_page_back_button_label": "上一步",
  "draw_floor_plan_page_switch_language_zh_label": "TW",
  "draw_floor_plan_page_switch_language_en_label": "EN",
  "draw_floor_plan_page_create_booth_label": "新增攤位",
  "draw_floor_plan_page_company_button_label": "參展商、活動定位",
  "draw_floor_plan_page_company_drawer_title": "參展商、活動列表",
  "draw_floor_plan_page_company_drawer_exhibition_select_label": "展覽名稱 ：",
  "draw_floor_plan_page_company_drawer_companies_sort_label": "排序方式 ：",
  "draw_floor_plan_page_company_drawer_companies_sort_reg_label": "報名表序號",
  "draw_floor_plan_page_company_drawer_companies_sort_booth_number_label": "攤位號碼",
  "draw_floor_plan_page_company_drawer_companies_sort_booth_size_label": "攤位大小",
  "draw_floor_plan_page_company_drawer_companies_sort_area_label": "展區 ：",
  "draw_floor_plan_page_company_drawer_companies_sort_area_placeholder": "請選擇展區",
  "draw_floor_plan_page_company_drawer_companies_sort_map_id_label": "未定位",
  "draw_floor_plan_page_booth_tag_button_label": "攤位標籤設定",
  "draw_floor_plan_page_booth_tag_modal_title": "攤位標籤設定",
  "draw_floor_plan_page_booth_tag_modal_fixed_tag_label": "常用標籤 ：",
  "draw_floor_plan_page_booth_tag_modal_custom_tag_label": "自訂標籤 ：",
  "draw_floor_plan_page_booth_tag_modal_create_tag_label": "新增標籤",
  "draw_floor_plan_page_booth_tag_modal_confirm_button_label": "確定",
  "draw_floor_plan_page_booth_drawer_title": "攤位設定",
  "draw_floor_plan_page_booth_drawer_booth_color_label": "攤位顏色",
  "draw_floor_plan_page_booth_drawer_area_label": "專區",
  "draw_floor_plan_page_booth_drawer_area_zh_name_label": "中文名稱 ：",
  "draw_floor_plan_page_booth_drawer_area_en_name_label": "英文名稱 ：",
  "draw_floor_plan_page_booth_drawer_companies_label": "參展商",
  "draw_floor_plan_page_booth_drawer_booth_number_font_size_label": "攤位編號字型大小",
  "draw_floor_plan_page_booth_drawer_companies_booth_number_label": "攤位編號",
  "draw_floor_plan_page_booth_drawer_companies_zh_nickname_label": "中文簡稱 ：",
  "draw_floor_plan_page_booth_drawer_companies_en_nickname_label": "英文簡稱 ：",
  "draw_floor_plan_page_booth_drawer_confirm_button_label": "儲存",
  "draw_floor_plan_page_booth_drawer_tags_label": "標籤",
  "draw_floor_plan_page_other_drawer_title": "其他地標設定",
  "draw_floor_plan_page_other_drawer_common_mark_label": "常見地標",
  "draw_floor_plan_page_other_drawer_custom_mark_label": "自訂地標",
  "draw_floor_plan_page_other_drawer_custom_mark_zh_name_label": "名稱-中",
  "draw_floor_plan_page_other_drawer_custom_mark_en_name_label": "名稱-英",
  "draw_floor_plan_page_other_drawer_booth_number_label": "攤位號碼",
  "draw_floor_plan_page_other_drawer_booth_color_label": "顏色",
  "draw_floor_plan_page_other_drawer_confirm_button_label": "儲存",
  "draw_floor_plan_page_pillars_switch_label": "柱子顯示：",
  "draw_floor_plan_page_pillars_switch_checked_label": "ON",
  "draw_floor_plan_page_pillars_switch_unchecked_label": "OFF",
  "draw_floor_plan_page_font_size_modal_title": "字型大小設定",
  "draw_floor_plan_page_font_size_modal_select_label": "字型大小：",
  "draw_floor_plan_page_font_size_modal_select_placeholder": "請選擇",
  "draw_floor_plan_page_font_size_modal_confirm_button_label": "儲存",
  "draw_floor_plan_page_color_picker_modal_title": "攤位顏色設定",
  "draw_floor_plan_page_color_picker_modal_confirm_button_label": "儲存",
  "draw_floor_plan_page_color_picker_modal_cancel_button_label": "取消",
  "login_error_data_error": "資料有誤，請重新確認！",
  "login_error_status_404": "帳號或密碼錯誤，請重新確認！",
  "login_error_status_general": "登入異常，請稍後重新嘗試！",
  "draw_floor_plan_page_error_on_stamped_booth_number_incompatible": "攤位號碼不符，請確認後再進行定位",
  "draw_floor_plan_page_error_on_paste_overlapping": "操作失敗，有重疊物件",
  "draw_floor_plan_page_error_on_create_group_overlapping": "操作失敗，有重疊物件",
  "draw_floor_plan_page_error_on_move_overlapping": "操作失敗，有重疊物件",
  "draw_floor_plan_page_error_on_stamped_company_already_exists": "操作失敗，此參展商已存在",
  "draw_floor_plan_page_error_back_fail": "操作失敗，請檢查網路或稍後再試"
};
