import React from 'react';
import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { v4 } from 'uuid';
import { delay } from 'src/utils';
import FloorPlanService from 'src/services/floorPlanService';

class CreateTagViewModel {
  mapId = null;

  @observable name = '';
  @observable isEdit = false;
  @observable isAwait = false;

  inputRef = React.createRef();

  onCustomTagCreated = null;

  @computed
  get disabled() {
    return { input: this.isAwait };
  }

  @computed
  get show() {
    return {
      input: this.isEdit,
      tag: !this.isEdit
    };
  }

  constructor(props) {
    this.mapId = props.router.params.id;
    this.onCustomTagCreated = props.onCustomTagCreated;
    makeObservable(this);
  }

  init = ({ mapId }) => {
    this.mapId = mapId;
  };

  @action onTagClick = () => {
    this.isEdit = true;
    setTimeout(this.inputRef.current.focus, 0);
  };

  @action onNameChange = (event) => {
    this.name = event.target.value;
  };

  @action onPressEnter = () => {
    if (this.name?.trim()) {
      this.postMapCustomTagAPI();
    }
  };

  @action onBlur = () => {
    this.name = '';
    this.isEdit = false;
  };

  @action postMapCustomTagAPI = async () => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.postMapCustomTag({
        mapId: this.mapId,
        name: this.name
      });

      this.onCustomTagCreated({ id: res.data.id, name: res.data.name });

      runInAction(() => {
        this.name = '';
      });
    } catch (error) {
      console.log('CreateTagViewModel', 'postMapCustomTag', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CreateTagViewModel;
