import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Layout, Menu, Tooltip, Modal, Button } from 'antd';
import i18n from 'src/i18n';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { Outlet } from 'react-router-dom';

import Logo from 'src/assets/logo.svg';
import UserIcon from 'src/assets/userIcon.svg';
import LogoutIcon from 'src/assets/logout.svg';

import Loading from 'src/components/Loading';
import withRouter from 'src/components/withRouter';

import styles from './styles.module.scss';

import ViewModel from './viewModel';

@observer
class HomePage extends React.Component {

  constructor(props) {
    super();

    this.viewModel = new ViewModel(props.router);
  }

  componentDidUpdate() {
    this.viewModel.didUpdate();
  }

  render() {
    return (
      <>
        <Layout className={styles.pageContainer}>
          <Layout.Sider
            className={styles.sider}
            collapsed={this.viewModel.isCollapse}
            width={240}
            collapsedWidth={60}
            collapsible
            trigger={null}

          >
            <div className={styles.innerItem}>
              <div className={clsx(styles.menuHeader, this.viewModel.isCollapse && styles.center)}>
                <img src={Logo} alt="" className={clsx(styles.logo, this.viewModel.isCollapse && styles.hide)} />
                {
                  this.viewModel.isCollapse
                    ? (
                      <MenuUnfoldOutlined
                        className={styles.icon}
                        onClick={this.viewModel.toggleCollapse}
                      />
                    )
                    : (
                      <MenuFoldOutlined
                        className={styles.icon}
                        onClick={this.viewModel.toggleCollapse}
                      />
                    )
                }
              </div>
              <Menu
                mode="inline"
                className="menu"
                style={{ border: 'none' }}
                items={this.viewModel.filterMenu}
                onClick={this.viewModel.onMenuClick}
                selectedKeys={this.viewModel.selectedKeys}
              />
              <div className={styles.footer}>
                {
                  this.viewModel.isCollapse
                    ? (
                      <Tooltip
                        title={this.viewModel.profile.name}
                        placement="right"
                      >
                        <div className={clsx(styles.accountInfo, styles.center)}>
                          <img src={UserIcon} alt="" className={styles.userIcon} />
                        </div>
                      </Tooltip>
                    )
                    : (
                      <div className={clsx(styles.accountInfo)}>
                        <img src={UserIcon} alt="" className={styles.userIcon} />
                        <div className={styles.userName}>
                          {this.viewModel.profile.name}
                        </div>
                      </div>
                    )
                }
                {
                  this.viewModel.isCollapse
                    ? (
                      <Tooltip
                        title={i18n.t('logout_title')}
                        placement="right"
                      >
                        <div
                          className={clsx(
                            styles.logoutBtn,
                            styles.center
                          )}
                          onClick={this.viewModel.onLogoutClick}
                        >
                          <img src={LogoutIcon} alt="" className={styles.logoutIcon} />
                        </div>
                      </Tooltip>

                    )
                    : (
                      <div
                        className={clsx(styles.logoutBtn)}
                        onClick={this.viewModel.onLogoutClick}
                      >
                        <img src={LogoutIcon} alt="" className={styles.logoutIcon} />
                        <div className={styles.logoutText}>
                          {i18n.t('logout_title')}
                        </div>
                      </div>
                    )
                }
              </div>
            </div>
          </Layout.Sider>
          <Layout.Content
            className={styles.content}
          >
            <Outlet />
          </Layout.Content>
          <Loading />
        </Layout>
        <Modal
          open={this.viewModel.isLogoutModalOpen}
          onCancel={this.viewModel.onLogoutCancel}
          centered
          footer={null}
          closable={false}
          width={400}
        >
          <div className={styles.modalContentContainer}>
            <div className={styles.description}>
              {i18n.t('logout_description')}
            </div>
            <div className={styles.actions}>
              <Button
                className={clsx(styles.btn, styles.cancel)}
                onClick={this.viewModel.onLogoutCancel}
              >
                {i18n.t('common_btn_cancel')}
              </Button>
              <Button
                className={clsx(styles.btn, styles.confirm)}
                onClick={this.viewModel.onLogout}
                type="primary"
              >
                {i18n.t('common_btn_confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

HomePage.propTypes = {
  router: PropTypes.object
};

HomePage.defaultProps = {
  router: {}
};

export default withRouter(HomePage);
