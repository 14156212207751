import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import styles from './styles.module.scss';

@observer
class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    const { vm, children, ...ps } = this.props;

    return (
      <Modal open={this.vm.isOpen} {...ps}>
        {children}
      </Modal>
    );
  }
}

ConfirmModal.propTypes = {
  vm: PropTypes.object.isRequired,
  children: PropTypes.node
};

ConfirmModal.defaultProps = {
  children: null
};

export default ConfirmModal;
