import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { FONT_SIZES } from 'src/constants';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';

class FontSizeModalViewModel {
  texts = [];
  @observable fontSize = null;
  @observable isAwait = false;

  modalVM = new CustomModalViewModel();

  floorPlanVM = null;

  @computed
  get disabled() {
    return {
      confirm: !this.fontSize || this.isAwait
    };
  }

  @computed
  get fontSizes() {
    return FONT_SIZES;
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  @action open = (texts) => {
    this.texts = texts;
    this.fontSize = null;

    this.modalVM.open();
  };

  close = () => {
    this.modalVM.close();
  };

  @action onFontSizeChange = (val) => {
    this.fontSize = val;
  };

  onConfirm = async () => {
    this.floorPlanVM.sets.setLoading();

    this.floorPlanVM.sets.setFloorPlanTextsFontSize(this.texts, this.fontSize);

    await this.floorPlanVM.updateFloorPlanAPI();

    this.modalVM.close();

    this.floorPlanVM.sets.setCompleted();
  };
}

export default FontSizeModalViewModel;
