import dayjs from 'dayjs';
import { RECEIVER_TYPE_TC, LANGUAGE_TYPE_TC } from 'src/constants';

export default class AnnouncementModel {
  constructor(data) {
    const {
      id,
      name,
      receiverType,
      language,
      sendTime,
      isActive,
      isSent
    } = data;

    this.id = id;
    this.name = name;
    this.language = LANGUAGE_TYPE_TC[language];
    this.receiverType = RECEIVER_TYPE_TC[receiverType];
    this.sendTime = dayjs(sendTime).format('YYYY-MM-DD HH:mm');
    this.isActive = isActive;
    this.isSent = isSent;
  }

  static fromRes(data) {
    return new AnnouncementModel(data);
  }
}
