import * as PIXI from 'pixi.js';
import { SVGScene } from '@pixi-essentials/svg';
import MARKS from 'src/constants/marks';
import {
  COLORS,
  OBJECT_BORDER_WIDTH,
  OBJECT_TYPES,
  OBJECT_ZINDEXS
} from '../../global/constants';

class Mark {
  id = null;
  searchId = null;
  type = null;

  view = null;
  icon = null;

  src = null;

  group = null;

  context = null;

  // 相對定位, 拖移使用
  translate = { x: 0, y: 0 };
  basicTranslate = { x: 0, y: 0 };

  isActive = false;

  // 計算 group 後, 的絕對定位
  get position() {
    return {
      x: this.group.view.x + this.translate.x,
      y: this.group.view.y + this.translate.y
    };
  }

  get res() {
    return {
      id: this.id,
      searchId: this.searchId,
      type: OBJECT_TYPES.markIcon,
      translateX: this.translate.x,
      translateY: this.translate.y
    };
  }

  get resForSVG() {
    return {
      src: this.src,
      x: this.position.x,
      y: this.position.y
    };
  }

  // DragObject 使用
  get validMove() {
    return (
      this.translate.x !== this.basicTranslate.x
      || this.translate.y !== this.basicTranslate.y
    );
  }

  static forActive = async (props) => {
    const mark = new Mark(props);
    await mark.renderIcon(props.data);
    return mark;
  };

  static forInactive = (props) => {
    const mark = new Mark(props);
    mark.renderIcon(props.data);
    return mark;
  };

  constructor(props) {
    this.group = props.group;
    this.context = props.context;
    this.init(props);
  }

  init = async (props) => {
    this.id = props.data.id;
    this.searchId = props.data.searchId;
    this.type = props.data.type;

    this.renderView(props);
    this.renderBorder();
  };

  // 被選中
  active = () => {
    if (this.context.store.check.isEdit) {
      this.border.visible = true;
    }

    this.isActive = true;
  };

  // 取消選取
  inactive = () => {
    if (this.context.store.check.isEdit) {
      this.border.visible = false;
    }

    this.isActive = false;
  };

  search = (id) => {
    return this.searchId.includes(id);
  };

  update = async (data) => {
    this.id = data.id;
    this.searchId = data.searchId;
    await this.renderIcon(data);
    this.updateBorder();
  };

  updateBorder = () => {
    this.border.clear();

    this.border.lineStyle(OBJECT_BORDER_WIDTH.active, COLORS.active.border);

    if (this.icon) {
      const x = this.icon.x;
      const y = this.icon.y;
      const width = this.icon.width;
      const height = this.icon.height;

      this.border.drawRect(x, y, width, height);
    }
  };

  move = (diff) => {
    this.translate = {
      x: this.basicTranslate.x + diff.x,
      y: this.basicTranslate.y + diff.y
    };

    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  followMove = () => {
    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  // 回到原位
  resetTranslate = () => {
    this.translate = {
      x: this.basicTranslate.x,
      y: this.basicTranslate.y
    };

    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  // 更新原本的位置
  updateBasicTranslate = () => {
    this.basicTranslate = {
      x: this.translate.x,
      y: this.translate.y
    };
  };

  addChildToMap = () => {
    this.context.map.addChild(this.view);
  };

  removeChildToMap = () => {
    this.context.map.removeChild(this.view);

    this.view.destroy(true);
  };

  onMousedown = (event) => {
    this.context.onMousedownFromDragObject(event, this);
    this.context.closeMenu();
  };

  onMouseup = (event) => {
    this.context.onMouseupFromSelect(event, this);
    this.context.onMouseupFromStamp(event, this);
  };

  renderView = (props) => {
    const view = new PIXI.Container();

    this.translate = { x: props.data.translateX, y: props.data.translateY };
    this.basicTranslate = {
      x: props.data.translateX,
      y: props.data.translateY
    };

    view.x = this.position.x;
    view.y = this.position.y;

    view.cullable = true;

    view.zIndex = OBJECT_ZINDEXS.icon.normal;

    this.view = view;
  };

  renderBorder = () => {
    const border = new PIXI.Graphics();

    border.visible = false;

    this.view.addChild(border);

    this.border = border;
  };

  renderIcon = async (data) => {
    try {
      this.icon?.destroy(true);

      const src = MARKS[data.id].src;
      const icon = await SVGScene.from(src);

      this.src = src;

      if (this.context.store.check.isEdit) {
        icon.interactive = true;
        icon.hitArea = new PIXI.Rectangle(0, 0, icon.width, icon.height);

        icon.on('mousedown', this.onMousedown);
        icon.on('mouseup', this.onMouseup);
      }

      this.view.addChild(icon);
      this.view.removeChild(this.icon);

      this.icon = icon;

      this.updateBorder();
    } catch (error) {
      console.log('FloorPlan', 'Mark', 'renderIcon');
    }
  };
}

export default Mark;
