import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';

class PillarsSwitchViewModel {
  // 這張地圖有沒有柱子
  @observable show = false;
  @observable checked = true;
  @observable isAwait = false;

  floorPlanViewModel = null;

  @computed
  get disabled() {
    return {
      switch: this.isAwait
    };
  }

  constructor(props) {
    this.floorPlanViewModel = props;
    makeObservable(this);
  }

  // switch 的初始值
  @action init = (val) => {
    this.checked = val;
  };

  @action setShow = (val) => {
    this.show = val;
  };

  @action onCheckedChange = async (val) => {
    this.isAwait = true;
    this.checked = val;

    if (val) {
      this.floorPlanViewModel.showPillars();
    } else {
      this.floorPlanViewModel.hidePillars();
    }

    await this.floorPlanViewModel.updateFloorPlanAPI();

    runInAction(() => {
      this.isAwait = false;
    });
  };
}

export default PillarsSwitchViewModel;
