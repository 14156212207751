import i18n from 'src/i18n';

export const ANNOUNCEMENT_EXHIBITION_TABLE = [
  {
    title: i18n.t('ann_exhi_table_column_code'),
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: i18n.t('ann_exhi_table_column_name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('ann_exhi_table_column_time'),
    dataIndex: 'time',
    key: 'time'
  },
  {
    title: i18n.t('ann_exhi_table_column_country'),
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: i18n.t('ann_exhi_table_column_place'),
    dataIndex: 'place',
    key: 'place'
  },
  {
    title: i18n.t('ann_exhi_table_column_manipulate'),
    dataIndex: 'manipulate',
    key: 'manipulate'
  }
];

export const ANNOUNCEMENT_TABLE = [
  {
    title: i18n.t('ann_table_column_name'),
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: i18n.t('ann_table_column_receiver'),
    dataIndex: 'receiverType',
    key: 'receiverType'
  },
  {
    title: i18n.t('ann_table_column_language'),
    dataIndex: 'language',
    key: 'language'
  },
  {
    title: i18n.t('ann_table_column_time'),
    dataIndex: 'sendTime',
    key: 'sendTime'
  },
  {
    title: i18n.t('ann_table_column_status'),
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: i18n.t('ann_exhi_table_column_manipulate'),
    dataIndex: 'manipulate',
    key: 'manipulate'
  }
];


