import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Popover, Button, ColorPicker } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import arrow from 'src/assets/draw_floor_plan_arrow.svg';
import floorPlanViewModel from '../../viewModels/FloorPlanViewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CreateBooth extends React.Component {
  constructor(props) {
    super(props);
    this.vm = floorPlanViewModel.createBoothVM;
  }

  componentDidMount() {
    this.vm.didMount();
  }

  componentWillUnmount() {
    this.vm.unmount();
  }

  renderPopoverContent = () => {
    return (
      <div className={styles.popoverContent}>
        {this.vm.colors.map((item) => {
          return (
            <Button
              className={clsx([styles.button, styles.rect])}
              style={{ backgroundColor: item }}
              key={item}
              onClick={this.vm.onRectClick}
              data-color={item}
            />
          );
        })}

        <div className={styles.rect}>
          <ColorPicker
            overlayClassName="createBoothColorPicker"
            placement="bottom"
            trigger="click"
            value={this.vm.pickColor}
            onChange={this.vm.onColorPickerChange}
            disabledAlpha
          >
            <Button
              className={styles.colorPickerButton}
              style={{ backgroundColor: this.vm.pickColor }}
              onClick={this.vm.onColorPickerClick}
            />
          </ColorPicker>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className={styles.container}>
        <Popover
          overlayClassName="createBoothPopover"
          content={this.renderPopoverContent()}
          placement="bottom"
          trigger="click"
        >
          <Button
            type="ghost"
            className={clsx([
              styles.button,
              floorPlanViewModel.check.isCreateBooth && styles.active
            ])}
            onClick={floorPlanViewModel.onCreateBoothClick}
          >
            <div className={styles.text}>
              {i18n.t('draw_floor_plan_page_create_booth_label')}
            </div>

            <div
              className={styles.rect}
              style={{ backgroundColor: this.vm.color }}
            />

            <img className={styles.arrow} src={arrow} alt="arrow" />
          </Button>
        </Popover>
      </div>
    );
  }
}

CreateBooth.propTypes = {};

CreateBooth.defaultProps = {};

export default CreateBooth;
