import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import { PILLARS } from 'src/constants/pillars';
import statusStore from 'src/stores/status';
import ExhibitionService from 'src/services/exhibition';
import FloorPlanService from 'src/services/floorPlanService';
import CustomModalViewModel from 'src/components/CustomModal/viewModel';
import FloorPlan from 'src/components/FloorPlan';
import i18n from 'src/i18n';

const CUSTOM_TEMPLATE = {
  value: 'custom',
  label: '自訂模板'
};

class CreateFloorPlanModalViewModel {
  @observable exhibitions = [];
  @observable templates = [];
  @observable template = null;
  @observable fileList = [];

  @observable isMerge = false;
  @observable isAwait = false;

  mergeMapId = null;
  mergeMap = null;
  background = null;

  modalVM = new CustomModalViewModel();

  form = null;

  onCreated = null;

  @computed
  get title() {
    return this.isMerge
      ? i18n.t('map_page_create_modal_merge_title')
      : i18n.t('map_page_create_modal_title');
  }

  @computed
  get check() {
    return {
      hasUpload: this.template === CUSTOM_TEMPLATE.value
    };
  }

  @computed
  get disabled() {
    return {
      form: this.isAwait,
      cancelDisabled: this.isAwait,
      ok: this.isAwait,
      exhibitions: this.isAwait || this.isMerge
    };
  }

  @computed
  get mapExhibitions() {
    return this.exhibitions.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
  }

  @computed
  get mapTemplates() {
    const templates = this.templates.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
    return [...templates, CUSTOM_TEMPLATE];
  }

  constructor(props) {
    makeObservable(this);
  }

  init = (props) => {
    this.form = props.form;
    this.onCreated = props.onCreated;
  };

  @action didMount = async () => {
    if (!this.isAwait) {
      this.isAwait = true;

      await Promise.all([
        this.getExhibitionListAPI(),
        this.getMapTemplateListAPI()
      ]);

      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  open = () => this.modalVM.open();

  openMerge = async (props = []) => {
    statusStore.setLoading();

    try {
      const data = await Promise.all(
        props.map((item) => {
          return this.getMapDetailAPI(item);
        })
      );
      const mapExhibitions = data
        .map((item) => item.exhibitions)
        .flat()
        .map((item) => item.id);
      const exhibitions = Array.from(new Set(mapExhibitions));

      runInAction(() => {
        const map = FloorPlan.mergeFloorPlan(data);

        this.isMerge = true;
        this.mergeMap = map;

        this.form.setFieldValue('exhibitions', exhibitions);

        this.modalVM.open();

        console.log('合併檢查', '地圖', map);
        console.log('合併檢查', '展覽', exhibitions);
      });
    } catch (error) {
      console.log('getMapDetailAPI', error);
    } finally {
      statusStore.setCompleted();
    }
  };

  onOk = () => {
    if (this.isMerge) {
      this.mergeCreateAPI();
    } else {
      this.createAPI();
    }
  };

  onFilterOption = (val, item) => item.label.includes(val);

  @action onAfterClose = () => {
    this.template = null;
    this.fileList = [];

    this.mergeMapId = null;
    this.mergeMap = null;
    this.background = null;

    this.isMerge = false;

    this.form.resetFields();
  };

  @action onTemplateChange = (val) => {
    this.template = val;

    console.log('onTemplateChange', val);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 上傳背景圖

  beforeUpload = () => {};
  customRequest = () => {};

  @action onUploadChange = (event) => {
    const file = [event.file];
    const targetFile = new FileReader();

    targetFile.addEventListener('load', this.onloadFile);
    targetFile.readAsDataURL(event.file.originFileObj);

    this.isAwait = true;
    this.fileList = file;
    this.form.setFieldValue('background', file);
  };

  @action onloadFile = async (event) => {
    const url = event.target.result;

    this.isAwait = false;
    this.background = url;
  };

  @action onFileRemove = () => {
    this.fileList = [];
    this.background = null;

    this.form.setFieldValue('background', null);
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 新增地圖

  getFromData = async () => {
    const data = await this.form.validateFields();
    const background = this.background || undefined;
    const template
      = data.template === CUSTOM_TEMPLATE.value ? undefined : data.template;

    // 如果是合併地圖, template 永遠是 undefined, background 可能是模板或上傳

    return {
      zhName: data.zhName,
      enName: data.enName,
      exhibitions: data.exhibitions,
      template,
      background
    };
  };

  getExhibitionListAPI = async () => {
    try {
      const res = await ExhibitionService.getExhibitionList();

      runInAction(() => {
        this.exhibitions = res.data;
      });
    } catch (error) {
      console.log(
        'CreateFloorPlanModalViewModel',
        'getExhibitionListAPI',
        error
      );
    }
  };

  getMapTemplateListAPI = async () => {
    try {
      const res = await FloorPlanService.getMapTemplateList();

      runInAction(() => {
        this.templates = res.data;
      });
    } catch (error) {
      console.log(
        'CreateFloorPlanModalViewModel',
        'getMapTemplateListAPI',
        error
      );
    }
  };

  @action createAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getFromData();
      const res = await FloorPlanService.postMapCreate(data);

      this.onCreated();
      this.modalVM.close();

      console.log('postMapCreateAPI', res.data);
    } catch (error) {
      console.log('CreateFloorPlanModalViewModel', 'postMapCreateAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------

  // 合併的地圖

  // template 永遠是 undefined, background 可能是模板或上傳
  getMergeFromData = async () => {
    const data = await this.form.validateFields();
    const template = this.templates.find((item) => item.id === data.template);
    const background = template?.background || this.background;

    return {
      zhName: data.zhName,
      enName: data.enName,
      exhibitions: data.exhibitions,
      background
    };
  };

  getMapDetailAPI = async (id) => {
    try {
      const res = await FloorPlanService.getMapDetail({ id });

      return res.data;
    } catch (error) {
      console.log('CreateFloorPlanModalViewModel', 'getMapDetailAPI', error);

      return null;
    }
  };

  @action mergeCreateAPI = async () => {
    this.isAwait = true;

    try {
      const data = await this.getMergeFromData();
      const res = await FloorPlanService.postMapCreate(data);

      this.mergeMapId = res.data.id;

      this.updateFloorPlanAPI();

      console.log('postMapCreateAPI', res.data);
    } catch (error) {
      console.log('CreateFloorPlanModalViewModel', 'postMapCreateAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新合併地圖的資料
  updateFloorPlanAPI = async () => {
    try {
      let map = null;

      // 自訂背景圖沒有柱子
      if (this.template === CUSTOM_TEMPLATE.value) {
        map = this.mergeMap;
      } else {
        // 模板的柱子
        const pillars = PILLARS[this.template];

        map = FloorPlan.mergePillars(this.mergeMap, pillars);
      }

      const res = await FloorPlanService.putMapUpdate({
        id: this.mergeMapId,
        map
      });

      this.onCreated();
      this.modalVM.close();
    } catch (error) {
      console.log('CreateFloorPlanModalViewModel', 'putMapUpdate', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default CreateFloorPlanModalViewModel;
