import { makeObservable, observable, computed, action } from 'mobx';
import { FLOOR_PLAN_DEFAULT_FONT_SIZES } from 'src/constants';

class AreaNameViewModel {
  @observable zhNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.area.zh;
  @observable zhName = '';

  @observable enNameFontSize = FLOOR_PLAN_DEFAULT_FONT_SIZES.area.en;
  @observable enName = '';

  @computed
  get valid() {
    return !!this.zhName.trim() || !!this.enName.trim();
  }

  @computed
  get res() {
    return {
      id: `${this.zhName}&${this.enName}`,
      zhNameFontSize: Number(this.zhNameFontSize),
      zhName: this.zhName.trim(),
      enNameFontSize: Number(this.enNameFontSize),
      enName: this.enName.trim()
    };
  }

  constructor(props) {
    makeObservable(this);
  }

  @action setData = (data) => {
    this.zhName = data?.zhName || '';
    this.enName = data?.enName || '';

    this.zhNameFontSize = String(
      data?.zhNameFontSize || FLOOR_PLAN_DEFAULT_FONT_SIZES.area.zh
    );
    this.enNameFontSize = String(
      data?.enNameFontSize || FLOOR_PLAN_DEFAULT_FONT_SIZES.area.en
    );
  };

  @action onZhNameFontSizeChange = (val) => {
    this.zhNameFontSize = val;
  };

  @action onZhNameChange = (event) => {
    this.zhName = event.target.value;
  };

  @action onEnNameFontSizeChange = (val) => {
    this.enNameFontSize = val;
  };

  @action onEnNameChange = (event) => {
    this.enName = event.target.value;
  };
}

export default AreaNameViewModel;
