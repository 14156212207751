import { ERRORS, OBJECT_TYPES } from '../global/constants';

// 蓋章

class Stamp {
  isActive = false;
  isStamp = null;
  clientDown = null;

  isDown = false;
  isPause = false;

  context = null;

  constructor(context) {
    this.context = context;
  }

  init = () => {
    this.isActive = true;
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  setStamp = () => {
    this.isStamp = true;

    this.context.pause('dragObject');
    this.context.pause('select');
  };

  clearStamp = () => {
    this.isStamp = false;

    this.context.resume('dragObject');
    this.context.resume('select');
  };

  // group children
  // eslint-disable-next-line consistent-return
  onMouseup = (event, target) => {
    if (!this.isActive || this.isPause || !this.isStamp) {
      return false;
    }

    this.context.events.onStamped(target.group);
  };
}

export default Stamp;
