import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import statusStore from 'src/stores/status';
import MARKS from 'src/constants/marks';
import FloorPlanService from 'src/services/floorPlanService';
import ExhibitionService from 'src/services/exhibition';
import FloorPlan from 'src/components/FloorPlan';
import Events from './Events';
import Sets from './Sets';
import Removes from './Removes';
import CompanyDrawerViewModel from '../../components/CompanyDrawer/viewModel';
import BoothDrawerViewModel from '../../components/BoothDrawer/viewModel';
import BoothTagModalViewModel from '../../components/BoothTagModal/viewModel';
import OtherDrawerViewModel from '../../components/OtherDrawer/viewModel';
import CreateBoothViewModel from '../../components/CreateBooth/viewModel';
import ColorPickerModalViewModel from '../../components/ColorPickerModal/viewModel';
import PillarsSwitchViewModel from '../../components/PillarsSwitch/viewModel';
import FontSizeModalViewModel from '../../components/FontSizeModal/viewModel';

const operateTypes = {
  createBooth: 'createBooth',
  companyDrawer: 'companyDrawer'
};

class FloorPlanViewModel {
  id = null;

  @observable operateType = null;

  // 平面圖
  floorPlan = null;
  LANGUAGES = FloorPlan.LANGUAGES;

  // 要蓋章的參展商
  @observable company = null;

  // 標籤池
  @observable tagPool = [];

  // 蓋章時跟隨滑鼠的參展商
  @observable isMouseenter = false;
  @observable followCompanyLeft = 0;
  @observable followCompanyTop = 0;

  // 歷史紀錄
  @observable historiesLength = 0;

  /**
   * 蓋章完成時, 會觸發 selected, 然後開啟 booth drawer, 但如果在同一個攤位, 會因為上面的條件而不更新 booth drawer, 所以需要一個新的開關
   */
  isStamped = false;

  // view models
  createBoothVM = null;
  // 參展商列表
  companyDrawerVM = null;
  // 攤位設定
  boothDrawerVM = null;
  // 標籤設定
  boothTagModalVM = null;
  // 地標設定
  otherDrawerVM = null;
  // 顏色彈窗
  colorPickerModalVM = null;
  // 柱子切換
  pillarsSwitchVM = null;
  // 文字大小
  fontSizeModalVM = null;

  // 拆模組
  events = new Events(this);
  sets = new Sets(this);
  removes = new Removes(this);

  updateTimeout = null;

  // 蓋章時跟隨滑鼠的參展商
  @computed
  get followCompanyName() {
    return this.company?.name || '';
  }

  @computed
  get followCompanyStyle() {
    return {
      left: this.followCompanyLeft,
      top: this.followCompanyTop
    };
  }

  @computed
  get showFollowCompanyName() {
    return this.isMouseenter && !!this.company;
  }

  @computed
  get disabled() {
    return {
      language: this.isAwait,
      backButton: !this.historiesLength
    };
  }

  @computed
  get check() {
    return {
      isCreateBooth: this.operateType === operateTypes.createBooth,
      isCompanyDrawer: this.operateType === operateTypes.companyDrawer
    };
  }

  @computed
  get mapTagPool() {
    return this.tagPool.map((item) => {
      return { id: item.id, name: item.name };
    });
  }

  get companies() {
    return this.floorPlan.getCompanies();
  }

  constructor() {
    makeObservable(this);
  }

  init = () => {
    // view models
    this.createBoothVM = new CreateBoothViewModel(this);
    // 參展商列表
    this.companyDrawerVM = new CompanyDrawerViewModel(this);
    // 攤位設定
    this.boothDrawerVM = new BoothDrawerViewModel(this);
    // 標籤設定
    this.boothTagModalVM = new BoothTagModalViewModel(this);
    // 地標設定
    this.otherDrawerVM = new OtherDrawerViewModel(this);
    // 顏色彈窗
    this.colorPickerModalVM = new ColorPickerModalViewModel(this);
    // 柱子切換
    this.pillarsSwitchVM = new PillarsSwitchViewModel(this);
    // 文字大小
    this.fontSizeModalVM = new FontSizeModalViewModel(this);
  };

  @action reset = () => {
    this.floorPlan?.destroy();

    this.id = null;

    this.operateType = null;

    this.floorPlan = null;

    this.company = null;

    this.tagPool = [];

    this.isAwait = false;

    this.isMouseenter = false;

    this.isStamped = false;

    // view models
    this.createBoothVM = null;
    // 參展商列表
    this.companyDrawerVM = null;
    // 攤位設定
    this.boothDrawerVM = null;
    // 標籤設定
    this.boothTagModalVM = null;
    // 地標設定
    this.otherDrawerVM = null;
    // 顏色彈窗
    this.colorPickerModalVM = null;
    // 柱子切換
    this.pillarsSwitchVM = null;
  };

  @action load = async (data, parent) => {
    const floorPlan = await FloorPlan.load({
      parent,
      map: data.map,
      background: data.background,
      mode: FloorPlan.MODES.edit,
      language: FloorPlan.LANGUAGES.zh
    });

    // 柱子
    this.pillarsSwitchVM.setShow(floorPlan.checkPillars);
    this.pillarsSwitchVM.init(floorPlan.checkPillarsVisible);

    floorPlan.addEventListener('created', this.events.onCreated);
    floorPlan.addEventListener('selected', this.events.onSelected);
    floorPlan.addEventListener(
      'companiesRemoved',
      this.events.onBoothCompaniesRemoved
    );
    floorPlan.addEventListener(
      'boothRemoved',
      this.events.onBoothCompaniesRemoved
    );
    floorPlan.addEventListener('merged', this.events.onMerged);
    floorPlan.addEventListener('stamped', this.events.onStamped);
    floorPlan.addEventListener('moved', this.events.onMoved);
    floorPlan.addEventListener('pasted', this.events.onPasted);
    floorPlan.addEventListener('setGroupsColor', this.events.onSetGroupsColor);
    floorPlan.addEventListener('setFontSize', this.events.onSetFontSize);
    floorPlan.addEventListener('error', this.events.onError);

    this.id = data.id;
    this.data = data;
    this.floorPlan = floorPlan;

    await this.refreshFloorPlan();
  };

  @action setTagPool = (val) => {
    this.tagPool = val;
    this.boothDrawerVM.updatedTagPool();
  };

  @action setCompany = (val) => {
    this.company = val;
  };

  @action setOperateTypeCreateBooth = () => {
    this.operateType = operateTypes.createBooth;
    this.floorPlan.clearSelects();
  };

  @action setMouseenter = (val) => {
    this.isMouseenter = val;
  };

  @action setFollowCompanyLeft = (val) => {
    this.followCompanyLeft = val;
  };

  @action setFollowCompanyTop = (val) => {
    this.followCompanyTop = val;
  };

  @action getHistoriesLength = () => {
    this.historiesLength = this.floorPlan.historiesLength;

    console.log('historiesLength', this.historiesLength);
  };

  setStamped = (val) => {
    this.isStamped = val;
  };

  // 工具列

  @action onCreateBoothClick = () => {
    if (this.operateType === operateTypes.createBooth) {
      this.clearSwitch('create');
    }
  };

  @action onCompanyDrawerOpen = () => {
    if (this.operateType === operateTypes.companyDrawer) {
      this.clearSwitch('stamp');
    } else {
      this.clearSwitch('create');
      this.clearSwitch('booth');
      this.clearSwitch('other');

      this.operateType = operateTypes.companyDrawer;

      this.floorPlan.clearSelects();

      this.companyDrawerVM.open();
    }
  };

  onCompanyDrawerClose = () => {
    this.clearSwitch('stamp');
  };

  /**
   * 下載地圖
   * 需要先更新地圖
   */
  downloadFloorPlan = async (props) => {
    statusStore.setLoading();

    const keys = props.key.split('-');
    const language = keys[0];
    const type = keys[1];

    await this.refreshFloorPlan();

    switch (type) {
      case 'jpg': {
        await this.floorPlan.downloadJPG({ name: this.data.name, language });
        break;
      }

      case 'svg': {
        await this.floorPlan.downloadSVG({ name: this.data.name, language });
        break;
      }

      default:
        break;
    }

    statusStore.setCompleted();

    console.log('下載', language, type);
  };

  @action clearSwitch = (type) => {
    switch (type) {
      case 'create': {
        if (this.operateType === operateTypes.createBooth) {
          this.operateType = null;
        }

        this.floorPlan.clearRectColor();
        break;
      }

      case 'stamp': {
        if (this.operateType === operateTypes.companyDrawer) {
          this.operateType = null;
        }

        this.company = null;
        this.floorPlan.clearStamp();
        this.boothDrawerVM.close();
        this.companyDrawerVM.close();
        break;
      }

      case 'other': {
        this.otherDrawerVM.close();
        break;
      }

      case 'booth': {
        this.boothDrawerVM.close();
        break;
      }

      default:
        break;
    }
  };

  showPillars = () => {
    this.floorPlan.showPillars();
  };

  hidePillars = () => {
    this.floorPlan.hidePillars();
  };

  // 編輯模式, 暫停和啟用選取
  pauseSelect = () => {
    this.floorPlan.pause('select');
  };

  resumeSelect = () => {
    this.floorPlan.resume('select');
  };

  // 更新地圖參展商的名字和攤位編號
  refreshFloorPlan = async () => {
    const lang = this.floorPlan.getLanguage();
    const groups = this.floorPlan.getGroups();
    const companies = Object.keys(this.companies);
    const details = await this.getCompaniesDetailAPI(companies);
    const jsonDetails = {};

    if (details.length) {
      details.forEach((item) => {
        jsonDetails[item.id] = {
          id: item.id,
          zhName: item.zhName,
          enName: item.enName,
          boothNumber: item.boothNumber
        };
      });

      const contents = details.map((item) => {
        return { id: item.id, zhName: item.zhName, enName: item.enName };
      });

      // 更新參展商的名字
      this.floorPlan.setTextContentById(contents, lang);

      // 更新攤位編號
      groups.forEach((group) => {
        const checkCompanies = !!group.companies.length;

        if (checkCompanies) {
          const boothNumbers = group.companies.map((companyId) => {
            return jsonDetails[companyId]?.boothNumber || '';
          });
          const boothNumber = Array.from(new Set(boothNumbers)).join(',');

          this.floorPlan.setBoothNumberById(group.id, boothNumber);
        }
      });

      // 更新地標
      const marks = Object.values(MARKS).filter((item) => item.src);

      await this.floorPlan.setMarkIconById(marks);

      await this.updateFloorPlanAPI();

      console.log(
        'FloorPlanViewModel',
        'refreshFloorPlan',
        groups,
        jsonDetails
      );
    }
  };

  // 讀取參展商的資料
  // eslint-disable-next-line consistent-return
  getCompaniesDetailAPI = async (ids) => {
    try {
      const res = await ExhibitionService.postExhibitorDetail(ids);

      console.log('companies detail', res.data);

      return res.data.map((item) => {
        return {
          ...item,
          tags: item.tags.map((tag) => tag.id)
        };
      });
    } catch (error) {
      console.log('DrawFloorPlanPageViewModel', 'postExhibitorDetail', error);

      return [];
    }
  };

  // 更新參展商 map id, 刪除攤位, 清除參展商
  updateCompaniesMapIdAPI = async (companies) => {
    try {
      const res = await ExhibitionService.putExhibitorDetail({
        list: companies
      });

      this.updateCompanyDrawerCompanies(companies);

      return true;
    } catch (error) {
      console.log('DrawFloorPlanPageViewModel', 'putExhibitorDetail', error);

      return false;
    }
  };

  updateCompanyDrawerCompanies = (companies) => {
    const floorPlanCompanies = this.floorPlan.getCompanies();

    // 更新 vm 的定位
    companies.forEach((item) => {
      const position = !!floorPlanCompanies[item.id];

      this.companyDrawerVM.setMapId(item.id, item.mapId);
      // mapId 可能是別張地圖
      this.companyDrawerVM.setPositioned(item.id, position);

      console.log('更新 CompanyDrawer 的參展商', item);
    });
  };

  // 更新參展商 boothNumber, 合併攤位
  putCompaniesBoothNumberAPI = async (list = []) => {
    try {
      const res = await ExhibitionService.putExhibitorDetail({
        list
      });

      // 更新 vm 的攤位編號
      list.forEach((item) => {
        this.companyDrawerVM.setBoothNumber(item.id, item.boothNumber);
      });
    } catch (error) {
      console.log('DrawFloorPlanPageViewModel', 'putExhibitorDetail', error);
    }
  };

  updateFloorPlanTimeout = () => {
    // this.updateTimeout = window.clearTimeout(this.updateTimeout);
    // this.updateTimeout = window.setTimeout(this.updateFloorPlanAPI, 5000);

    // 被要求不延遲
    this.updateFloorPlanAPI();
  };

  // 更新地圖
  updateFloorPlanAPI = async (props) => {
    const callback = props?.callback || (() => {});

    // 地圖大部分的操作都會呼叫這個 func, 可以在這裡讀取上一步數量
    this.getHistoriesLength();

    try {
      const map = await this.floorPlan.exportData();
      const res = await FloorPlanService.putMapUpdate({
        id: this.id,
        map,
        background: props?.background
      });

      if (props?.background) {
        this.floorPlan.updateBackground(props.background);
      }

      callback();
    } catch (error) {
      console.log('DrawFloorPlanPageViewModel', 'putMapUpdate', error);
    }
  };
}

const floorPlanViewModel = new FloorPlanViewModel();

export default floorPlanViewModel;
