import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { ROUTES, EAnnouncementStatus } from 'src/constants';
import { Waypoint } from 'react-waypoint';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { Select, Button, Table, Tooltip, Tag, Popconfirm } from 'antd';
import { ANNOUNCEMENT_TABLE } from 'src/constants/table';

import Header from 'src/components/Header';
import withRouter from 'src/components/withRouter';

import AnnouncementEditDrawer from './components/Drawer';
import styles from './styles.module.scss';

import ViewModel from './viewModel';

@observer
class AnnouncementPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router);
  }

  render() {
    return (
      <>
        <div className={styles.pageContainer}>
          <Header
            backTo={ROUTES.Announcement}
            title={this.viewModel.name}
            breadcrumb={[{ title: i18n.t('ann_exhi_page_breadcrumb') }, { title: this.viewModel.name }]}
          />
          <div className={styles.contentContainer}>
            <div className={styles.filterContainer}>
              <div className={styles.selectContainer}>
                <div className={styles.selectTitle}>
                  {i18n.t('ann_exhi_status_select_title')}
                </div>
                <Select
                  className={clsx('select', styles.select)}
                  placeholder={i18n.t('ann_exhi_status_select_placeholder')}
                  value={this.viewModel.selectedStatus}
                  onChange={this.viewModel.onStatusSelect}
                  options={[
                    {
                      value: 'all',
                      label: i18n.t('common_select_option_all')
                    },
                    ...EAnnouncementStatus
                  ]}
                />
              </div>
              <Button
                className={styles.addButton}
                type="primary"
                onClick={this.viewModel.drawerViewModel.onAddOpen}
              >
                {i18n.t('ann_status_add')}
              </Button>
            </div>
            <div className={styles.headerContainer}>
              {
                ANNOUNCEMENT_TABLE.map((el) => {
                  const width = (() => {
                    if (el.key === 'status' || el.key === 'language') {
                      return '10%';
                    }
                    if (el.key === 'name') {
                      return '35%';
                    }
                    return '15%';
                  })();
                  if (el.key === 'sendTime') {
                    return (
                      <div
                        className={styles.customTitle}
                        style={{ width }}
                        onClick={this.viewModel.onSortChange}
                        key={el.key}
                      >
                        <div className={styles.title}>
                          {el.title}
                        </div>
                        <div className={styles.arrowContainer}>
                          <CaretUpOutlined className={clsx(styles.icon, styles.upIcon, this.viewModel.sortBy === 'dateAsc' && styles.active)} />
                          <CaretDownOutlined className={clsx(styles.icon, styles.downIcon, this.viewModel.sortBy === 'dateDesc' && styles.active)} />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div
                      key={el.key}
                      className={styles.headerColumn}
                      style={{ width }}
                    >
                      {el.title}
                    </div>
                  );
                })
              }
            </div>
            <div className={clsx('tableContent', styles.tableContainer)}>
              <Table
                dataSource={this.viewModel.announcements}
                rowKey={(record) => record.id}
                pagination={false}
                showHeader={false}
              >
                {
                  ANNOUNCEMENT_TABLE.map((el) => {
                    if (el.key === 'name') {
                      return (
                        <Table.Column
                          title={null}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          width="35%"
                          render={(_, record, index) => {
                            return (
                              <Tooltip
                                title={record.name}
                                placement="topLeft"
                              >
                                <div className={styles.nameColumn}>
                                  {record.name}
                                </div>
                              </Tooltip>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'manipulate') {
                      return (
                        <Table.Column
                          title={null}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          width="15%"
                          render={(_, record, index) => {
                            return (
                              <>
                                {
                                  record.isSent
                                    ? (
                                      <>
                                        <Button
                                          type="link"
                                          className={clsx(styles.btn, styles.primaryBtn)}
                                          onClick={() => this.viewModel.drawerViewModel.onEditOpen(record.id)}
                                        >
                                          {i18n.t('ann_table_manipulate_view')}
                                        </Button>
                                        <Popconfirm
                                          title={record.isActive ? i18n.t('ann_inactive_confirm_title') : i18n.t('ann_active_confirm_title')}
                                          onConfirm={() => this.viewModel.toggleActive(record.id)}
                                          okText={i18n.t('common_btn_confirm')}
                                          cancelText={i18n.t('common_btn_cancel')}
                                        >
                                          <Button
                                            type="link"
                                            className={clsx(styles.btn, styles.deleteBtn)}
                                          >
                                            {
                                              record.isActive ? i18n.t('ann_table_manipulate_inactive') : i18n.t('ann_table_manipulate_active')
                                            }
                                          </Button>
                                        </Popconfirm>
                                      </>
                                    )
                                    : (
                                      <>
                                        <Button
                                          type="link"
                                          className={clsx(styles.btn, styles.primaryBtn)}
                                          onClick={() => this.viewModel.drawerViewModel.onEditOpen(record.id)}
                                        >
                                          {i18n.t('ann_table_manipulate_edit')}
                                        </Button>
                                        <Popconfirm
                                          title={i18n.t('ann_delete_confirm_title')}
                                          onConfirm={() => this.viewModel.onDelete(record.id)}
                                          okText={i18n.t('common_btn_delete')}
                                          cancelText={i18n.t('common_btn_cancel')}
                                        >
                                          <Button
                                            type="link"
                                            className={clsx(styles.btn, styles.deleteBtn)}
                                          >
                                            {i18n.t('ann_table_manipulate_delete')}
                                          </Button>
                                        </Popconfirm>
                                      </>
                                    )
                                }

                                {
                                  (this.viewModel.anchor && index === this.viewModel.announcements.length - 1)
                                  && (
                                    <Waypoint
                                      onEnter={this.viewModel.getAnnouncements}
                                    />
                                  )
                                }
                              </>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'status') {
                      return (
                        <Table.Column
                          title={null}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          width="10%"
                          render={(_, record, index) => {
                            if (record.isSent) {
                              return (
                                <Tag
                                  color="#1890FF"
                                >
                                  {i18n.t('ann_status_select_option_sent')}
                                </Tag>
                              );
                            }
                            return (
                              <Tag
                                color="#FF6B00"
                              >
                                {i18n.t('ann_status_select_option_unsend')}
                              </Tag>
                            );
                          }}
                        />
                      );
                    }
                    if (el.key === 'language') {
                      return (
                        <Table.Column
                          title={null}
                          dataIndex={el.dataIndex}
                          key={el.key}
                          width="10%"
                        />
                      );
                    }
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="15%"
                      />
                    );
                  })
                }
              </Table>
            </div>
          </div>
        </div>
        <AnnouncementEditDrawer viewModel={this.viewModel.drawerViewModel} />
      </>
    );
  }
}

AnnouncementPage.propTypes = {
  router: PropTypes.object
};

AnnouncementPage.defaultProps = {
  router: {}
};

export default withRouter(AnnouncementPage);
