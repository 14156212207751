import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Checkbox } from 'antd';
import styles from './styles.module.scss';

@observer
class CheckboxTag extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <label htmlFor={this.vm.labelId} className={styles.container}>
        <div className={styles.checkboxContainer}>
          <Checkbox
            id={this.vm.labelId}
            checked={this.vm.checked}
            onChange={this.vm.onCheckedChange}
            disabled={this.props.disabled}
          />
        </div>

        <div className={styles.name}>
          {this.vm.name}
        </div>
      </label>
    );
  }
}

CheckboxTag.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool
};

CheckboxTag.defaultProps = {
  disabled: false
};

export default CheckboxTag;
