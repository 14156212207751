import TagDataModel from './TagDataModel';

class ExhibitorListItemDataModel {
  constructor(props) {
    this.id = String(props.id);

    this.name = props.name || '';
    this.zhName = props.zhName || '';
    this.enName = props.enName || '';

    this.boothNumber = props.standNumber || '';

    this.mapId = props.mapId || '';

    this.regNo = props.regNo || '';
  }
}

export default ExhibitorListItemDataModel;
