import Group from '../components/Group';
import SelectPath from '../components/SelectPath';
import GroupDataModel from '../dataModels/GroupDataModel';
import { EVENT_CODES, ERRORS } from '../global/constants';

class CreateGroup {
  path = null;

  clientDown = null;
  clientMove = null;

  isMouseDown = false;
  isPause = false;

  context = null;

  constructor(context) {
    this.path = new SelectPath(context);
    this.context = context;
  }

  init = () => {
    this.context.viewport.on('mousedown', this.onMousedown);
  };

  destroy = () => {
    window.removeEventListener('mouseup', this.onWindowMouseup);
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  // eslint-disable-next-line consistent-return
  onMousedown = (event) => {
    if (
      !this.context.store.check.hasRectColor
      || this.isPause
      || this.isMouseDown
      || event.buttons !== EVENT_CODES.leftButton
    ) {
      return false;
    }

    this.isMouseDown = true;

    const localClient = this.context.getEventMapPosition(event);

    this.clientDown = localClient;
    this.clientMove = localClient;

    this.path.init(localClient, true);

    this.context.viewport.on('mousemove', this.onViewportMousemove);
    window.addEventListener('mouseup', this.onWindowMouseup);

    this.context.closeMenu();

    this.context.pause('select');
  };

  // eslint-disable-next-line consistent-return
  onViewportMousemove = (event) => {
    if (!this.context.store.check.hasRectColor || this.isPause) {
      return false;
    }

    const clientMove = this.context.getEventMapPosition(event);

    this.clientMove = clientMove;

    this.path.render(clientMove, true);
  };

  // eslint-disable-next-line consistent-return
  onWindowMouseup = () => {
    this.context.viewport.off('mousemove', this.onViewportMousemove);
    window.removeEventListener('mouseup', this.onWindowMouseup);

    this.context.resume('select');

    if (
      !this.context.store.check.hasRectColor
      || this.isPause
      || !this.isMouseDown
    ) {
      this.isMouseDown = false;

      // 刪除選取匡
      this.path.clear();

      return false;
    }

    this.isMouseDown = false;

    const bounding = this.path.bounding;
    // 有沒有選到攤位
    const overlapRects = this.context.store.flat.rects.find((rect) =>
      rect.checkOverlap(bounding));

    if (overlapRects) {
      this.context.events.onError(ERRORS['0x002']);
    } else {
      const data = new GroupDataModel({
        x: bounding.x,
        y: bounding.y,
        rects: [
          {
            x: 0,
            y: 0,
            width: bounding.width,
            height: bounding.height,
            tint: this.context.store.rectColor
          }
        ]
      });
      const group = new Group({
        data,
        context: this.context
      });

      this.clientDown = null;
      this.clientMove = null;

      group.addChildToMap();

      // history 上一步紀錄
      this.context.history.pushes.createBooth(group.get.res);

      this.context.events.onCreated();

      // 選取畫好的攤位
      this.context.clearSelects();
      this.context.store.updateSelects([group]);

      group.active();

      // 外部使用
      this.context.events.onSelected();
    }

    // 刪除選取匡
    this.path.clear();
  };
}

export default CreateGroup;
