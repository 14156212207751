import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Input, Tag } from 'antd';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import styles from './styles.module.scss';

@observer
class CustomTag extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({
      router: props.router,
      onCustomTagEdited: props.onCustomTagEdited,
      onCustomTagRemoved: props.onCustomTagRemoved
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <Input
          ref={this.vm.inputRef}
          type="text"
          size="small"
          className={clsx([styles.input, this.vm.show.input && styles.show])}
          value={this.vm.name}
          onChange={this.vm.onNameChange}
          onBlur={this.vm.onBlur}
          onPressEnter={this.vm.onPressEnter}
          disabled={this.vm.disabled.input}
        />
        <Tag
          className={clsx([
            styles.tag,
            this.vm.show.tag && styles.show,
            this.vm.disabled.tag && styles.disabled
          ])}
          onClick={this.vm.onTagClick}
          closable
          onClose={this.vm.onClose}
        >
          {this.vm.name}
        </Tag>
      </div>
    );
  }
}

CustomTag.propTypes = {
  router: PropTypes.object.isRequired,
  vm: PropTypes.object.isRequired,
  onCustomTagEdited: PropTypes.func.isRequired,
  onCustomTagRemoved: PropTypes.func.isRequired
};

CustomTag.defaultProps = {};

export default withRouter(CustomTag);
