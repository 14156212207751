import {
  makeObservable,
  observable,
  computed,
  action,
  runInAction
} from 'mobx';
import statusStore from 'src/stores/status';
import ExhibitionService from 'src/services/exhibition';
import FloorPlan from 'src/components/FloorPlan';
import CustomDrawerViewModel from 'src/components/CustomDrawer/viewModel';
import AreaNameViewModel from './components/AreaName/viewModel';
import CompanyViewModel from './components/Company/viewModel';
import BoothNumberFontSizeViewModel from './components/BoothNumberFontSize/viewModel';

class BoothDrawerViewModel {
  mapId = null;

  @observable group = null;

  @observable companies = [];

  @observable isAwait = false;

  drawerVM = new CustomDrawerViewModel();
  areaNameVM = new AreaNameViewModel();
  boothNumberFontSizeVM = new BoothNumberFontSizeViewModel();

  floorPlanVM = null;

  @computed
  get check() {
    return {
      group: !!this.group
    };
  }

  @computed
  get disabled() {
    return {
      areaName: this.isAwait,
      company: this.isAwait,
      confirm: this.isAwait,
      tag: this.isAwait
    };
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  init = (props) => {
    this.mapId = props.router.params.id;
  };

  // 點擊攤位後, 打開 drawer, 參考fakeGroupData
  @action open = (group) => {
    const checkId = this.group?.id === group.id;

    if (!checkId || this.floorPlanVM.isStamped) {
      this.group = group;
      this.companies = [];

      this.areaNameVM.setData({
        zhName: group.areaName.zh?.content,
        enName: group.areaName.en?.content,
        zhNameFontSize: group.areaName.zh?.fontSize,
        enNameFontSize: group.areaName.en?.fontSize
      });

      this.boothNumberFontSizeVM.setFontSize(group.boothNumber?.fontSize);

      this.drawerVM.open();

      this.getCompaniesDetailAPI();
    }

    console.log('BoothDrawer', 'open', group);
  };

  @action close = () => {
    this.drawerVM.close();
  };

  @action onAfterOpenChange = (open) => {
    if (!open) {
      this.group = null;
      this.companies = [];
    }
  };

  @action onBoothNumberChange = (event) => {
    this.boothNumber = event.target.value;
  };

  pauseSelect = () => {
    this.floorPlanVM.pauseSelect();
  };

  resumeSelect = () => {
    this.floorPlanVM.resumeSelect();
  };

  getBoothNumber = () => {
    const res = this.companies
      .map((item) => item.res.boothNumber)
      .filter((item) => item);
    const boothNumber = Array.from(new Set(res)).join(',');

    return boothNumber.trim() ? boothNumber : null;
  };

  onConfirm = async () => {
    statusStore.setLoading();

    await Promise.all([
      this.updateCompaniesDetailAPI(),
      this.updateFloorPlanGroup(true)
    ]);

    this.updateCompanyDrawer();

    statusStore.setCompleted();
  };

  @action updatedTagPool = () => {
    if (this.group) {
      this.companies = [];
      this.getCompaniesDetailAPI();
    }
  };

  // 讀取多個參展商的資料
  @action getCompaniesDetailAPI = async () => {
    this.isAwait = true;

    try {
      const companies = Object.keys(this.group.companies);
      const res = await ExhibitionService.postExhibitorDetail(companies);

      runInAction(() => {
        // 保存參展商
        this.companies = res.data.map((item) => new CompanyViewModel(item));

        // 更新參展商
        this.companies.forEach((vm) => {
          const data = this.group.companies[vm.id];

          // 從地圖讀取中英文暱稱的文字大小
          vm.setZhNameFontSize(data.zh.fontSize);
          vm.setEnNameFontSize(data.en.fontSize);
        });

        // 更新參展商抽屜的 VM
        this.updateCompanyDrawer();

        // 更新地圖的攤位
        this.updateFloorPlanGroup(false);
      });
    } catch (error) {
      console.log('BoothDrawerViewModel', 'postExhibitorDetail', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 編輯參展商
  @action updateCompaniesDetailAPI = async () => {
    this.isAwait = true;

    try {
      const list = this.companies.map((vm) => vm.res);
      const res = await ExhibitionService.putExhibitorDetail({ list });

      console.log('攤位設定', '編輯參展商', list);
    } catch (error) {
      console.log('BoothDrawerViewModel', 'putExhibitorDetail', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 更新 CompanyDrawer 的參展商, 中英文暱稱, 攤位編號
  updateCompanyDrawer = () => {
    const companies = this.companies.map((item) => item.res);

    companies.forEach((item) => {
      this.floorPlanVM.companyDrawerVM.setZhName(item.id, item.zhName);
      this.floorPlanVM.companyDrawerVM.setEnName(item.id, item.enName);
      this.floorPlanVM.companyDrawerVM.setBoothNumber(
        item.id,
        item.boothNumber
      );
    });
  };

  // 更新地圖攤位
  @action updateFloorPlanGroup = (history = false) => {
    const boothNumber = this.getBoothNumber();
    const detail = {
      // 固定會有參展商
      companies: {}
    };

    // 更新參展商
    this.companies.forEach((item) => {
      const data = item.res;

      detail.companies[data.id] = {
        [FloorPlan.LANGUAGES.zh]: {
          id: data.id,
          content: data.zhName,
          fontSize: data.zhNameFontSize,
          language: FloorPlan.LANGUAGES.zh
        },
        [FloorPlan.LANGUAGES.en]: {
          id: data.id,
          content: data.enName,
          fontSize: data.enNameFontSize,
          language: FloorPlan.LANGUAGES.en
        }
      };
    });

    // 更新攤位編號
    if (boothNumber) {
      detail.boothNumber = {
        content: boothNumber,
        fontSize: this.boothNumberFontSizeVM.res
      };
    }

    if (this.areaNameVM.valid) {
      const res = this.areaNameVM.res;

      detail.areaName = {
        [FloorPlan.LANGUAGES.zh]: {
          id: res.id,
          content: res.zhName,
          fontSize: res.zhNameFontSize,
          language: FloorPlan.LANGUAGES.zh
        },
        [FloorPlan.LANGUAGES.en]: {
          id: res.id,
          content: res.enName,
          fontSize: res.enNameFontSize,
          language: FloorPlan.LANGUAGES.en
        }
      };
    }

    console.log('BoothDrawer', 'update', 'detail', detail);

    this.floorPlanVM.sets.setFloorPlanBoothDetail({
      groupId: this.group.id,
      detail,
      history,
      type: FloorPlan.HISTORIES.companies
    });

    // call api
    this.floorPlanVM.updateFloorPlanAPI();
  };
}

export default BoothDrawerViewModel;
