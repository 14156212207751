class Pause {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  pause = (type) => {
    switch (type) {
      case 'dragMap':
        this.parent.context.viewport.plugins.pause('drag');
        this.parent.context.dragMapControl.pause();
        break;

      case 'wheel':
        this.parent.context.viewport.plugins.pause('wheel');
        break;

      case 'pinch':
        this.parent.context.viewport.plugins.pause('pinch');
        break;

      case 'select':
        this.parent.context.select.pause();
        break;

      case 'readSelect':
        this.parent.context.readSelect.pause();
        break;

      case 'createGroup':
        this.parent.context.createGroup.pause();
        break;

      case 'menu':
        this.parent.context.menu.pause();
        break;

      case 'dragObject':
        this.parent.context.dragObject.pause();
        break;

      case 'stamp':
        this.parent.context.stamp.pause();
        break;

      case 'history':
        this.parent.context.history.pause();
        break;

      default:
        break;
    }
  };
}

export default Pause;
