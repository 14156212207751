import { DEVICE_PIXEL_RATIO } from '../global/constants';

class ResizeViewport {
  context = null;

  constructor(context) {
    this.context = context;
  }

  resize = () => {
    /**
     * pixi 使用正確的解析度比率
     * viewport 的寬度使用計算後的
     * https://github.com/davidfig/pixi-viewport/issues/326
     */
    const screenWidth = this.context.parent.clientWidth / DEVICE_PIXEL_RATIO;
    const screenHeight = this.context.parent.clientHeight / DEVICE_PIXEL_RATIO;

    const worldWidth = this.context.store.col * this.context.store.gridSize;
    const worldHeight = this.context.store.row * this.context.store.gridSize;

    this.context.viewport.resize(
      screenWidth,
      screenHeight,
      worldWidth,
      worldHeight
    );
  };
}

export default ResizeViewport;
