import { v4 } from 'uuid';
import { OBJECT_TYPES } from '../global/constants';
import RectDataModel from './RectDataModel';
import TextDataModel from './TextDataModel';
import MarkIconDataModel from './MarkIconDataModel';

class GroupDataModel {
  constructor(props) {
    this.id = props.id || v4();
    this.type = props.type || OBJECT_TYPES.group;

    this.x = props.x;
    this.y = props.y;

    this.rects = props.rects?.map((item) => new RectDataModel(item)) || [];

    // 參展商

    const companies = props.companies || {};
    const newCompanies = {};

    Object.keys(companies).forEach((companyId) => {
      const company = companies[companyId];
      const newCompany = {};

      Object.keys(company).forEach((lang) => {
        newCompany[lang] = company[lang];
      });

      newCompanies[companyId] = newCompany;
    });

    this.companies = newCompanies;

    // 攤位編號

    this.boothNumber = props.boothNumber
      ? new TextDataModel(props.boothNumber)
      : null;

    // 文字地標
    const markText = props.markText || {};
    const newMarkText = {};

    Object.keys(markText).forEach((lang) => {
      newMarkText[lang] = new TextDataModel(props.markText[lang]);
    });

    this.markText = newMarkText;

    // 地標圖片

    this.markIcon = props.markIcon
      ? new MarkIconDataModel(props.markIcon)
      : null;

    // 專區名稱
    const areaName = props.areaName || {};
    const newAreaName = {};

    Object.keys(areaName).forEach((lang) => {
      newAreaName[lang] = new TextDataModel(props.areaName[lang]);
    });

    this.areaName = newAreaName;
  }
}

export default GroupDataModel;
