import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import clsx from 'clsx';
import i18n from 'src/i18n';
import iconEnable from 'src/assets/icon_back.svg';
import iconDisabled from 'src/assets/icon_back_disabled.svg';
import styles from './styles.module.scss';

@observer
class BackButton extends React.Component {
  render() {
    const icon = this.props.disabled ? iconDisabled : iconEnable;

    return (
      <Button
        type="text"
        className={styles.button}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        <div className={styles.icon}>
          <img src={icon} alt="back" className={styles.image} />
        </div>

        <div
          className={clsx([
            styles.label,
            this.props.disabled && styles.disabled
          ])}
        >
          {i18n.t('draw_floor_plan_page_back_button_label')}
        </div>
      </Button>
    );
  }
}

BackButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

BackButton.defaultProps = {
  onClick: () => {},
  disabled: false
};

export default BackButton;
