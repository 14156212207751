import { makeObservable, observable, computed, action } from 'mobx';

const colors = {
  '#ffffff': '#ffffff',
  '#333333': '#333333',
  '#E01818': '#E01818',
  '#FB8C09': '#FB8C09',
  '#FFCD29': '#FFCD29',
  '#20C92F': '#20C92F',
  '#69C0FF': '#69C0FF',
  '#165CF6': '#165CF6',
  '#9747FF': '#9747FF'
};

const KEY_CODES = {
  esc: 27
};

class CreateBoothViewModel {
  @observable color = colors['#FFCD29'];
  @observable pickColor = colors['#FFCD29'];

  floorPlanVM = null;

  @computed
  get colors() {
    return Object.keys(colors);
  }

  constructor(props) {
    this.floorPlanVM = props;
    makeObservable(this);
  }

  didMount = () => {
    window.addEventListener('keyup', this.onWindowKeydown);
  };

  unmount = () => {
    window.removeEventListener('keyup', this.onWindowKeydown);
  };

  @action setActive = (val) => {
    this.active = val;
  };

  @action onRectClick = (event) => {
    const color = event.target.dataset.color;

    this.color = color;

    this.floorPlanVM.sets.setCreateBoothColor(color);
  };

  @action onColorPickerChange = (color, hex) => {
    this.color = hex;
    this.pickColor = hex;

    this.floorPlanVM.sets.setCreateBoothColor(hex);
  };

  @action onColorPickerClick = () => {
    this.color = this.pickColor;
    this.floorPlanVM.sets.setCreateBoothColor(this.color);
  };

  // esc 可以清除畫攤位
  onWindowKeydown = (event) => {
    if (event.keyCode === KEY_CODES.esc) {
      this.floorPlanVM.clearSwitch('create');
    }
  };
}

export default CreateBoothViewModel;
