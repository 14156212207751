import { OUTSIDE_EVENTS } from '../../global/constants';

class Events {
  parent = null;

  // 新增攤位結束
  outsideCreated = () => {};
  // 選擇攤位
  outsideSelected = () => {};
  // 攤位清除廠商
  outsideCompaniesRemoved = () => {};
  // 清除攤位
  outsideBoothRemoved = () => {};
  // 攤位合併
  outsideMerged = () => {};
  // 蓋章結束
  outsideStamped = () => {};
  // 任何東西移動結束
  outsideMoved = () => {};
  // 貼上
  outsidePasted = () => {};
  // 設置多個攤位顏色
  outsideSetGroupsColor = () => {};
  // 設置多個文字字型大小
  outsideSetFontSize = () => {};
  // 任何操作的 error
  outsideError = () => {};

  constructor(props) {
    this.parent = props;
  }

  addEventListener = (type, func) => {
    switch (type) {
      case OUTSIDE_EVENTS.created:
        this.outsideCreated = func;
        break;

      case OUTSIDE_EVENTS.selected:
        this.outsideSelected = func;
        break;

      case OUTSIDE_EVENTS.companiesRemoved:
        this.outsideCompaniesRemoved = func;
        break;

      case OUTSIDE_EVENTS.boothRemoved:
        this.outsideBoothRemoved = func;
        break;

      case OUTSIDE_EVENTS.merged:
        this.outsideMerged = func;
        break;

      case OUTSIDE_EVENTS.stamped:
        this.outsideStamped = func;
        break;

      case OUTSIDE_EVENTS.moved:
        this.outsideMoved = func;
        break;

      case OUTSIDE_EVENTS.pasted:
        this.outsidePasted = func;
        break;

      case OUTSIDE_EVENTS.setGroupsColor:
        this.outsideSetGroupsColor = func;
        break;

      case OUTSIDE_EVENTS.setFontSize:
        this.outsideSetFontSize = func;
        break;

      case OUTSIDE_EVENTS.error:
        this.outsideError = func;
        break;

      default:
        break;
    }
  };

  removeEventListener = (type) => {
    switch (type) {
      case OUTSIDE_EVENTS.created:
        this.outsideCreated = () => {};
        break;

      case OUTSIDE_EVENTS.selected:
        this.outsideSelected = () => {};
        break;

      case OUTSIDE_EVENTS.companiesRemoved:
        this.outsideCompaniesRemoved = () => {};
        break;

      case OUTSIDE_EVENTS.boothRemoved:
        this.outsideBoothRemoved = () => {};
        break;

      case OUTSIDE_EVENTS.merged:
        this.outsideMerged = () => {};
        break;

      case OUTSIDE_EVENTS.stamped:
        this.outsideStamped = () => {};
        break;

      case OUTSIDE_EVENTS.moved:
        this.outsideMoved = () => {};
        break;

      case OUTSIDE_EVENTS.pasted:
        this.outsidePasted = () => {};
        break;

      case OUTSIDE_EVENTS.setGroupsColor:
        this.outsideSetGroupsColor = () => {};
        break;

      case OUTSIDE_EVENTS.setFontSize:
        this.outsideSetFontSize = () => {};
        break;

      case OUTSIDE_EVENTS.error:
        this.outsideError = () => {};
        break;

      default:
        break;
    }
  };

  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------
  // ---------------------------------------------------------------------------


  // 給外部的事件

  onCreated = () => {
    this.outsideCreated();
  };

  onSelected = () => {
    const groups = this.parent.store.selects.map((item) => item.group || item);
    const mapGroups = groups.map((item) => {
      return this.parent.store.check.isEdit
        ? item.get.resComplexForOutside
        : item.get.resSimpleForOutside;
    });

    this.outsideSelected(mapGroups);
  };

  // 通知外部, 應該要刪除的廠商
  getShouldCompaniesRemoved = () => {
    // [1, 2, 3, 2];
    const allCompanies = Object.values(this.parent.store.data.groups)
      .map((item) => Object.keys(item.companies))
      .flat();
    // [1, 2];
    const removeCompanies = this.parent.store.flatSelects.groups
      .map((item) => Object.keys(item.companies))
      .flat();
    const resCompanies = removeCompanies.filter((i) => {
      const length = allCompanies.filter((c) => c === i).length;
      return length === 1;
    });

    return resCompanies;
  };

  onCompaniesRemoved = () => {
    const groups = this.parent.store.flatSelects.groups;
    const companies = this.getShouldCompaniesRemoved();
    // 上一步
    const history = {
      before: [],
      after: []
    };

    groups.forEach((group) => {
      history.before.push(group.get.res);

      group.delete.removeAllCompany();

      history.after.push(group.get.res);
    });

    this.parent.history.pushes.removeCompanies(history);

    this.outsideCompaniesRemoved(companies);
  };

  // 刪除攤位
  onBoothRemoved = () => {
    const groups = this.parent.store.flatSelects.groups;
    const companies = this.getShouldCompaniesRemoved();
    const history = {
      before: [],
      after: []
    };

    groups.forEach((group) => {
      history.before.push(group.get.res);
      group.remove();
    });

    this.parent.clearSelects();

    this.parent.history.pushes.removeBooths(history);

    this.outsideBoothRemoved(companies);

    this.onSelected();
  };

  onMerged = async () => {
    await this.outsideMerged();
  };

  onStamped = async (group) => {
    const groupRes = group.get.resSimpleForOutside;
    const check = await this.outsideStamped(groupRes);

    // 蓋章成功
    if (check) {
      this.parent.clearSelects();
      this.parent.store.updateSelects([group]);

      group.active();

      this.onSelected();
    }
  };

  onMoved = () => {
    this.outsideMoved();
  };

  onPasted = () => {
    this.outsidePasted();
  };

  onSetGroupsColor = () => {
    const groups = this.parent.store.selects.map((item) => {
      return (
        item.group?.get.resComplexForOutside || item.get.resComplexForOutside
      );
    });

    this.outsideSetGroupsColor(groups);
  };

  onSetFontSize = () => {
    const texts = this.parent.store.flatSelects.texts;
    this.outsideSetFontSize(texts);
  };

  onError = (val) => {
    this.outsideError(val);
  };
}

export default Events;
