import {
  makeObservable,
  observable,
  action,
  runInAction,
  computed
} from 'mobx';
import FloorPlanService from 'src/services/floorPlanService';
import ExhibitionService from 'src/services/exhibition';
import FloorPlan from 'src/components/FloorPlan';
import statusStore from 'src/stores/status';
import { delay } from 'src/utils';

class FloorPlanListItemViewModel {
  id = null;

  @observable chose = false;
  @observable active = false;
  @observable isEdit = false;
  @observable isAwait = false;

  @observable zhName = '';
  @observable enName = '';
  @observable exhibitions = [];
  @observable tags = [];
  @observable searchTags = [];

  @computed
  get disabled() {
    return {
      edit: this.isAwait
    };
  }

  @computed
  get mapTags() {
    return this.tags.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
  }

  @computed
  get mapSearchTags() {
    return this.searchTags.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });
  }

  constructor(props) {
    this.id = props.id;

    // 上架
    this.active = props.active;

    this.zhName = props.zhName;
    this.enName = props.enName;
    this.exhibitions = props.exhibitions;

    this.searchTags = props.searchTags;
    this.tags = props.tags.map((item) => {
      return {
        value: item.id,
        label: item.name
      };
    });

    makeObservable(this);
  }

  @action onChoseChange = (event) => {
    this.chose = event.target.checked;
  };

  @action onActiveChange = (val) => {
    this.active = val;
    this.putMapUpdateAPI();
  };

  @action onZhNameChange = (event) => {
    this.zhName = event.target.value;
  };

  @action onEnNameChange = (event) => {
    this.enName = event.target.value;
  };

  @action onChangeSearchTags = (values) => {
    this.tags = values.map((item) => {
      return {
        value: item.value,
        label: item.label
      };
    });

    console.log('onChangeSearchTags', values);
  };

  onSave = async (callBack) => {
    this.putMapUpdateAPI(callBack);
  };

  @action putMapUpdateAPI = async (callBack = () => {}) => {
    this.isAwait = true;

    try {
      const data = {
        id: this.id,
        zhName: this.zhName,
        enName: this.enName,
        active: this.active,
        tags: this.tags.map((item) => item.value)
      };
      const res = await FloorPlanService.putMapUpdate(data);

      if (this.active) {
        this.updateCompaniesMapIdAPI();
      }

      console.log('active', this.active);

      callBack();
    } catch (error) {
      console.log('FloorPlanListItemViewModel', 'putMapUpdateAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  // 上架時, 更新地圖內參展商所在的地圖
  @action updateCompaniesMapIdAPI = async () => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.getMapDetail({ id: this.id });
      const companies = FloorPlan.getCompaniesByData(res.data.map);
      const details = await ExhibitionService.postExhibitorDetail(companies);
      const mapDetails = details.data.map((item) => {
        const tags = item.tags.map((tag) => tag.id);

        return {
          ...item,
          tags,
          mapId: this.id
        };
      });
      const sendRes = await ExhibitionService.putExhibitorDetail({
        list: mapDetails
      });
    } catch (error) {
      console.log('FloorPlanListItemViewModel', 'getMapDetailAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default FloorPlanListItemViewModel;
