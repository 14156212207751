import { request, getHost, getHeaders } from './utils';

export const getCountries = () => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/exhibition/countries`,
    headers: getHeaders()
  };
  return request(options);
};

export const getExhibitions = (params) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/exhibition/list`,
    headers: getHeaders(),
    params
  };
  return request(options);
};

export const getExhibitionDetail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/exhibition/${id}/detail`,
    headers: getHeaders()
  };
  return request(options);
};

// 展覽列表
export const getExhibitionList = () => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/exhibition/map/list`,

    headers: getHeaders()
  };

  return request(options);
};

// 參展商列表
export const getExhibitionExhibitor = ({ id, sortBy, areaNo }) => {
  const options = {
    method: 'get',

    url: `${getHost()}/api/v1/backstage/exhibition/${id}/exhibitor`,

    headers: getHeaders(),

    params: {
      sortBy,
      areaNo
    }
  };

  return request(options);
};

// 讀取多個參展商的資料
export const postExhibitorDetail = ({ exhibitors }) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/backstage/exhibitor/detail`,
    headers: getHeaders(),
    data: {
      exhibitors
    }
  };

  return request(options);
};

// 編輯參展商資料
export const putExhibitorDetail = ({ list }) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/backstage/exhibitor/detail`,
    headers: getHeaders(),
    data: {
      list
    }
  };

  return request(options);
};
