import React from 'react';
import { Form } from 'antd';

// 配合 ant modal + ant form
function withForm(Component) {
  return (props) => {
    const [form] = Form.useForm();

    return <Component {...props} form={form} />;
  };
}


export default withForm;
