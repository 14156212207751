import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { FONT_SIZES } from 'src/constants';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class BoothNumberFontSize extends React.Component {
  constructor(props) {
    super(props);
    this.vm = this.props.vm;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.label}>
          {i18n.t(
            'draw_floor_plan_page_booth_drawer_booth_number_font_size_label'
          )}
        </div>

        <div className={styles.form}>
          <Select
            className={styles.select}
            value={this.vm.fontSize}
            onChange={this.vm.onFontSizeChange}
            options={FONT_SIZES}
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>
      </div>
    );
  }
}

BoothNumberFontSize.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

BoothNumberFontSize.defaultProps = {
  disabled: false,
  onFocus: () => {},
  onBlur: () => {}
};

export default BoothNumberFontSize;
