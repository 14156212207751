class FloorPlaListItemDataModel {
  constructor(props) {
    this.id = props.id;

    this.zhName = props.zhName;
    this.enName = props.enName;

    this.active = props.active;

    this.exhibitions = props.exhibitions.map((item) => {
      return {
        id: item.id,
        name: item.name
      };
    });

    this.searchTags = props.searchTags.map((item) => {
      return {
        id: item.id,
        name: item.name,
        isShow: item.isShow
      };
    });

    this.tags = props.searchTags.filter((item) => item.isShow);
  }
}

export default FloorPlaListItemDataModel;
