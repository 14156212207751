import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';
import dayjs from 'dayjs';
import AnnouncementService from 'src/services/announcement';
import statusStore from 'src/stores/status';

export default class AnnouncementEditDrawerViewModel {
  parent = null;

  @observable id = null;
  @observable name = '';
  @observable receiverType = null;
  @observable language = null;
  @observable sendTime = null;
  @observable content = '';

  @observable isSent = false;
  @observable isOpen = false;

  @computed get canSubmit() {
    return this.name.trim() && this.receiverType && this.language && this.sendTime && this.content.trim();
  }

  constructor(parent) {
    makeObservable(this);

    this.init(parent);
  }

  @action init = (parent) => {
    this.parent = parent;
  };

  @action onAddOpen = () => {
    this.id = null;
    this.name = '';
    this.receiverType = null;
    this.language = null;
    this.sendTime = dayjs();
    this.content = '';
    this.isSent = false;

    this.isOpen = true;
  };

  @action onEditOpen = async (id) => {
    this.id = id;

    await this.getAnnouncementDetail();

    runInAction(() => {
      this.isOpen = true;
    });
  };

  @action onOpen = () => {
    this.isOpen = true;
  };

  @action onCancel = () => {
    this.isOpen = false;
  };

  @action onNameChange = (e) => {
    this.name = e.target.value;
  };

  @action onReceiverSelect = (v) => {
    this.receiverType = v;
  };

  @action onLanguageSelect = (e) => {
    this.language = e.target.value;
  };

  @action onTimeSelect = (v) => {
    this.sendTime = v;
  };

  @action onContentChange = (e) => {
    this.content = e.target.value;
  };

  @action getAnnouncementDetail = async () => {
    try {
      const { announcement } = await AnnouncementService.getAnnouncementDetail(this.id);

      runInAction(() => {
        const {
          id,
          name,
          receiverType,
          language,
          sendTime,
          content,
          isSent
        } = announcement;
        this.id = id;
        this.name = name;
        this.receiverType = receiverType;
        this.language = language;
        this.sendTime = dayjs(sendTime);
        this.content = content;
        this.isSent = isSent;
      });
    } catch (error) {
      console.log(error);
    }
  };


  @action checkTime = () => {
    const now = dayjs();
    const nowMinute = now.get('minute');
    const nextSegment = Math.ceil(nowMinute / 5) * 5;
    const availableMinute = nextSegment + 5;
    const addMinute = availableMinute - nowMinute;

    const limit = now.startOf('minute').add(addMinute, 'minute');

    if (this.sendTime.startOf('minute') < limit) {
      message.warning('公告時間太近可能會造成發送失敗，請重新選擇！');
      return false;
    }

    return true;
  };

  disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  @action onSubmit = async () => {
    // TODO: comment for test use
    if (!this.checkTime()) {
      return;
    }
    statusStore.setLoading();
    if (!this.id) {
      await this.onCreate();
    } else {
      await this.onEdit();
    }

    await this.parent.afterConfirm();

    runInAction(() => {
      this.isOpen = false;
    });
  };

  @action onCreate = async () => {
    try {
      const data = {
        exhibitionId: this.parent.id,
        name: this.name,
        receiverType: this.receiverType,
        language: this.language,
        sendTime: this.sendTime.startOf('minute').toDate().toISOString(),
        content: this.content
      };

      await AnnouncementService.createAnnouncement(data);
    } catch (error) {
      console.log(error);
    } finally {
      statusStore.setCompleted();
    }
  };

  @action onEdit = async () => {
    try {
      const data = {
        name: this.name,
        receiverType: this.receiverType,
        language: this.language,
        sendTime: this.sendTime.startOf('minute').toDate().toISOString(),
        content: this.content
      };
      await AnnouncementService.updateAnnouncement(this.id, data);
    } catch (error) {
      console.log(error);
    } finally {
      statusStore.setCompleted();
    }
  };
}
