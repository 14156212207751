import { OBJECT_TYPES, DEVICE_PIXEL_RATIO } from '../../global/constants';
import GroupDataModel from '../../dataModels/GroupDataModel';

class Get {
  // constructor
  Group = null;
  // instance
  group = null;

  get flatCompanies() {
    const keys = [];
    const values = [];
    const texts = [];

    Object.keys(this.parent.companies).forEach((companyId) => {
      const company = this.parent.companies[companyId];

      keys.push(companyId);
      values.push(company);

      texts.push(...Object.values(company));
    });

    return {
      keys,
      values,
      texts
    };
  }

  get flatMarkText() {
    const keys = [];
    const values = [];

    Object.keys(this.parent.markText).forEach((key) => {
      keys.push(key);
      values.push(this.parent.markText[key]);
    });

    return {
      keys,
      values
    };
  }

  get flatAreaName() {
    const keys = [];
    const values = [];

    Object.keys(this.parent.areaName).forEach((key) => {
      keys.push(key);
      values.push(this.parent.areaName[key]);
    });

    return {
      keys,
      values
    };
  }

  get flat() {
    const texts = [];
    const children = [];

    this.flatCompanies.texts.forEach((item) => {
      texts.push(item);
      children.push(item);
    });

    if (this.parent.boothNumber) {
      texts.push(this.parent.boothNumber);
      children.push(this.parent.boothNumber);
    }

    const markTexts = this.flatMarkText.values;
    if (markTexts.length) {
      texts.push(...markTexts);
      children.push(...markTexts);
    }

    if (this.parent.markIcon) {
      children.push(this.parent.markIcon);
    }

    const areaNames = this.flatAreaName.values;
    if (areaNames.length) {
      texts.push(...areaNames);
      children.push(...areaNames);
    }

    return {
      texts,
      children
    };
  }

  // 合併使用, 左下角的方塊
  get basicRect() {
    return this.parent.rects.reduce((basic, rect) => {
      // 最下面
      if (rect.maxY > basic.maxY) {
        return rect;
      }

      // 最下面 + 最左邊
      if (rect.maxY === basic.maxY && rect.minX < basic.minX) {
        return rect;
      }

      return basic;
    });
  }

  // 參展商名字定位的方塊
  get basicLeftTopRect() {
    return this.parent.rects.reduce((basic, rect) => {
      // 最上面
      if (rect.minY < basic.minY) {
        return rect;
      }

      // 最上面 + 最左邊
      if (rect.minY === basic.minY && rect.minX < basic.minX) {
        return rect;
      }

      return basic;
    });
  }

  get copy() {
    const data = new GroupDataModel({ ...this.res, id: null });
    return new this.Group({ context: this.parent.context, data });
  }

  // 複製使用, group 的左上角(座標點)
  get copyBasic() {
    const rects = this.parent.rects.slice();

    const viewX = this.parent.view.x;
    const viewY = this.parent.view.y;

    const minX = rects.sort((a, b) => a.view.x - b.view.x)[0].view.x;
    const minY = rects.sort((a, b) => a.view.y - b.view.y)[0].view.y;

    const x = viewX + minX;
    const y = viewY + minY;

    return { x, y };
  }

  // 廠商第一次的定位
  get companyBasic() {
    const rect = this.basicLeftTopRect;

    const x = rect.view.x + 7;
    const y = rect.view.y + 7;

    return {
      x,
      y
    };
  }

  // 攤位編號定一次定位
  get boothNumberBasic() {
    const rect = this.basicRect;

    const x = rect.view.x + 4;
    const y = rect.view.y + rect.view.height - 37;

    return {
      x,
      y
    };
  }

  get res() {
    const companies = {};

    this.flatCompanies.keys.forEach((companyId) => {
      const company = {};

      Object.keys(this.parent.companies[companyId]).forEach((lang) => {
        company[lang] = this.parent.companies[companyId][lang].res;
      });

      companies[companyId] = company;
    });

    const markText = {};

    this.flatMarkText.keys.forEach((lang) => {
      markText[lang] = this.parent.markText[lang].res;
    });

    const areaName = {};

    this.flatAreaName.keys.forEach((lang) => {
      areaName[lang] = this.parent.areaName[lang].res;
    });

    return {
      id: this.parent.id,
      type: OBJECT_TYPES.group,
      x: this.parent.view.x,
      y: this.parent.view.y,
      rects: this.parent.rects.map((rect) => rect.res),
      companies,
      boothNumber: this.parent.boothNumber?.res,
      markText,
      markIcon: this.parent.markIcon?.res,
      areaName
    };
  }

  get resForSVG() {
    const companies = this.flatCompanies.keys.map(
      (companyId) =>
        this.parent.companies[companyId][this.parent.context.store.language]
          .resForSVG
    );

    const markText = this.parent.markText[this.parent.context.store.language];
    const markTextRes = markText ? markText.resForSVG : null;

    const areaName = this.parent.areaName[this.parent.context.store.language];
    const areaNameRes = areaName ? areaName.resForSVG : null;

    return {
      x: this.parent.view.x,
      y: this.parent.view.y,
      rects: this.parent.rects.map((rect) => rect.res),
      border: this.borderLines,
      companies,
      boothNumber: this.parent.boothNumber?.resForSVG,
      markText: markTextRes,
      markIcon: this.parent.markIcon?.resForSVG,
      areaName: areaNameRes
    };
  }

  // 後台 BoothDrawer OtherDrawer 使用
  get resComplexForOutside() {
    const companies = {};

    this.flatCompanies.keys.forEach((companyId) => {
      const company = this.parent.companies[companyId];
      const newCompany = {};

      Object.keys(company).forEach((lang) => {
        newCompany[lang] = company[lang].res;
      });

      companies[companyId] = newCompany;
    });

    const markText = {};

    this.flatMarkText.keys.forEach((lang) => {
      markText[lang] = this.parent.markText[lang].res;
    });

    const areaName = {};

    this.flatAreaName.keys.forEach((lang) => {
      areaName[lang] = this.parent.areaName[lang].res;
    });

    return {
      id: this.parent.id,
      companies,
      boothNumber: this.parent.boothNumber?.res,
      markIcon: this.parent.markIcon?.id,
      markText,
      areaName,
      boothColor: this.parent.rects[0].tint
    };
  }

  // 檢視模式使用
  get resSimpleForOutside() {
    return {
      id: this.parent.id,
      companies: this.flatCompanies.keys,
      boothNumber: this.parent.boothNumber?.id,
      markIcon: this.parent.markIcon?.id,
      markText: this.flatMarkText.values[0]?.id,
      areaName: this.flatAreaName.values[0]?.id,
      boothColor: this.parent.rects[0].tint
    };
  }

  get borderLines() {
    const lines = this.parent.rects.map((rect) => rect.lines).flat();
    const res = {};

    lines.forEach((line) => {
      const key = JSON.stringify(line);

      if (res[key]) {
        delete res[key];
      } else {
        res[key] = true;
      }
    });

    return Object.keys(res);
  }

  get center() {
    const rects = this.parent.rects.slice();

    const minX = rects.sort((a, b) => a.minX - b.minX)[0].minX;
    const minY = rects.sort((a, b) => a.minY - b.minY)[0].minY;

    const maxX = rects.sort((a, b) => b.maxX - a.maxX)[0].maxX;
    const maxY = rects.sort((a, b) => b.maxY - a.maxY)[0].maxY;

    const x = Math.round((minX + maxX) / 2);
    const y = Math.round((minY + maxY) / 2);

    return { x, y };
  }

  get x() {
    return this.parent.view.x;
  }

  get y() {
    return this.parent.view.y;
  }

  get width() {
    const rects = this.parent.rects.slice();
    const minX = rects.sort((a, b) => a.minX - b.minX)[0].minX;
    const maxX = rects.sort((a, b) => b.maxX - a.maxX)[0].maxX;

    return maxX - minX;
  }

  get height() {
    const rects = this.parent.rects.slice();
    const minY = rects.sort((a, b) => a.minY - b.minY)[0].minY;
    const maxY = rects.sort((a, b) => b.maxY - a.maxY)[0].maxY;

    return maxY - minY;
  }

  get moveScale() {
    const screenWidth = this.parent.context.parent.offsetWidth;
    const screenHeight = this.parent.context.parent.offsetHeight;

    // 允許佔據螢幕比率 50%
    const maxRatio = 0.5;

    const widthRatio = (screenWidth * maxRatio) / this.width;
    const heightRatio = (screenHeight * maxRatio) / this.height;

    const ratio = widthRatio >= heightRatio ? heightRatio : widthRatio;
    const res = (ratio / DEVICE_PIXEL_RATIO).toFixed(2);

    return res;
  }

  // DragObject 使用
  get validMove() {
    return (
      this.parent.view.x !== this.parent.basicPosition.x
      || this.parent.view.y !== this.parent.basicPosition.y
    );
  }

  constructor(props) {
    this.Group = props.Group;
    this.parent = props.parent;
  }
}

export default Get;
