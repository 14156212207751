class MarkDataModel {
  constructor(props) {
    this.id = props.id;
    this.searchId = props.searchId;

    this.type = props.type;

    this.translateX = props.translateX || 0;
    this.translateY = props.translateY || 0;

    this.visible = !!props.visible;
  }
}

export default MarkDataModel;
