import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { computed } from 'mobx';
import { Dropdown, Form } from 'antd';
import i18n from 'src/i18n';
import withForm from 'src/components/withForm';
import CustomModal from 'src/components/CustomModal';
import CustomUpload from 'src/components/CustomUpload';
import menu from 'src/assets/draw_floor_plan_menu.svg';
import arrow from 'src/assets/draw_floor_plan_arrow.svg';
import OperateMenuViewModel from './viewModel';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class OperateMenu extends React.Component {
  @computed
  get items() {
    return [
      {
        key: 'updateBackground',
        type: 'sub menu',
        label: i18n.t(
          'draw_floor_plan_page_operate_menu_update_background_label'
        ),
        popupClassName: 'updateBackgroundMenu',
        children: [
          {
            key: '1-1',
            label: i18n.t(
              'draw_floor_plan_page_operate_menu_update_background_sub_label'
            ),
            onClick: this.vm.modalVM.open
          }
        ]
      },

      {
        key: 'download',
        type: 'sub menu',
        label: i18n.t(
          'draw_floor_plan_page_operate_menu_update_download_label'
        ),
        popupClassName: 'downloadFloorPlanMenu',
        children: [
          {
            key: 'zh-jpg',
            label: i18n.t(
              'draw_floor_plan_page_operate_menu_update_download_sub_label_1'
            ),
            onClick: this.vm.onDownloadFloorPlan
          },
          {
            key: 'zh-svg',
            label: i18n.t(
              'draw_floor_plan_page_operate_menu_update_download_sub_label_2'
            ),
            onClick: this.vm.onDownloadFloorPlan
          },
          {
            key: 'en-jpg',
            label: i18n.t(
              'draw_floor_plan_page_operate_menu_update_download_sub_label_3'
            ),
            onClick: this.vm.onDownloadFloorPlan
          },
          {
            key: 'en-svg',
            label: i18n.t(
              'draw_floor_plan_page_operate_menu_update_download_sub_label_4'
            ),
            onClick: this.vm.onDownloadFloorPlan
          }
        ]
      }
    ];
  }

  constructor(props) {
    super(props);
    this.vm = new OperateMenuViewModel({
      form: props.form
    });
  }

  render() {
    return (
      <div className={styles.container}>
        <Dropdown
          overlayClassName="drawFloorPlanMenu"
          menu={{ items: this.items }}
        >
          <div className={styles.button}>
            <div className={styles.imageContainer}>
              <img className={styles.menuImage} src={menu} alt="menu" />
              <img className={styles.arrowImage} src={arrow} alt="arrow" />
            </div>
          </div>
        </Dropdown>

        <CustomModal
          title={i18n.t(
            'draw_floor_plan_page_operate_menu_update_background_modal_title'
          )}
          vm={this.vm.modalVM}
          footer
          onOk={this.vm.onOk}
          cancelDisabled={this.vm.disabled.modal}
          okDisabled={this.vm.disabled.modal}
          afterClose={this.vm.onAfterClose}
        >
          <Form form={this.vm.form} disabled={this.vm.disabled.form}>
            <Form.Item
              name="background"
              rules={[
                {
                  required: true,
                  message: i18n.t(
                    'draw_floor_plan_page_operate_menu_update_background_modal_required'
                  )
                }
              ]}
            >
              <CustomUpload
                accept="image/jpeg,image/jpg"
                fileList={this.vm.fileList}
                onChange={this.vm.onFileChange}
                onFileRemove={this.vm.onAfterClose}
                title={i18n.t(
                  'draw_floor_plan_page_operate_menu_update_background_modal_upload_title'
                )}
                subtitle={i18n.t(
                  'draw_floor_plan_page_operate_menu_update_background_modal_upload_subtitle'
                )}
              />
            </Form.Item>
          </Form>
        </CustomModal>
      </div>
    );
  }
}

OperateMenu.propTypes = {
  form: PropTypes.object.isRequired
};

OperateMenu.defaultProps = {};

export default withForm(OperateMenu);
