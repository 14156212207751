import { announcement } from 'src/api';
import AnnouncementModel from 'src/models/response/Announcement';

export default class AnnouncementService {
  static async getAnnouncementList(data, params) {
    const res = await announcement.getAnnouncementList(data, params);

    return { list: res.data.result.list.map((el) => AnnouncementModel.fromRes(el)), anchor: res.data.result.anchor };
  }

  static async getAnnouncementDetail(id) {
    const res = await announcement.getAnnouncementDetail(id);

    return res.data.result;
  }

  static async createAnnouncement(data) {
    await announcement.createAnnouncement(data);
  }

  static async updateAnnouncement(id, data) {
    await announcement.updateAnnouncement(id, data);
  }

  static async toggleAnnouncementActive(id) {
    await announcement.toggleAnnouncementActive(id);
  }

  static async deleteAnnouncement(id) {
    await announcement.deleteAnnouncement(id);
  }
}
