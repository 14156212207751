import { makeObservable, observable, action, computed, reaction, runInAction } from 'mobx';
import ExhibitionService from 'src/services/exhibition';
import AnnouncementService from 'src/services/announcement';
import statusStore from 'src/stores/status';

import AnnouncementEditDrawerViewModel from './components/Drawer/viewModel';

export default class TemplatePageViewModel {
  @observable id = null;
  @observable name = '展覽';
  @observable announcements = [];

  @observable selectedStatus = 'all';
  @observable sortBy = 'dateDesc';

  @observable router = null;
  @observable anchor = null;

  @observable drawerViewModel = new AnnouncementEditDrawerViewModel(this);

  constructor() {
    makeObservable(this);

    this.init();
  }

  @action init = () => {
    const once = reaction(
      () => this.router,
      async (router) => {
        if (router.params.id && this.id !== router.params.id) {

          this.id = router.params.id;
          statusStore.setLoading();
          await Promise.all([
            this.getAnnouncements(),
            this.getExhibitionDetail()
          ]);
          statusStore.setCompleted();
          once();
        }
      }
    );
  };

  @action didMount = (router) => {
    this.router = router;
  };

  @action getExhibitionDetail = async () => {
    try {
      const { exhibitionName } = await ExhibitionService.getAnnouncementExhibitionDetail(this.id);
      runInAction(() => {
        this.name = exhibitionName;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action getAnnouncements = async () => {
    const params = {
      ...(this.anchor && { anchor: this.anchor })
    };
    const data = {
      exhibitionId: this.id,
      ...(this.selectedStatus !== 'all' && { isSent: this.selectedStatus }),
      ...(this.sortBy !== 'dateDesc' && { sortBy: this.sortBy })
    };
    try {
      const { list, anchor } = await AnnouncementService.getAnnouncementList(data, params);

      runInAction(() => {
        this.announcements = [...this.announcements, ...list];
        this.anchor = anchor;
      });
    } catch (error) {
      console.log(error);
    }
  };

  @action onStatusSelect = async (e) => {
    this.selectedStatus = e;

    this.anchor = null;
    this.announcements = [];

    statusStore.setLoading();
    await this.getAnnouncements();
    statusStore.setCompleted();
  };

  @action onSortChange = async () => {
    if (this.sortBy === 'dateDesc') {
      this.sortBy = 'dateAsc';
    } else {
      this.sortBy = 'dateDesc';
    }

    this.anchor = null;
    this.announcements = [];

    statusStore.setLoading();
    await this.getAnnouncements();
    statusStore.setCompleted();
  };


  @action afterConfirm = async () => {
    this.selectedStatus = 'all';
    this.sortBy = 'dateDesc';
    this.anchor = null;
    this.announcements = [];

    await this.getAnnouncements();
    statusStore.setCompleted();
  };



  @action onDelete = async (id) => {
    try {
      await AnnouncementService.deleteAnnouncement(id);
      await this.afterConfirm();
    } catch (error) {
      console.log(error);
    }
  };

  @action toggleActive = async (id) => {
    try {
      await AnnouncementService.toggleAnnouncementActive(id);
      await this.afterConfirm();
    } catch (error) {
      console.log(error);
    }
  };
}
