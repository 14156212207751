class ExhibitorDataModel {
  constructor(props) {
    this.id = String(props.id);

    this.name = props.name;
    this.zhName = props.zhName || '';
    this.enName = props.enName || '';

    this.boothNumber = props.standNumber;

    this.tags = props.tags.map((item) => item);

    this.mapId = props.mapId || '';

    // 展覽 id
    this.exhibitionId = props.exhibitionId;
  }
}

export default ExhibitorDataModel;
