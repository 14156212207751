import { OBJECT_TYPES } from '../global/constants';

class TextDataModel {
  constructor(props) {
    this.id = props.id;
    this.type = props.type;
    this.language = props.language;

    this.content = props.content;

    this.translateX = props.translateX || 0;
    this.translateY = props.translateY || 0;

    this.fill = props.fill || '#000000';

    this.fontSize = props.fontSize || 14;
    this.fontFamily = props.fontFamily;
  }
}

export default TextDataModel;
