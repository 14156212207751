import { request, getHost, getHeaders } from './utils';

export const getAnnouncementList = (data, params) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/announcement`,
    headers: getHeaders(),
    data,
    ...(Object.keys(params).length > 0 ? { params } : {})
  };
  return request(options);
};

export const getAnnouncementDetail = (id) => {
  const options = {
    method: 'get',
    url: `${getHost()}/api/v1/announcement/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

export const createAnnouncement = (data) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/announcement/create`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const updateAnnouncement = (id, data) => {
  const options = {
    method: 'put',
    url: `${getHost()}/api/v1/announcement/${id}`,
    headers: getHeaders(),
    data
  };
  return request(options);
};

export const toggleAnnouncementActive = (id) => {
  const options = {
    method: 'post',
    url: `${getHost()}/api/v1/announcement/${id}/toggle`,
    headers: getHeaders()
  };
  return request(options);
};

export const deleteAnnouncement = (id) => {
  const options = {
    method: 'delete',
    url: `${getHost()}/api/v1/announcement/${id}`,
    headers: getHeaders()
  };
  return request(options);
};

