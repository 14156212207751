import {
  makeObservable,
  observable,
  action,
  computed,
  runInAction
} from 'mobx';
import FloorPlanService from 'src/services/floorPlanService';
import statusStore from 'src/stores/status';
import ConfirmModalViewModel from 'src/components/ConfirmModal/viewModel';
import { delay } from 'src/utils';
import FloorPlanListItemViewModel from './viewModels/FloorPlanListItemViewModel';
import CreateFloorPlanModalViewModel from './components/CreateFloorPlanModal/viewModel';

class MapExhibitionPageViewModel {
  @observable searchKeyword = '';
  @observable editId = null;
  @observable data = [];
  @observable searchTags = [];

  anchor = undefined;

  @observable isAwait = false;

  removeId = null;

  createVM = new CreateFloorPlanModalViewModel();
  removeModalVM = new ConfirmModalViewModel();

  @computed
  get check() {
    return {
      checkEditId: !!this.editId,
      hasData: !!this.data.length
    };
  }

  @computed
  get disabled() {
    return {
      search: this.isAwait,
      deleteModal: this.isAwait,
      create: this.isAwait
    };
  }

  @computed
  get merge() {
    const maps = this.data.filter((item) => item.chose).map((item) => item.id);
    const checkLength = maps.length < 2;

    return {
      disabled: this.isAwait || checkLength,
      maps
    };
  }

  @computed
  get choseFloorPlans() {
    return this.data.filter((vm) => vm.chose);
  }

  constructor() {
    makeObservable(this);
  }

  didMount = () => {
    this.onScrollEnd();
  };

  @action onChangeSearchKeyword = (event) => {
    this.searchKeyword = event.target.value;
  };

  @action onSearch = () => {
    this.reset();
    this.getMapListAPI();
  };

  onOpenMerge = () => {
    this.createVM.openMerge(this.merge.maps);
  };

  onCreated = () => {
    this.reset();
    this.getMapListAPI();
  };

  @action setEditId = (val) => {
    this.editId = val;
  };

  // 刪除地圖
  onRemoveFloorPlan = (id) => {
    this.removeId = id;
    this.removeModalVM.open();
  };

  onRemoveFloorPlanConfirm = () => {
    this.deleteMapAPI(this.removeId);
  };

  onScrollEnd = () => {
    if (!this.isAwait && this.anchor !== null) {
      this.getMapListAPI();
    }
  };

  @action reset = () => {
    this.data = [];
    this.anchor = undefined;
  };

  @action getMapListAPI = async () => {
    this.isAwait = true;

    try {
      const checkSearchKeyword = !!this.searchKeyword.trim();
      const keyword = checkSearchKeyword
        ? this.searchKeyword.trim()
        : undefined;
      const res = await FloorPlanService.getMapList({
        keyword,
        anchor: this.anchor
      });

      runInAction(() => {
        const data = res.data.map(
          (item) => new FloorPlanListItemViewModel(item)
        );

        this.data = [...this.data, ...data];
        this.anchor = res.anchor;
      });
    } catch (error) {
      console.log('MapExhibitionPageViewModel', 'getMapListAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };

  @action deleteMapAPI = async (id) => {
    this.isAwait = true;

    try {
      const res = await FloorPlanService.deleteMap({ id });

      runInAction(() => {
        this.data = this.data.filter((item) => item.id !== id);
        this.removeModalVM.close();
      });
    } catch (error) {
      console.log('MapExhibitionPageViewModel', 'deleteMapAPI', error);
    } finally {
      runInAction(() => {
        this.isAwait = false;
      });
    }
  };
}

export default MapExhibitionPageViewModel;
