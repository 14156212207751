import { OBJECT_ZINDEXS } from '../../global/constants';
import Get from './Get';
import Merge from './Merge';
import Set from './Set';
import Delete from './Delete';
import Render from './Render';

class Group {
  id = null;
  type = null;

  view = null;

  rects = [];
  companies = {};

  boothNumber = null;

  // 文字地標
  markText = {};
  markIcon = null;

  // 專區名字
  areaName = {};

  normalBorder = null;
  activeBorder = null;

  context = null;

  // 拖移使用
  basicPosition = { x: 0, y: 0 };

  isActive = false;

  // 拆模組
  get = null;
  merge = null;
  set = null;
  delete = null;
  render = null;

  constructor(props) {
    this.id = props.data.id;
    this.type = props.data.type;
    this.context = props.context;

    // render 需要 context, 所以寫在這裡
    this.get = new Get({ Group, parent: this });
    this.merge = new Merge(this);
    this.set = new Set(this);
    this.delete = new Delete(this);
    this.render = new Render(this);

    this.render.renderView(props.data);
    this.render.renderRects(props.data.rects);
    this.render.renderBorder();
    this.render.renderCompanies(props.data.companies);
    this.render.renderBoothNumber(props.data.boothNumber);
    this.render.renderMarkText(props.data.markText);
    this.render.renderMarkIcon(props.data.markIcon);
    this.render.renderAreaName(props.data.areaName);
  }

  // 被選中
  active = () => {
    if (this.context.store.check.isEdit) {
      this.normalBorder.visible = false;
      this.activeBorder.visible = true;
    }

    this.get.flat.children.forEach((item) => item.active());

    this.view.zIndex = OBJECT_ZINDEXS.group.active;
    this.isActive = true;
  };

  // 取消選取
  inactive = () => {
    if (this.context.store.check.isEdit) {
      this.normalBorder.visible = true;
      this.activeBorder.visible = false;
    }

    this.get.flat.children.forEach((item) => item.inactive());

    this.view.zIndex = OBJECT_ZINDEXS.group.normal;
    this.isActive = false;
  };

  switchLanguage = () => {
    this.get.flatCompanies.texts.forEach((item) => {
      item.hide();
      item.show();
    });

    this.get.flatMarkText.values.forEach((item) => {
      item.hide();
      item.show();
    });

    this.get.flatAreaName.values.forEach((item) => {
      item.hide();
      item.show();
    });
  };

  // 合併
  mergeRects = (val) => {
    this.rects = val;
    this.render.updateBorder();
  };

  mergeCompanies = (val) => {
    this.companies = val;
  };

  mergeBoothNumber = (val) => {
    this.boothNumber = val;
  };

  setView = (val) => {
    this.view = val;
  };

  setBasicPosition = (val) => {
    this.basicPosition = val;
  };

  setRects = (val) => {
    this.rects = val;
  };

  setNormalBorder = (val) => {
    this.normalBorder = val;
  };

  setActiveBorder = (val) => {
    this.activeBorder = val;
  };

  // 參展商
  setCompanies = (val) => {
    this.companies = val;
  };

  // 攤位編號
  setBoothNumber = (val) => {
    this.boothNumber = val;
  };

  // 文字地標
  setMarkText = (val) => {
    this.markText = val;
  };

  // 圖片地標
  setMarkIcon = (val) => {
    this.markIcon = val;
  };

  // 專區名字
  setAreaName = (val) => {
    this.areaName = val;
  };

  // 搜尋廠商, 專區名稱, 地標 ...
  search = (id) => {
    const company = !!this.companies[id];

    const boothNumber = this.boothNumber?.search(id);

    const markText = this.get.flatMarkText.values.find((item) =>
      item.search(id));

    const markIcon = this.markIcon?.search(id);

    const areaName = this.get.flatAreaName.values.find((item) =>
      item.search(id));

    return company || boothNumber || markText || markIcon || areaName;
  };

  // 拖移
  move = (diff) => {
    const position = {
      x: this.basicPosition.x + diff.x,
      y: this.basicPosition.y + diff.y
    };

    this.view.x = position.x;
    this.view.y = position.y;

    this.get.flat.children.forEach((item) => item.followMove());
  };

  moveFormat = () => {
    const position = {
      x: this.context.formatPointToSize(this.view.x, 'round'),
      y: this.context.formatPointToSize(this.view.y, 'round')
    };

    this.view.x = position.x;
    this.view.y = position.y;

    this.get.flat.children.forEach((item) => item.followMove());
  };

  resetPosition = () => {
    this.view.x = this.basicPosition.x;
    this.view.y = this.basicPosition.y;

    this.get.flat.texts.forEach((item) => item.resetTranslate());
    this.markIcon?.resetTranslate();
  };

  updateBasicPosition = () => {
    this.basicPosition = { x: this.view.x, y: this.view.y };

    this.get.flat.texts.forEach((item) => item.updateBasicTranslate());
    this.markIcon?.updateBasicTranslate();
  };

  addChildToMap = () => {
    this.context.map.addChild(this.view);
    this.context.store.addGroup(this.id, this);

    this.get.flat.texts.forEach((item) => item.addChildToMap());
    this.markIcon?.addChildToMap();
  };

  remove = () => {
    this.context.store.removeGroup(this.id);
    this.context.map.removeChild(this.view);

    this.get.flat.texts.forEach((item) => item.removeChildToMap());

    this.markIcon?.removeChildToMap();

    this.view.destroy(true);
  };
}

export default Group;
