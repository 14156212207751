import * as PIXI from 'pixi.js';
import RBush from 'rbush';
import { COLORS, OBJECT_TYPES, OBJECT_ZINDEXS } from '../../global/constants';

class Rect {
  group = null;
  type = OBJECT_TYPES.rect;
  view = new PIXI.Sprite(PIXI.Texture.WHITE);

  // 原本的顏色
  tint = null;

  get res() {
    return {
      x: this.view.x,
      y: this.view.y,
      width: this.view.width,
      height: this.view.height,
      tint: this.tint,
      minX: this.minX,
      maxX: this.maxX,
      minY: this.minY,
      maxY: this.maxY
    };
  }

  get minX() {
    return this.view.x + this.group.view.x;
  }

  get maxX() {
    return this.minX + this.view.width;
  }

  get minY() {
    return this.view.y + this.group.view.y;
  }

  get maxY() {
    return this.minY + this.view.height;
  }

  get lines() {
    const gridSize = this.group.context.store.gridSize;

    const minX = this.view.x;
    const maxX = minX + this.view.width;

    const minY = this.view.y;
    const maxY = minY + this.view.height;

    const res = [];

    for (let x = minX; x < maxX; x += gridSize) {
      res.push({ x1: x, y1: minY, x2: x + gridSize, y2: minY });
      res.push({ x1: x, y1: maxY, x2: x + gridSize, y2: maxY });
    }

    for (let y = minY; y < maxY; y += gridSize) {
      res.push({ x1: minX, y1: y, x2: minX, y2: y + gridSize });
      res.push({ x1: maxX, y1: y, x2: maxX, y2: y + gridSize });
    }

    return res;
  }

  constructor(props) {
    this.group = props.group;
    this.context = props.context;

    this.init(props.data);
  }

  init = (data) => {
    this.view.width = data.width;
    this.view.height = data.height;

    this.view.x = data.x;
    this.view.y = data.y;

    // 複製貼上或新增
    this.view.tint = data.tint;
    this.view.zIndex = OBJECT_ZINDEXS.rect;

    this.view.interactive = true;

    if (this.context.store.check.isEdit) {
      this.view.on('mousedown', this.onMousedown);
      this.view.on('mouseup', this.onMouseup);
      this.view.on('rightup', this.onRightMouseup);
    } else {
      this.view.on('pointerdown', this.onTouchstart);
      this.view.on('pointerup', this.onTouchend);
    }

    this.tint = data.tint;
  };

  setColor = (val) => {
    this.view.tint = val;
    this.tint = val;
  };

  setHighlight = (val) => {
    this.view.tint = val || this.tint;
  };

  // 重疊
  checkOverlap = (position) => {
    const tree = new RBush();
    const sp = {
      minX: position.minX + 1,
      minY: position.minY + 1,
      maxX: position.maxX - 1,
      maxY: position.maxY - 1
    };

    tree.insert(this);

    const search = tree.search(sp);

    return !!search.length;
  };

  // 相鄰
  checkAdjacent = (rect) => {
    const tree = new RBush();

    tree.insert(this);

    const search1 = tree.search({
      minX: rect.minX + 1,
      maxX: rect.maxX - 1,
      minY: rect.minY,
      maxY: rect.maxY
    });
    const search2 = tree.search({
      minX: rect.minX,
      maxX: rect.maxX,
      minY: rect.minY + 1,
      maxY: rect.maxY - 1
    });

    return !!search1.length || !!search2.length;
  };

  checkTint = (rect) => {
    return (
      this.view.tint.toLocaleUpperCase() === rect.view.tint.toLocaleUpperCase()
    );
  };

  onTouchstart = (event) => {
    this.context.onChildTouchstartFromReadSelect(event, this);
  };

  onTouchend = (event) => {
    this.context.onChildTouchendFromReadSelect(event, this);
  };

  onMousedown = (event) => {
    this.context.onMousedownFromDragObject(event, this);
    this.context.closeMenu();
  };

  onMouseup = (event) => {
    this.context.onMouseupFromSelect(event, this);
    this.context.onMouseupFromStamp(event, this);
  };

  onRightMouseup = (event) => {
    this.context.onChildRightMouseupFromMenu(event);
  };

  addChildToGroup = () => {
    this.group.view.addChild(this.view);
  };
}

export default Rect;
