import origin from './countryOrigin.json';

export const CountryListTW = origin.map((el) => ({
  value: el.CountryCode,
  label: el.Country
}));

export const CountryListEN = origin.map((el) => ({
  value: el.CountryCode,
  label: el.eCountry
}));
