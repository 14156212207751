import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Modal, Button } from 'antd';
import clsx from 'clsx';
import styles from './styles.module.scss';
import './reset.scss';

function CustomModal(props) {
  const {
    title,
    children,
    vm,
    footer,
    onOk,
    okText,
    cancelDisabled,
    okDisabled,
    ...ps
  } = props;

  return (
    <Modal
      open={vm.isOpen}
      centered
      wrapClassName="customModal"
      closable={false}
      title={null}
      footer={null}
      {...ps}
    >
      <div className={styles.titleContainer}>
        <div className={styles.title}>{title}</div>
      </div>

      <div className={styles.contentContainer}>
        <div className={styles.children}>{children}</div>
      </div>

      {footer && (
        <div className={styles.footerContainer}>
          <div className={styles.buttons}>
            <div className={styles.buttonContainer}>
              <Button
                className={styles.footerButton}
                onClick={vm.close}
                disabled={cancelDisabled}
              >
                取消
              </Button>
            </div>

            <div className={styles.buttonContainer}>
              <Button
                type="primary"
                className={clsx([styles.footerButton, styles.okButton])}
                onClick={onOk}
                disabled={okDisabled}
              >
                {okText}
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
}

CustomModal.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  vm: PropTypes.object.isRequired,
  footer: PropTypes.bool,
  onOk: PropTypes.func,
  cancelDisabled: PropTypes.bool,
  okDisabled: PropTypes.bool,
  okText: PropTypes.string,
  width: PropTypes.number
};

CustomModal.defaultProps = {
  title: '標題',
  children: null,
  footer: false,
  onOk: () => {},
  cancelDisabled: false,
  okDisabled: false,
  okText: '新增',
  width: 600
};

export default observer(CustomModal);
