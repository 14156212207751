import React from 'react';
import { RouterProvider, Navigate, createBrowserRouter } from 'react-router-dom';
import { ROUTES } from 'src/constants';
import qs from 'qs';
import { Helmet } from 'react-helmet';
import UserService from 'src/services/user';
import { ConfigProvider } from 'antd';

import { AppStateContext } from 'src/stores';
import Theme from 'src/constants/Theme.json';

import HomePage from './HomePage';
import LoginPage from './LoginPage';
import AnnouncementExhibitionPage from './AnnouncementExhibitionPage';
import AnnouncementPage from './AnnouncementPage';
import MapExhibitionPage from './MapExhibitionPage';
import DrawFloorPlanPage from './DrawFloorPlanPage';
import ForWebViewFloorPlanPage from './ForWebViewFloorPlanPage';
import ViewPortPage from './ViewPortPage';

const router = createBrowserRouter(
  [
    {
      path: '',
      element: <HomePage />,
      errorElement: <Navigate to="/login" replace />,
      loader: UserService.getProfile,
      id: 'main',
      children: [
        {
          path: `${ROUTES.Announcement}/:id`,
          element: <AnnouncementPage />
        },
        {
          path: ROUTES.Announcement,
          element: <AnnouncementExhibitionPage />
        },
        {
          path: ROUTES.Map,
          element: <MapExhibitionPage />
        },
        {
          path: `${ROUTES.Map}/:id`,
          element: <DrawFloorPlanPage />
        }
      ]
    },
    {
      path: '/viewport',
      element: <ViewPortPage />
    },
    {
      path: '/for-webview-floor-plan',
      element: <ForWebViewFloorPlanPage />
    },
    {
      path: '/login',
      element: <LoginPage />
    },
    {
      path: '*',
      element: <Navigate to="/" replace />

    }
  ],
  {
    basename: '/'
  }
);


class PagesRoot extends React.Component {
  static contextType = AppStateContext;

  render() {
    return (
      <ConfigProvider
        theme={Theme}
      >
        <Helmet>
          <title>展昭國際</title>
        </Helmet>
        <RouterProvider router={router} />
      </ConfigProvider>
    );
  }
}

export default PagesRoot;
