import FloorPlanAPIs from 'src/api/floorPlanAPIs';
import FloorPlaListItemDataModel from 'src/models/response/FloorPlaListItemDataModel';
import FloorPlanTemplateDataModel from 'src/models/response/FloorPlanTemplateDataModel';
import FloorPlanDataModel from 'src/models/response/FloorPlanDataModel';
import TagDataModel from 'src/models/response/TagDataModel';
import PoolTagDataModel from 'src/models/response/PoolTagDataModel';

class FloorPlanService {
  static async getMapList({ keyword, limit, anchor }) {
    const res = await FloorPlanAPIs.getMapList({ keyword, limit, anchor });

    return {
      data: res.data.result.list.map(
        (item) => new FloorPlaListItemDataModel(item)
      ),
      anchor: res.data.result.anchor
    };
  }

  static async getMapTemplateList() {
    const res = await FloorPlanAPIs.getMapTemplateList();

    return {
      data: res.data.list.map((item) => new FloorPlanTemplateDataModel(item))
    };
  }

  static async postMapCreate({
    zhName,
    enName,
    exhibitions,
    template,
    background
  }) {
    const res = await FloorPlanAPIs.postMapCreate({
      zhName,
      enName,
      exhibitions,
      template,
      background
    });

    return {
      data: {
        id: res.data.result.id
      }
    };
  }

  static async putMapUpdate({
    id,
    zhName,
    enName,
    active,
    tags,
    map,
    background
  }) {
    const res = await FloorPlanAPIs.putMapUpdate({
      id,
      zhName,
      enName,
      active,
      tags,
      map,
      background
    });

    return {
      data: res.data
    };
  }

  static async deleteMap({ id }) {
    const res = await FloorPlanAPIs.deleteMap({
      id
    });

    return {
      data: res.data
    };
  }

  static async getMapDetail({ id }) {
    const res = await FloorPlanAPIs.getMapDetail({ id });

    return {
      data: new FloorPlanDataModel(res.data.result)
    };
  }

  // 預設標籤

  // 固定標籤的標籤池
  static async getMapTagListDefault() {
    const res = await FloorPlanAPIs.getMapTagListDefault();

    return {
      data: res.data.result.map((item) => new PoolTagDataModel(item))
    };
  }

  // 地圖已經選擇的標籤，固定 + 自訂
  static async getMapTagList({ mapId }) {
    const res = await FloorPlanAPIs.getMapTagList({ mapId });

    return {
      data: res.data.result.list.map((item) => new TagDataModel(item))
    };
  }

  // 更新選擇的固定標籤
  static async putMapTagDefault({ mapId, tags }) {
    const res = await FloorPlanAPIs.putMapTagDefault({ mapId, tags });

    return {
      data: res.data
    };
  }


  // 新增自訂標籤
  static async postMapCustomTag({ mapId, name }) {
    const res = await FloorPlanAPIs.postMapCustomTag({ mapId, name });

    return {
      data: new TagDataModel(res.data)
    };
  }

  // 編輯自訂標籤
  static async putMapCustomTag({ mapId, tagId, name }) {
    const res = await FloorPlanAPIs.putMapCustomTag({ mapId, tagId, name });

    return {
      data: res.data
    };
  }

  // 刪除自訂標籤
  static async deleteMapCustomTag({ mapId, tagId }) {
    const res = await FloorPlanAPIs.deleteMapCustomTag({ mapId, tagId });

    return {
      data: res.data
    };
  }

  // 檢查地圖是否有上架
  static async getMapActive({ mapId }) {
    const res = await FloorPlanAPIs.getMapActive({ mapId });

    return {
      data: {
        id: res.data.result.id,
        active: res.data.result.active
      }
    };
  }
}

export default FloorPlanService;
