import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { Layout, Breadcrumb } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import withRouter from '../withRouter';

import styles from './styles.module.scss';

const Header = observer(({ backTo, title, breadcrumb, router }) => {
  return (
    <Layout.Header
      className={styles.header}
    >
      <Breadcrumb
        items={breadcrumb}
      />
      <div className={styles.titleContainer}>
        {
          backTo
          && (
            <ArrowLeftOutlined
              onClick={() => router.navigate(backTo)}
            />
          )
        }
        <div className={styles.title}>
          {title}
        </div>
      </div>
    </Layout.Header>
  );
});

Header.propTypes = {
  backTo: PropTypes.string,
  title: PropTypes.string,
  breadcrumb: PropTypes.array,
  router: PropTypes.object
};

Header.defaultProps = {
  backTo: null,
  title: '',
  breadcrumb: [],
  router: {}
};

export default withRouter(Header);
