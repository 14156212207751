import { OBJECT_TYPES } from '../../global/constants';
import Render from './Render';

class Text {
  id = null;
  type = null;
  language = null;

  group = null;

  view = null;
  text = null;
  border = null;

  context = null;

  // 拖移使用
  translate = { x: 0, y: 0 };
  basicTranslate = { x: 0, y: 0 };

  isActive = false;

  // 原本的顏色
  fill = null;

  // 拆模組
  render = new Render(this);

  get content() {
    return this.text.text;
  }

  // 依照 group 定位
  get position() {
    return {
      x: this.group.view.x + this.translate.x,
      y: this.group.view.y + this.translate.y
    };
  }

  get check() {
    return {
      language: this.language === this.context.store.language,
      content: !!this.text.text.trim()
    };
  }

  get res() {
    return {
      id: this.id,
      type: this.type,
      language: this.language,
      content: this.text.text,
      translateX: this.translate.x,
      translateY: this.translate.y,
      fontSize: this.text.style.fontSize,
      fontFamily: this.text.style.fontFamily,
      fill: this.fill
    };
  }

  get resForSVG() {
    const content = this.text.text;
    let y = 0;

    switch (this.type) {
      // 匯出 svg 時, 攤位編號的 y 軸需要特條
      case OBJECT_TYPES.boothNumber: {
        const ratio = 0.775;
        const pad = Number((this.text.style.fontSize * ratio).toFixed(4));

        y = this.position.y + pad;

        break;
      }

      default: {
        y = this.position.y + this.text.style.fontSize;
        break;
      }
    }

    return {
      x: this.position.x,
      y,
      content,
      visible: this.view.visible,
      fontSize: this.text.style.fontSize,
      fontFamily: this.text.style.fontFamily,
      fill: this.fill
    };
  }

  // DragObject 使用
  get validMove() {
    return (
      this.translate.x !== this.basicTranslate.x
      || this.translate.y !== this.basicTranslate.y
    );
  }

  get minX() {
    return this.view.x;
  }

  get maxX() {
    return this.minX + this.text.width;
  }

  get minY() {
    return this.view.y;
  }

  get maxY() {
    return this.minY + this.text.height;
  }

  constructor(props) {
    this.id = props.data.id;
    this.type = props.data.type;
    this.language = props.data.language;

    this.group = props.group;
    this.context = props.context;

    this.render.renderView(props.data);
    this.render.renderText(props.data);
    this.render.renderBorder();
    this.render.updateBorder();
  }

  // 被選中
  active = () => {
    this.isActive = true;
    this.border.visible = true;
  };

  // 取消選取
  inactive = () => {
    this.isActive = false;
    this.border.visible = false;
  };

  // 文字顯示隱藏
  show = () => {
    this.view.visible = this.check.content && this.check.language;
  };

  hide = () => {
    this.view.visible = false;
  };

  // 修改文字顏色
  setColor = (val) => {
    this.text.style.fill = val;
    this.fill = val;
  };

  // app 使用
  setHighlight = (val) => {
    this.text.style.fill = val || this.fill;
  };

  // 搜尋
  search = (id) => this.id.includes(id);

  // 拖移使用
  move = (diff) => {
    const position = {
      x: this.basicTranslate.x + diff.x,
      y: this.basicTranslate.y + diff.y
    };

    this.translate = position;

    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  followMove = () => {
    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  resetTranslate = () => {
    this.translate = {
      x: this.basicTranslate.x,
      y: this.basicTranslate.y
    };

    this.view.x = this.position.x;
    this.view.y = this.position.y;
  };

  updateBasicTranslate = () => {
    this.basicTranslate = {
      x: this.translate.x,
      y: this.translate.y
    };
  };

  // 專區名稱與地標使用
  updateId = (val) => {
    this.id = val;
  };

  updateContent = (content) => {
    this.text.text = content;

    this.render.updateBorder();

    switch (this.type) {
      // 沒有文字需要隱藏, 不隱藏會有 active 邊框
      case OBJECT_TYPES.company:
      case OBJECT_TYPES.areaName:
      case OBJECT_TYPES.markText: {
        this.view.visible = this.check.content && this.check.language;
        break;
      }

      case OBJECT_TYPES.boothNumber: {
        this.view.visible = this.check.content;
        break;
      }

      default:
        break;
    }
  };

  updateFontSize = (val = 14) => {
    this.text.style.fontSize = Number(val);

    this.render.updateBorder();
  };

  // 將 val 轉為 1 比例的值
  formatValueToBasic = (val) => {
    return val * (1 / this.context.viewport.scaled);
  };

  onMousedown = (event) => {
    this.context.onMousedownFromDragObject(event, this);
    this.context.closeMenu();
  };

  onMouseup = (event) => {
    this.context.onMouseupFromSelect(event, this);
    this.context.onMouseupFromStamp(event, this);
  };

  onTouchstart = (event) => {
    this.context.onChildTouchstartFromReadSelect(event, this);
  };

  onTouchend = (event) => {
    this.context.onChildTouchendFromReadSelect(event, this);
  };

  onRightMouseup = (event) => {
    this.context.onChildRightMouseupFromMenu(event);
  };

  addChildToMap = () => {
    this.context.map.addChild(this.view);
  };

  removeChildToMap = () => {
    this.context.map.removeChild(this.view);

    this.view.destroy(true);
  };

  setView = (val) => {
    this.view = val;
  };

  setText = (val) => {
    this.text = val;
  };

  setBorder = (val) => {
    this.border = val;
  };

  setFill = (val) => {
    this.fill = val;
  };

  setTranslate = (val) => {
    this.translate = val;
  };

  setBasicTranslate = (val) => {
    this.basicTranslate = val;
  };
}

export default Text;
