class Resume {
  parent = null;

  constructor(parent) {
    this.parent = parent;
  }

  resume = (type) => {
    switch (type) {
      case 'dragMap':
        this.parent.context.viewport.plugins.resume('drag');
        this.parent.context.dragMapControl.resume();
        break;

      case 'wheel':
        this.parent.context.viewport.plugins.resume('wheel');
        break;

      case 'pinch':
        this.parent.context.viewport.plugins.resume('pinch');
        break;

      // 沒有要蓋章時, 才可以恢復選取
      case 'select':
        if (!this.parent.context.stamp.isStamp) {
          this.parent.context.select.resume();
        }
        break;

      case 'readSelect':
        this.parent.context.readSelect.resume();
        break;

      case 'createGroup':
        this.parent.context.createGroup.resume();
        break;

      case 'menu':
        this.parent.context.menu.resume();
        break;

      case 'dragObject':
        this.parent.context.dragObject.resume();
        break;

      case 'stamp':
        this.parent.context.stamp.resume();
        break;

      case 'history':
        this.parent.context.history.resume();
        break;

      default:
        break;
    }
  };
}

export default Resume;
