import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer, Observer } from 'mobx-react';
import i18n from 'src/i18n';
import { Input, Select, Table, Button, Tooltip } from 'antd';
import { Waypoint } from 'react-waypoint';

import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons';
import { EExhibitionStatusList, ROUTES } from 'src/constants';
import { ANNOUNCEMENT_EXHIBITION_TABLE } from 'src/constants/table';
import { CountryListTW } from 'src/constants/country';
import withRouter from 'src/components/withRouter';

import Header from 'src/components/Header';

import styles from './styles.module.scss';

import ViewModel from './viewModel';

@observer
class AnnouncementExhibitionPage extends React.Component {
  constructor() {
    super();

    this.viewModel = new ViewModel();
  }

  componentDidMount() {
    this.viewModel.didMount(this.props.router);
  }

  render() {
    return (
      <div className={styles.pageContainer}>
        <Header
          backTo={null}
          title={i18n.t('ann_page_title')}
          breadcrumb={[{ title: i18n.t('ann_exhi_page_breadcrumb') }]}
        />
        <div className={styles.contentContainer}>
          <div className={styles.contentTitleContainer}>
            <div className={styles.contentTitle}>
              {i18n.t('ann_exhi_page_title')}
            </div>
            <div className={styles.contentSubTitle}>
              {i18n.t('ann_exhi_page_sub_title')}
            </div>
          </div>
          <div className={styles.filterContainer}>
            <div className={styles.selectContainer}>
              <div className={styles.selectTitle}>
                {i18n.t('ann_exhi_status_select_title')}
              </div>
              <Select
                className={clsx('select', styles.select)}
                placeholder={i18n.t('ann_exhi_status_select_placeholder')}
                value={this.viewModel.selectedStatus}
                onChange={this.viewModel.onStatusSelect}
                options={EExhibitionStatusList}
              />
            </div>
            <div className={styles.selectContainer}>
              <div className={styles.selectTitle}>
                {i18n.t('ann_exhi_country_select_title')}
              </div>
              <Select
                className={clsx('select', styles.select)}
                showSearch
                optionFilterProp="label"
                placeholder={i18n.t('ann_exhi_country_select_placeholder')}
                value={this.viewModel.selectedCountry}
                onChange={this.viewModel.onCountrySelect}
                options={[
                  {
                    value: 'all',
                    label: i18n.t('common_select_option_all')
                  },
                  ...CountryListTW
                ]}
              />
            </div>
            <Input.Search
              placeholder={i18n.t('ann_exhi_search_placeholder')}
              allowClear={false}
              className={clsx('searchInput', styles.searchInput)}
              value={this.viewModel.search}
              onChange={this.viewModel.onSearchChange}
              onSearch={this.viewModel.onSearch}
            />

          </div>
          <div className={styles.headerContainer}>
            {
              ANNOUNCEMENT_EXHIBITION_TABLE.map((el) => {
                const width = (() => {
                  if (el.key === 'manipulate' || el.key === 'country') {
                    return '12%';
                  }
                  if (el.key === 'time' || el.key === 'name') {
                    return '25%';
                  }
                  return '14%';
                })();
                if (el.key === 'time') {
                  return (
                    <div
                      className={styles.customTitle}
                      style={{ width }}
                      onClick={this.viewModel.onSortChange}
                      key={el.key}
                    >
                      <div className={styles.title}>
                        {el.title}
                      </div>
                      <div className={styles.arrowContainer}>
                        <CaretUpOutlined className={clsx(styles.icon, styles.upIcon, this.viewModel.sortBy === 'dateAsc' && styles.active)} />
                        <CaretDownOutlined className={clsx(styles.icon, styles.downIcon, this.viewModel.sortBy === 'dateDesc' && styles.active)} />
                      </div>
                    </div>
                  );
                }
                return (
                  <div
                    key={el.key}
                    className={styles.headerColumn}
                    style={{ width }}
                  >
                    {el.title}
                  </div>
                );
              })
            }
          </div>
          <div className={clsx('tableContent', styles.tableContainer)}>
            <Table
              dataSource={this.viewModel.exhibitionList}
              rowKey={(record) => record.code}
              pagination={false}
              showHeader={false}
            >
              {
                ANNOUNCEMENT_EXHIBITION_TABLE.map((el) => {
                  if (el.key === 'manipulate') {
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="12%"
                        render={(_, record, index) => {
                          return (
                            <>
                              <Button
                                type="link"
                                onClick={() => this.props.router.navigate(`${ROUTES.Announcement}/${record.code}`)}
                              >
                                {i18n.t('ann_exhi_table_ann_detail')}
                              </Button>
                              {
                                ((this.viewModel.page <= this.viewModel.totalPages) && index === this.viewModel.exhibitionList.length - 1)
                                && (
                                  <Waypoint
                                    onEnter={() => this.viewModel.getExhibition(this.props.router)}
                                  />
                                )
                              }
                            </>
                          );
                        }}
                      />
                    );
                  }
                  if (el.key === 'country') {
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="12%"
                        render={(_, record, index) => {
                          return (
                            <Tooltip
                              title={record.country}
                              placement="topLeft"
                            >
                              <div className={styles.nameColumn}>
                                {record.country}
                              </div>
                            </Tooltip>
                          );
                        }}
                      />
                    );
                  }
                  if (el.key === 'name') {
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="25%"
                        render={(_, record, index) => {
                          return (
                            <Tooltip
                              title={record.name}
                              placement="topLeft"
                            >
                              <div className={styles.nameColumn}>
                                {record.name}
                              </div>
                            </Tooltip>
                          );
                        }}
                      />
                    );
                  }
                  if (el.key === 'time') {
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="25%"
                        render={(_, record, index) => {
                          return (
                            <Tooltip
                              title={record.time}
                              placement="topLeft"
                            >
                              <div className={styles.nameColumn}>
                                {record.time}
                              </div>
                            </Tooltip>
                          );
                        }}
                      />
                    );
                  }
                  if (el.key === 'place') {
                    return (
                      <Table.Column
                        title={null}
                        dataIndex={el.dataIndex}
                        key={el.key}
                        width="13%"
                        render={(_, record, index) => {
                          return (
                            <Tooltip
                              title={record.place}
                              placement="topLeft"
                            >
                              <div className={styles.nameColumn}>
                                {record.place}
                              </div>
                            </Tooltip>
                          );
                        }}
                      />
                    );
                  }
                  return (
                    <Table.Column
                      title={null}
                      dataIndex={el.dataIndex}
                      key={el.key}
                      width="13%"
                      render={(_, record, index) => {
                        return (
                          <Tooltip
                            title={record.code}
                            placement="topLeft"
                          >
                            <div className={styles.nameColumn}>
                              {record.code}
                            </div>
                          </Tooltip>
                        );
                      }}
                    />
                  );
                })
              }
            </Table>

          </div>
        </div>
      </div>
    );
  }
}

AnnouncementExhibitionPage.propTypes = {
  router: PropTypes.object
};

AnnouncementExhibitionPage.defaultProps = {
  router: {}
};

export default withRouter(AnnouncementExhibitionPage);
