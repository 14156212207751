import * as PIXI from 'pixi.js';
import { Button } from '@pixi/ui';
import { FONT_FAMILY } from '../../global/constants';

const normalTextColor = '#000000';
const normalBackground = '#ffffff';
const disabledTextColor = '#bfbfbf';
const activeBackground = '#F5F5F5';

class SelfButton {
  view = null;
  button = null;
  buttonContent = null;
  background = null;
  text = null;

  onClicked = null;

  constructor({
    x = 0,
    y = 0,
    width = 80,
    height = 32,
    fontFamily = FONT_FAMILY,
    text = '按鈕',
    onClicked = () => {}
  }) {
    this.renderView({ x, y });
    this.renderButtonContent({ x, y });
    this.renderBackground({ width, height });
    this.renderText({ width, height, fontFamily, text });

    const button = new Button(this.buttonContent);

    button.onDown.connect(this.onDown);
    button.onUp.connect(this.onUp);

    this.onClicked = onClicked;

    this.button = button;
    this.view.addChild(this.buttonContent);
  }

  onDown = () => {
    this.background.tint = activeBackground;
  };

  onUp = () => {
    this.background.tint = normalBackground;
    this.onClicked();
  };

  onStopPropagation = (event) => event.stopPropagation();

  disabled = () => {
    this.button.enabled = false;
    this.text.style.fill = disabledTextColor;
  };

  reset = () => {
    this.button.enabled = true;

    this.background.tint = normalBackground;
    this.text.style.fill = normalTextColor;
  };

  renderView = ({ x, y }) => {
    const container = new PIXI.Container();

    container.x = x;
    container.y = y;

    container.interactive = true;

    container.on('mousedown', this.onStopPropagation);
    container.on('mousemove', this.onStopPropagation);
    container.on('mouseup', this.onStopPropagation);

    this.view = container;
  };

  renderButtonContent = () => {
    this.buttonContent = new PIXI.Container();
  };

  renderBackground = ({ width, height }) => {
    const background = new PIXI.Sprite(PIXI.Texture.WHITE);

    background.width = width;
    background.height = height;

    this.buttonContent.addChild(background);

    this.background = background;
  };

  renderText = ({ width, height, scaled, fontFamily, text }) => {
    const textIns = new PIXI.Text(text);

    textIns.x = width / 2;
    textIns.y = height / 2;

    textIns.anchor.set(0.5);

    textIns.style.fontSize = 14;
    textIns.style.fontFamily = fontFamily;
    textIns.style.fill = normalTextColor;
    textIns.style.fontWeight = 400;

    this.buttonContent.addChild(textIns);

    this.text = textIns;
  };
}

export default SelfButton;
