import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import statusStore from 'src/stores/status';

import styles from './styles.module.scss';

const Loading = observer(() => {
  return (
    <div className={clsx(styles.loadingContainer, statusStore.isLoading && styles.active)}>
      <div className={styles.curtain} />
      <div className={styles.iconContainer}>
        <svg xmlns="http://www.w3.org/2000/svg" width="160" height="160" viewBox="0 0 80 80">
          <g>
            <rect x="34" y="34" width="12" height="12" rx="2" ry="2" fill="#00bfff">
              <animateTransform attributeName="transform" type="rotate" from="0 40 40" to="360 40 40" dur="1s" repeatCount="indefinite" />
            </rect>
            <rect x="17" y="17" width="12" height="12" rx="2" ry="2" fill="#f08080">
              <animateTransform attributeName="transform" type="rotate" from="0 40 40" to="360 40 40" dur="1.5s" repeatCount="indefinite" />
            </rect>
            <rect x="51" y="51" width="12" height="12" rx="2" ry="2" fill="#32cd32">
              <animateTransform attributeName="transform" type="rotate" from="0 40 40" to="360 40 40" dur="2s" repeatCount="indefinite" />
            </rect>
          </g>
        </svg>
      </div>
    </div>
  );
});

export default Loading;
