import { LANGUAGES, OBJECT_TYPES } from '../global/constants';

const aisles1 = [
  // 1 館 1 樓, 直的街道
  { minX: 1140, minY: 480, maxX: 1320, maxY: 3900 },
  { minX: 2850, minY: 480, maxX: 3030, maxY: 3900 },
  { minX: 4470, minY: 480, maxX: 4650, maxY: 3900 },
  // A 館 1 樓, 橫的街道
  { minX: 510, minY: 2010, maxX: 5460, maxY: 2190 }
];

const aisles2 = [
  // 1 館 4 樓, 直的街道
  { minX: 1200, minY: 480, maxX: 1380, maxY: 3900 },
  { minX: 2910, minY: 480, maxX: 3090, maxY: 3900 },
  { minX: 4440, minY: 480, maxX: 4620, maxY: 3900 },
  // A 館 4 樓, 橫的街道
  { minX: 480, minY: 2010, maxX: 5520, maxY: 2190 }
];

const aisles3 = [
  // 2 館 1 樓, 直的街道
  { minX: 660, minY: 270, maxX: 840, maxY: 4020 },
  { minX: 2910, minY: 270, maxX: 3090, maxY: 4020 },
  // 2 館 1 樓, 橫的街道
  { minX: 120, minY: 1380, maxX: 3630, maxY: 1560 },
  { minX: 120, minY: 2820, maxX: 3630, maxY: 3000 }
];

const aisles4 = [
  // 2 館 4 樓, 直的街道
  { minX: 690, minY: 300, maxX: 870, maxY: 4020 },
  { minX: 2850, minY: 300, maxX: 3030, maxY: 3930 },
  { minX: 2940, minY: 3930, maxX: 3030, maxY: 4020 },
  // 2 館 4 樓, 橫的街道,
  { minX: 120, minY: 1410, maxX: 3690, maxY: 1590 },
  { minX: 120, minY: 2850, maxX: 3690, maxY: 3030 }
];

class Store {
  mode = null;

  col = 200;
  row = 100;
  gridSize = 30;

  data = {
    groups: {},
    pillars: [],
    version: '1.0'
  };

  selects = [];
  copies = [];

  rectColor = '';

  language = 'zh';

  get check() {
    return {
      hasRectColor: !!this.rectColor,
      hasSelects: !!this.selects.length,
      hasCopies: !!this.copies.length,
      isEdit: this.mode === 'edit'
    };
  }

  get flat() {
    const groups = Object.values(this.data.groups);
    const rects = [];
    const texts = [];
    const marks = [];

    groups.forEach((group) => {
      const flat = group.get.flat;

      rects.push(...group.rects);

      texts.push(...flat.texts);

      if (group.markIcon) {
        marks.push(group.markIcon);
      }
    });

    return { groups, rects, texts, marks };
  }

  get flatSelects() {
    const groups = [];
    const rects = [];
    const texts = [];
    const marks = [];

    this.selects.forEach((item) => {
      switch (item.type) {
        case OBJECT_TYPES.group:
          groups.push(item);
          rects.push(...item.rects);
          break;

        case OBJECT_TYPES.company:
        case OBJECT_TYPES.boothNumber:
        case OBJECT_TYPES.markText:
        case OBJECT_TYPES.areaName:
          texts.push(item);
          break;

        case OBJECT_TYPES.markIcon:
          marks.push(item);
          break;

        default:
          break;
      }
    });

    return {
      groups,
      rects,
      texts,
      marks
    };
  }

  get flatCopies() {
    const groups = [];
    const rects = [];

    this.copies.forEach((item) => {
      switch (item.type) {
        case OBJECT_TYPES.group:
          groups.push(item);
          rects.push(...item.rects);
          break;

        default:
          break;
      }
    });

    return {
      groups,
      rects
    };
  }

  // 複製的左上角
  get copyBasic() {
    if (this.check.hasCopies) {
      const groups = this.flatCopies.groups.slice();
      const x = groups.sort((a, b) => a.get.copyBasic.x - b.get.copyBasic.x)[0]
        .get.copyBasic.x;
      const y = groups.sort((a, b) => a.get.copyBasic.y - b.get.copyBasic.y)[0]
        .get.copyBasic.y;

      return {
        x,
        y
      };
    }

    return { x: 0, y: 0 };
  }

  // 合併檢查
  get checkGroupMerge() {
    if (this.flatSelects.groups.length < 2) {
      return false;
    }

    const rects = this.flatSelects.rects;
    const res = this.loopCheckMerge();

    return rects.length === res.length;
  }

  setMode = (val) => {
    this.mode = val;
  };

  setSize = ({ col, row }) => {
    this.col = col;
    this.row = row;
  };

  setRectColor = (val) => {
    this.rectColor = val;
  };

  setLanguage = (val = LANGUAGES.zh) => {
    this.language = val;
  };

  addGroup = (id, data) => {
    this.data.groups[id] = data;
  };

  removeGroup = (id) => {
    this.data.groups[id] = undefined;
    delete this.data.groups[id];
  };

  updatePillars = (val) => {
    this.data.pillars = val;
  };

  updateSelects = (val) => {
    this.selects = val;
  };

  updateCopies = (val) => {
    this.copies = val;
  };

  loopCheckMerge = (target = this.flatSelects.rects[0], res = []) => {
    const rects = this.flatSelects.rects.filter((rect) => {
      if (rect === target) {
        return false;
      }

      const find = res.find((item) => item === rect);

      return !find;
    });

    const filterRects = rects.filter((item) => {
      const isAllAdjacent = item.checkAdjacent(target);
      const isAllSameTint = item.checkTint(target);

      return isAllAdjacent && isAllSameTint;
    });

    let sliceRes = res.slice();

    filterRects.forEach((item) => {
      sliceRes.push(target, item);
      sliceRes = this.loopCheckMerge(item, sliceRes);
    });

    return Array.from(new Set(sliceRes));
  };
}

export default Store;
