import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import clsx from 'clsx';
import seal from 'src/assets/seal_blue.svg';
import position from 'src/assets/position.svg';
import styles from './styles.module.scss';

function Company(props) {
  return (
    <div
      className={clsx([
        styles.container,
        props.vm.check.isAnywherePositioned && styles.positioned,
        props.vm.check.isActive && styles.active
      ])}
    >
      <div className={styles.label}>{props.vm.label}</div>

      {props.vm.check.seal && (
        <div className={styles.buttonContainer}>
          <Button
            type="ghost"
            className={styles.button}
            onClick={props.vm.onSealClick}
          >
            <img src={seal} alt="seal" className={styles.icon} />
          </Button>
        </div>
      )}

      {props.vm.positioned && (
        <div className={styles.buttonContainer}>
          <Button
            type="ghost"
            className={styles.button}
            onClick={props.vm.onPositionClick}
          >
            <img src={position} alt="position" className={styles.icon} />
          </Button>
        </div>
      )}
    </div>
  );
}

Company.propTypes = {
  vm: PropTypes.object.isRequired
};

Company.defaultProps = {};

export default observer(Company);
