import { OBJECT_TYPES, ERRORS } from '../global/constants';

/**
 * 如果已經選取一個攤位, 如何切換選取這個攤位內的東西?
 * 點擊已經選取的東西時, 拖移的 mousedown 會先暫停 select 功能, 然後在 onCaptureViewportMouseup 判斷是否有拖移, Capture 會優先執行, 決定恢復 select, 若恢復則會讓 select mouse up 執行, 達成切換選取的功能
 */

class DragObject {
  isActive = false;
  isPause = false;
  context = null;

  clientDown = null;
  clientMove = null;

  // 上一步
  before = [];
  after = [];

  constructor(context) {
    this.context = context;
  }

  init = () => {
    this.isActive = true;
  };

  destroy = () => {
    window.removeEventListener('mouseup', this.onBubbleWindowMouseup);
  };

  pause = () => {
    this.isPause = true;
  };

  resume = () => {
    this.isPause = false;
  };

  getSelectsRes = () => {
    const mapSelects = this.context.store.selects.map((item) => {
      switch (item.type) {
        case OBJECT_TYPES.group:
          return item.get.res;

        case OBJECT_TYPES.company:
        case OBJECT_TYPES.boothNumber:
        case OBJECT_TYPES.markText:
        case OBJECT_TYPES.markIcon:
        case OBJECT_TYPES.areaName:
          return item.group.get.res;

        default:
          return null;
      }
    });
    const filterGroups = mapSelects.filter((item) => !!item);
    const res = {};

    // 可能會是同樣的 group, 例如: 選取攤位的參展商與攤位編號
    filterGroups.forEach((group) => {
      res[group.id] = group;
    });

    return Object.values(res);
  };

  // eslint-disable-next-line consistent-return
  onMousedown = (event, target) => {
    // 暫停或是沒有選到人
    if (
      !this.isActive
      || this.isPause
      || !this.context.store.check.hasSelects
    ) {
      return false;
    }

    const localClient = this.context.getEventMapPosition(event);

    this.clientDown = localClient;
    this.clientMove = localClient;

    // 點到方塊需要找 group 的 isActive, 其他找自己的 isActive
    if (target.group?.isActive || target.isActive) {
      this.context.viewport.on('mousemove', this.onViewportMousemove);
      this.context.viewport.addEventListener(
        'mouseup',
        this.onCaptureViewportMouseup,
        true
      );
      window.addEventListener('mouseup', this.onBubbleWindowMouseup);

      this.context.pause('select');

      this.before = this.getSelectsRes();
    }
  };

  // eslint-disable-next-line consistent-return
  onViewportMousemove = (event) => {
    if (this.isPause) {
      return false;
    }

    const localClient = this.context.getEventMapPosition(event);
    const diff = {
      x: localClient.x - this.clientDown.x,
      y: localClient.y - this.clientDown.y
    };

    this.clientMove = localClient;

    this.context.store.selects.forEach((item) => item.move(diff));
  };

  // eslint-disable-next-line consistent-return
  onCaptureViewportMouseup = () => {
    if (!this.clientDown) {
      return false;
    }

    const checkX = this.clientDown.x === this.clientMove.x;
    const checkY = this.clientDown.y === this.clientMove.y;

    if (checkX && checkY) {
      this.context.resume('select');
    }
  };

  // eslint-disable-next-line consistent-return
  onBubbleWindowMouseup = () => {
    this.context.viewport.off('mousemove', this.onViewportMousemove);
    this.context.viewport.removeEventListener(
      'mouseup',
      this.onCaptureViewportMouseup,
      true
    );
    window.removeEventListener('mouseup', this.onBubbleWindowMouseup);

    this.context.resume('select');

    if (this.isPause || !this.clientDown) {
      return false;
    }

    // 檢查重疊

    const rects = this.context.store.flat.rects;
    const flatSelects = this.context.store.flatSelects;

    // 可以減少不好塞中間的情況
    flatSelects.groups.forEach((group) => group.moveFormat());

    // 移動的方塊有沒有和其他方塊重疊
    const checkOverlap = flatSelects.rects.find((rect) => {
      const checkOverlapRects = rects.find((item) =>
        item === rect ? false : item.checkOverlap(rect));

      return checkOverlapRects;
    });

    if (checkOverlap) {
      flatSelects.groups.forEach((item) => item.resetPosition());
      flatSelects.texts.forEach((item) => item.resetTranslate());
      flatSelects.marks.forEach((item) => item.resetTranslate());

      this.context.events.onError(ERRORS['0x001']);
    } else {
      let validMove = false;

      flatSelects.groups.forEach((item) => {
        if (!validMove) {
          validMove = item.get.validMove;
        }

        item.updateBasicPosition();
      });

      flatSelects.texts.forEach((item) => {
        if (!validMove) {
          validMove = item.validMove;
        }

        item.updateBasicTranslate();
      });

      flatSelects.marks.forEach((item) => {
        if (!validMove) {
          validMove = item.validMove;
        }

        item.updateBasicTranslate();
      });

      this.after = this.getSelectsRes();

      // 判斷有沒有移動
      if (validMove) {
        this.context.history.pushes.move({
          before: this.before,
          after: this.after
        });

        this.context.events.onMoved();
      }
    }

    this.clientDown = null;
    this.clientMove = null;

    this.before = [];
    this.after = [];
  };
}

export default DragObject;
