import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Drawer } from 'antd';
import './reset.scss';

function CustomDrawer(props) {
  const { vm, onClose, ...ps } = props;
  const close = useCallback(() => {
    onClose();
    vm.close();
  }, [vm, onClose]);

  return (
    <Drawer
      className="customDrawer"
      open={vm.isOpen}
      mask={false}
      onClose={close}
      {...ps}
    >
      {props.children}
    </Drawer>
  );
}

CustomDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node
};

CustomDrawer.defaultProps = {
  onClose: () => {},
  children: null
};

export default observer(CustomDrawer);
