import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select, Input } from 'antd';
import { FONT_SIZES } from 'src/constants';
import i18n from 'src/i18n';
import styles from './styles.module.scss';

@observer
class AreaName extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.label}>
          {i18n.t('draw_floor_plan_page_booth_drawer_area_zh_name_label')}
        </div>

        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            options={FONT_SIZES}
            value={this.vm.zhNameFontSize}
            onChange={this.vm.onZhNameFontSizeChange}
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>

        <div className={styles.inputContainer}>
          <Input.TextArea
            value={this.vm.zhName}
            onChange={this.vm.onZhNameChange}
            disabled={this.props.disabled}
            autoSize
            maxLength={50}
            showCount
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>

        <div className={styles.label}>
          {i18n.t('draw_floor_plan_page_booth_drawer_area_en_name_label')}
        </div>

        <div className={styles.selectContainer}>
          <Select
            className={styles.select}
            options={FONT_SIZES}
            value={this.vm.enNameFontSize}
            onChange={this.vm.onEnNameFontSizeChange}
            disabled={this.props.disabled}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>

        <div className={styles.inputContainer}>
          <Input.TextArea
            value={this.vm.enName}
            onChange={this.vm.onEnNameChange}
            disabled={this.props.disabled}
            autoSize
            maxLength={50}
            showCount
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
          />
        </div>
      </div>
    );
  }
}

AreaName.propTypes = {
  vm: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

AreaName.defaultProps = {
  disabled: false,
  onFocus: () => {},
  onBlur: () => {}
};

export default AreaName;
