import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { Select, Checkbox } from 'antd';
import CustomDrawer from 'src/components/CustomDrawer';
import Company from './components/Company';
import styles from './styles.module.scss';
import './reset.scss';

@observer
class CompanyDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ exhibitions: props.exhibitions });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <CustomDrawer
        vm={this.vm.drawerVM}
        title={i18n.t('draw_floor_plan_page_company_drawer_title')}
        placement="left"
        width={400}
        onClose={this.vm.floorPlanVM.onCompanyDrawerClose}
      >
        <div className={styles.container}>
          <div className={styles.formItem}>
            <div className={styles.label}>
              {i18n.t(
                'draw_floor_plan_page_company_drawer_exhibition_select_label'
              )}
            </div>

            <Select
              className={styles.select}
              options={this.vm.mapExhibitions}
              value={this.vm.exhibitionId}
              onChange={this.vm.onExhibitionIdChange}
              disabled={this.vm.disabled.select}
            />
          </div>

          <div className={styles.formItem}>
            <div className={styles.label}>
              {i18n.t(
                'draw_floor_plan_page_company_drawer_companies_sort_label'
              )}
            </div>

            <Select
              className={styles.select}
              options={this.vm.mapSortTypes}
              value={this.vm.sortBy}
              onChange={this.vm.onSortByChange}
              disabled={this.vm.disabled.select}
            />
          </div>

          <div className={styles.formItem}>
            <div className={styles.label}>
              {i18n.t(
                'draw_floor_plan_page_company_drawer_companies_sort_area_label'
              )}
            </div>

            <Select
              className={styles.select}
              options={this.vm.areas}
              value={this.vm.areaId}
              onChange={this.vm.onAreaIdChange}
              disabled={this.vm.disabled.select}
              placeholder={i18n.t(
                'draw_floor_plan_page_company_drawer_companies_sort_area_placeholder'
              )}
              allowClear
              onClear={this.vm.onAreaClear}
            />
          </div>

          <div className={styles.lengthFilter}>
            <div className={styles.length}>{this.vm.companiesLengthLabel}</div>

            <Checkbox
              className="filterMapId"
              checked={this.vm.filterMapId}
              onChange={this.vm.onFilterMapIdChange}
            >
              {i18n.t(
                'draw_floor_plan_page_company_drawer_companies_sort_map_id_label'
              )}
            </Checkbox>
          </div>

          <div className={styles.companies}>
            {this.vm.filterCompanies.map((vm) => (
              <Company vm={vm} key={vm.id} />
            ))}
          </div>
        </div>
      </CustomDrawer>
    );
  }
}

CompanyDrawer.propTypes = {
  vm: PropTypes.object.isRequired,
  exhibitions: PropTypes.array.isRequired
};

CompanyDrawer.defaultProps = {};

export default CompanyDrawer;
