import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import i18n from 'src/i18n';
import { Select } from 'antd';
import CustomModal from 'src/components/CustomModal';
import styles from './styles.module.scss';

@observer
class FontSizeModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
  }

  render() {
    return (
      <CustomModal
        vm={this.vm.modalVM}
        title={i18n.t('draw_floor_plan_page_font_size_modal_title')}
        footer
        onOk={this.vm.onConfirm}
        okText={i18n.t(
          'draw_floor_plan_page_font_size_modal_confirm_button_label'
        )}
        okDisabled={this.vm.disabled.confirm}
      >
        <div className={styles.container}>
          <div className={styles.label}>
            {i18n.t('draw_floor_plan_page_font_size_modal_select_label')}
          </div>

          <div className={styles.form}>
            <Select
              className={styles.select}
              options={this.vm.fontSizes}
              value={this.vm.fontSize}
              onChange={this.vm.onFontSizeChange}
              placeholder={i18n.t(
                'draw_floor_plan_page_font_size_modal_select_placeholder'
              )}
            />
          </div>
        </div>
      </CustomModal>
    );
  }
}

FontSizeModal.propTypes = {
  vm: PropTypes.object.isRequired
};

FontSizeModal.defaultProps = {};

export default FontSizeModal;
