import * as PIXI from 'pixi.js';
import {
  OBJECT_BORDER_WIDTH,
  COLORS,
  OBJECT_ZINDEXS,
  OBJECT_TYPES
} from '../../global/constants';

class Render {
  parent = null;

  constructor(props) {
    this.parent = props;
  }

  updateBorder = () => {
    this.parent.border.clear();
    this.parent.border.lineStyle(
      OBJECT_BORDER_WIDTH.active,
      COLORS.active.border
    );

    const x = this.parent.text.x;
    const y = this.parent.text.y;
    const width = this.parent.text.width;
    const height = this.parent.text.height;

    this.parent.border.endFill();
    this.parent.border.drawRect(x, y, width, height);
  };

  renderView = (data) => {
    const view = new PIXI.Container();
    const translate = { x: data.translateX, y: data.translateY };

    this.parent.setTranslate(translate);
    this.parent.setBasicTranslate(translate);

    view.x = this.parent.position.x;
    view.y = this.parent.position.y;

    switch (this.parent.type) {
      case OBJECT_TYPES.areaName:
      case OBJECT_TYPES.company:
      case OBJECT_TYPES.markText: {
        const checkLang = data.language === this.parent.context.store.language;
        const checkContent = !!data.content.trim();

        view.visible = checkLang && checkContent;

        break;
      }

      default: {
        view.visible = true;
        break;
      }
    }

    view.zIndex = OBJECT_ZINDEXS.text.normal;

    view.cullable = true;

    this.parent.setView(view);
  };

  renderText = (data) => {
    const text = new PIXI.Text(data.content);

    text.style.fontFamily = data.fontFamily;
    text.style.fontSize = data.fontSize;

    text.style.fill = data.fill;
    text.style.fontWeight = 400;

    text.texture.baseTexture.mipmap = PIXI.MIPMAP_MODES.ON;

    text.interactive = true;

    if (this.parent.context.store.check.isEdit) {
      text.on('mousedown', this.parent.onMousedown);
      text.on('mouseup', this.parent.onMouseup);
      text.on('rightup', this.parent.onRightMouseup);
    } else {
      text.on('pointerdown', this.parent.onTouchstart);
      text.on('pointerup', this.parent.onTouchend);
    }

    this.parent.setText(text);
    this.parent.setFill(data.fill);

    this.parent.view.addChild(text);
  };

  renderBorder = () => {
    const border = new PIXI.Graphics();

    border.visible = false;

    this.parent.view.addChild(border);
    this.parent.setBorder(border);
  };
}

export default Render;
