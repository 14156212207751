import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { Select, Button } from 'antd';
import i18n from 'src/i18n';
import clsx from 'clsx';
import withRouter from 'src/components/withRouter';
import CustomModal from 'src/components/CustomModal';
import CreateTag from './components/CreateTag';
import CustomTag from './components/CustomTag';
import styles from './styles.module.scss';

@observer
class BoothTagModal extends React.Component {
  constructor(props) {
    super(props);
    this.vm = props.vm;
    this.vm.init({ router: props.router });
  }

  componentDidMount() {
    this.vm.didMount();
  }

  render() {
    return (
      <CustomModal
        vm={this.vm.modalVM}
        title={i18n.t('draw_floor_plan_page_booth_tag_modal_title')}
        okDisabled={this.vm.disabled.ok}
      >
        <div className={styles.container}>
          <div className={styles.item}>
            <div className={styles.label}>
              {i18n.t('draw_floor_plan_page_booth_tag_modal_fixed_tag_label')}
            </div>

            <Select
              mode="multiple"
              labelInValue
              className={styles.select}
              options={this.vm.mapTags}
              value={this.vm.selected}
              onChange={this.vm.onSelectedChange}
            />
          </div>

          <div className={styles.item}>
            <div className={clsx([styles.label, styles.tagsLabel])}>
              {i18n.t('draw_floor_plan_page_booth_tag_modal_custom_tag_label')}
            </div>

            <div className={styles.tags}>
              {this.vm.custom.map((vm) => (
                <CustomTag
                  vm={vm}
                  key={vm.id}
                  onCustomTagEdited={this.vm.onCustomTagEdited}
                  onCustomTagRemoved={this.vm.onCustomTagRemoved}
                />
              ))}

              <CreateTag onCustomTagCreated={this.vm.onCustomTagCreated} />
            </div>
          </div>

          <div className={styles.confirmContainer}>
            <Button
              type="primary"
              className={styles.confirm}
              onClick={this.vm.close}
              disabled={this.vm.disabled.confirm}
            >
              {i18n.t(
                'draw_floor_plan_page_booth_tag_modal_confirm_button_label'
              )}
            </Button>
          </div>
        </div>
      </CustomModal>
    );
  }
}

BoothTagModal.propTypes = {
  router: PropTypes.object.isRequired,
  vm: PropTypes.object.isRequired
};

BoothTagModal.defaultProps = {};

export default withRouter(BoothTagModal);
