import FontFaceObserver from 'fontfaceobserver';
import { FONT_LOAD_TIMEOUT } from '../global/constants';
import Group from '../components/Group';
import GroupDataModel from '../dataModels/GroupDataModel';
import Pillar from '../components/Pillar';

class Load {
  // class
  FloorPlan = null;

  constructor(FloorPlan) {
    this.FloorPlan = FloorPlan;
  }

  load = async (props) => {
    const floorPlan = new this.FloorPlan({
      parent: props.parent,
      mode: props.mode,
      language: props.language
    });

    // 下載字型
    await Promise.all([
      new FontFaceObserver('Noto Sans TC', {}).load(null, FONT_LOAD_TIMEOUT),
      new FontFaceObserver('Crimson Text', {}).load(null, FONT_LOAD_TIMEOUT)
    ]);

    const map = JSON.parse(decodeURIComponent(props.map || '{}'));
    const groups = Object.values(map.groups || []);
    const pillars = map.pillars || [];

    await Promise.all([
      // 背景
      await floorPlan.setBackground(props.background),

      // 攤位
      ...groups.map(async (item) => {
        const group = new Group({
          context: floorPlan.context,
          data: new GroupDataModel(item)
        });

        group.addChildToMap();

        return group;
      }),

      // 柱子
      ...pillars.map(async (item) => {
        const pillar = await Pillar.render({
          context: floorPlan.context,
          data: item
        });

        pillar.addChildToMap();

        return pillar;
      })
    ]);

    await floorPlan.moveToCenter(0);

    props.parent.appendChild(floorPlan.context.app.view);

    setTimeout(floorPlan.resize, 1000);

    return floorPlan;
  };

  // 檢視模式
  readLoad = async (props) => {
    const floorPlan = new this.FloorPlan({
      parent: props.parent,
      mode: props.mode,
      language: props.language
    });

    const data = decodeURIComponent(props.map || '{}');
    const map = JSON.parse(data);
    const groups = Object.values(map.groups || []);
    const pillars = map.pillars || [];

    // 下載字型
    await Promise.all([
      new FontFaceObserver('Noto Sans TC', {}).load(null, FONT_LOAD_TIMEOUT),
      new FontFaceObserver('Crimson Text', {}).load(null, FONT_LOAD_TIMEOUT)
    ]);

    await Promise.all([
      // 背景
      await floorPlan.setBackground(props.background),

      ...groups.map(async (item) => {
        const group = new Group({
          context: floorPlan.context,
          data: new GroupDataModel(item)
        });

        group.addChildToMap();

        return group;
      }), // 柱子
      ...pillars.map(async (item) => {
        const pillar = await Pillar.render({
          context: floorPlan.context,
          data: item
        });

        pillar.addChildToMap();

        return pillar;
      })
    ]);

    await floorPlan.moveToCenter(0);

    props.parent.appendChild(floorPlan.context.app.view);

    setTimeout(floorPlan.resize, 1000);

    return floorPlan;
  };
}

export default Load;
