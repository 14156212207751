import React, { useMemo, useEffect } from 'react';
import {
  useNavigate, useParams, useLocation, useSearchParams, useRouteLoaderData
} from 'react-router-dom';
import { ROUTES } from 'src/constants';

const withRouter = (WrappedComponent) => React.forwardRef((props, ref) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const params = useParams();

  // ?note: use useRouteLoaderData to get profile, no need for with profile.
  const profile = useRouteLoaderData('main');

  // ?note use profile to redirect here.
  useEffect(() => {
    if (profile?.group !== 'admin' && location.pathname === ROUTES.Announcement) {
      navigate(ROUTES.Map);
    }
  });

  const router = useMemo(
    () => {
      return {
        location,
        searchParams,
        setSearchParams,
        navigate,
        params,
        profile
      };
    },
    [
      location,
      searchParams,
      setSearchParams,
      navigate,
      params,
      profile
    ]
  );

  return (
    <WrappedComponent
      {...props}
      ref={ref}
      router={router}
    />
  );
});

export default withRouter;
